import React from "react";
import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";

const StyledContainer = styled(StyledElement)`
  margin: 0% 5%;
  text-align: center;

  @media (min-width: ${theme.smallDesktop}) {
    margin: 0% 10%;
  }
`;

const StyledTitle = styled(StyledElement)`
  padding-bottom: 30px;

  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 50px;
    line-height: 70px;
    padding-bottom: 42px;
  }
`;

const StyledParagraph = styled(StyledElement)`
  padding-bottom: 70px;

  @media (min-width: ${theme.smallDesktop}) {
    font-size: 22px;
    line-height: 32px;
    padding-left: 8%;
    padding-right: 8%;
  }
`;

const StyledParagraph2 = styled(StyledElement)`
  padding-bottom: 46px;

  @media (min-width: ${theme.smallDesktop}) {
    font-size: 22px;
    line-height: 32px;
    text-align: left;
    padding-bottom: 82px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  margin-bottom: 40px;
  border-radius: 15px;
  height: 24vh;
  object-fit: cover;
  box-shadow: 18px 14px 50px 4px rgba(9, 30, 57, 0.1);

  @media (min-width: ${theme.tablet}) {
    height: 100%;
    margin-bottom: 64px;
  }
`;

const StyledHeading = styled(StyledElement)`
  padding-bottom: 24px;

  @media (min-width: ${theme.smallDesktop}) {
    font-size: 46px;
    line-height: 56px;
    padding-bottom: 38px;
  }
`;

const ContentAboutUs = ({ data }) => {

  return (
    <>
      <StyledContainer as="div">
        <StyledTitle
          as="h1"
          fontFamily="'Exo 2', sans-serif"
          fontWeight="900"
          fontSize="32px"
          lineheight="44px"
        >
          {data?.about_us_1?.name}
        </StyledTitle>
        <StyledParagraph
          as="p"
          fontWeight="400"
          fontSize="16px"
          lineheight="24px"
        >
          {data?.about_us_1?.description}
        </StyledParagraph>
        <StyledImage src="./images/contentAboutUs/image1.png" />
        <StyledHeading
          as="h2"
          fontFamily="'Exo 2', sans-serif"
          fontWeight="800"
          fontSize="32px"
        >
          {data?.about_us_2?.name}
        </StyledHeading>
        <StyledParagraph2
          as="p"
          fontWeight="400"
          fontSize="16px"
          lineheight="24px"
        >
          {data?.about_us_2?.description}
        </StyledParagraph2>
        <StyledImage src="./images/contentAboutUs/image2.svg" />
        <StyledHeading
          as="h2"
          fontFamily="'Exo 2', sans-serif"
          fontWeight="800"
          fontSize="32px"
        >
          {data?.about_us_3?.name}
        </StyledHeading>
        <StyledParagraph2
          as="p"
          fontWeight="400"
          fontSize="16px"
          lineheight="24px"
        >
          {data?.about_us_3?.description}
        </StyledParagraph2>
      </StyledContainer>
    </>
  );
};

export default ContentAboutUs;
