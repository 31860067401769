import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    box-shadow: 0 0 0 0 rgba(50, 205, 50, 1);
    transform: scale(1);
    animation: pulse 1.5s infinite;
    padding: 20px;
    border-radius: 50%;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(50, 205, 50, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(50, 205, 50, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(50, 205, 50, 0);
    }
  }
`;

const PageLoader = () => {
  return (
    <StyledContainer>
      <img src="./images/logo.svg" alt="Logo" />
    </StyledContainer>
  );
};

export default PageLoader;
