import React from "react";
import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";

const StyledContainer = styled.div`
  margin: 24px 0px;
  @media (min-width: ${theme.tablet}) {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #898989;
  }
  @media (min-width: ${theme.mediumDesktop}) {
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
  }
`;

const StyledName = styled(StyledElement)`
  text-align: left;
  margin-bottom: 16px;
`;
const StyledEmail = styled(StyledElement)`
  text-align: left;
`;

const StaffMember = ({ name, email }) => {
  return (
    <StyledContainer>
      <StyledName
        as="h1"
        fontFamily="'Exo 2', sans-serif"
        fontWeight="700"
        fontSize="25px"
      >
        {name}
      </StyledName>
      <StyledEmail as="h1" fontWeight="400" fontSize="16px" fontStyle="italic">
        {email}
      </StyledEmail>
    </StyledContainer>
  );
};

export default StaffMember;
