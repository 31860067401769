import React from "react";
import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";

const ImageContainer = styled.div`
  width: 100%;
  height: 245px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${theme.standardMobile}) {
    height: 285px;
  }
  @media (min-width: ${theme.mediumMobile}) {
    height: 320px;
  }
  @media (min-width: ${theme.tablet}) {
    height: 305px;
  }
  @media (min-width: ${theme.smallDesktop}) {
    height: 265px;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    height: 330px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    height: 435px;
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  box-shadow: 18px 14px 50px 4px rgba(9, 30, 57, 0.1);
`;

const GalleryImage = ({ src }) => {
  return (
    <ImageContainer>
      <Image src={src} />
    </ImageContainer>
  );
};
export default GalleryImage;
