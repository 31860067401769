const theme = {
  // colors
  lightBlue: "#5FA4BD",
  babyBlue: "#E2F5FB",
  darkBlue: "#023859",
  lightDarkBlue: "#0C5E8F",
  white: "#FFF",
  black: "#000",
  green: "#43B74A",
  darkGreen: "#35893A",
  darkdarkGreen: "#123915",
  magenta: "#DB2954",
  gray: "#898989",
  lightGray: "#d4d1d1",
  beige: "#EFECEC",

  // Media queries breakpoints
  smallMobile: "320px",
  standardMobile: "375px",
  mediumMobile: "425px",
  largeMobile: "550px",
  tablet: "768px",
  smallDesktop: "992px",
  standardDesktop: "1024px",
  mediumDesktop: "1440px",
  largeDesktop: "1600px",
  extraLargeDesktop: "1900px",
};

export default theme;
