import React from "react";
import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";

const StyledContainer = styled.div`
  text-align: center;
  margin: 5%;
  @media (min-width: ${theme.smallDesktop}) {
    display: flex;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 4% 10%;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    margin: 4% 10%;
  }
`;
const StyledImageDiv = styled.div`
  width: 100%;
  margin-bottom: 12px;
  @media (min-width: ${theme.smallDesktop}) {
    margin-right: 5%;
    align-self: center;
    width: 51%;
    margin-bottom: 0px;
  }
`;
const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 15px;
  box-shadow: 18px 14px 50px 4px rgba(9, 30, 57, 0.1);

  height: 50vh;
  @media (min-width: ${theme.standardMobile}) {
    height: 58vh;
  }
  @media (min-width: ${theme.mediumMobile}) {
    height: 68vh;
  }
  @media (min-width: ${theme.largeMobile}) {
    height: 80vh;
  }
  @media (min-width: ${theme.tablet}) {
    height: 110vh;
  }
  @media (min-width: ${theme.smallDesktop}) {
    height: auto;
  }
`;
const StyledContentDiv = styled.div`
  width: 100%;
  align-self: center;
`;

const StyledTitle = styled(StyledElement)`
  text-align: left;
  margin-top: 8px;
  margin-bottom: 14px;

  @media (min-width: ${theme.smallDesktop}) {
    text-align: left;
    font-weight: 800;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 36px;
    line-height: 40px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 40px;
    line-height: 48px;
  }
`;
const StyledHeading = styled(StyledElement)`
  text-align: left;

  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 18px;
    line-height: 32px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 22px;
  }
`;
const StyledParagraph = styled(StyledElement)`
  text-align: left;
  margin-top: 20px;

  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 18px;
    line-height: 32px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 22px;
    margin-top: 30px;
  }
`;

const Director = ({ data }) => {
  if (!data) {
    return null;
  }
  const { name, image, email, description } = data;
  return (
    <StyledContainer>
      <StyledImageDiv>
        <StyledImage src={image} />
      </StyledImageDiv>
      <StyledContentDiv>
        <StyledHeading
          as="h2"
          fontWeight="500"
          fontSize="16px"
          fontStyle="italic"
          lineheight="24px"
        >
          директор{" "}
        </StyledHeading>
        <StyledTitle
          as="h1"
          fontFamily="'Exo 2', sans-serif"
          fontWeight="700"
          fontSize="24px"
        >
          {name}{" "}
        </StyledTitle>
        <StyledHeading
          as="h2"
          fontWeight="400"
          fontSize="16px"
          fontStyle="italic"
          lineheight="24px"
        >
          {email}
        </StyledHeading>
        <StyledParagraph
          as="p"
          fontWeight="400"
          fontSize="16px"
          lineheight="24px"
        >
          {description}
        </StyledParagraph>
      </StyledContentDiv>
    </StyledContainer>
  );
};

export default Director;
