import React from "react";
import styled from "styled-components";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper/modules";

import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";
import GraduatesOfTheYear from "./GraduatesOfTheYear";

const StyledContainer = styled.div`
  text-align: center;
  margin: 7% 0% 7% 5%;
  @media (min-width: ${theme.tablet}) {
    margin: 3% 5%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 3% 10%;
  }
`;
const StyledHeading = styled(StyledElement)`
  margin-bottom: 38px;
  margin-right: 5%;
  @media (min-width: ${theme.tablet}) {
    margin-right: 0%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 46px;
    line-height: 56px;
  }
`;

const StyledStudentsContainer = styled.div`
  display: flex;
  width: 100%;

  .swiper {
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding-bottom: 1%;
  }

  .swiper-wrapper {
    padding-bottom: 30px;
  }

  .swiper-slide {
    width: 80%;
  }

  .swiper-pagination {
    z-index: 0;
  }
`;

const GraduatesOverTheYears = ({ data }) => {

  return (
    <StyledContainer>
      <StyledHeading
        as="h1"
        fontFamily="'Exo 2', sans-serif"
        fontWeight="800"
        fontSize="32px"
        lineheight="48px"
      >
        Матуранти низ годините
      </StyledHeading>
      <StyledStudentsContainer>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={30}
          modules={[Pagination]}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 3,
            },
            1440: {
              slidesPerView: 4,
            },
          }}
        >
          {data?.map((student, index) => (
            <SwiperSlide key={index}>
              <GraduatesOfTheYear image={student.image} year={student.year} />
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledStudentsContainer>
    </StyledContainer>
  );
};

export default GraduatesOverTheYears;
