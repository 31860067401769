import React from "react";
import styled from "styled-components";
import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";

const StudentContainer = styled.div`
  background-color: ${theme.white};
  width: 100%;

  @media (min-width: ${theme.tablet}) {
  }
  @media (min-width: ${theme.mediumDesktop}) {
  }
`;
const StudentImage = styled.img`
  object-fit: cover;
  width: 100%;
  flex-shrink: 0;
  border-radius: 10.608px;
  height: 245px;
  @media (min-width: ${theme.standardMobile}) {
    height: 285px;
  }
  @media (min-width: ${theme.mediumMobile}) {
    height: 320px;
  }
  @media (min-width: ${theme.tablet}) {
    height: 305px;
  }
  @media (min-width: ${theme.smallDesktop}) {
    height: 265px;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    height: 255px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    height: 332px;
  }
`;

const StudentName = styled(StyledElement)`
  text-align: left;
  margin-top: 8px;
  margin-bottom: 8px;

  @media (min-width: ${theme.tablet}) {
    font-size: 28px;
    margin-top: 16px;
    margin-bottom: 10px;
  }
  @media (min-width: ${theme.smallDesktop}) {
    font-size: 24px;
    margin-top: 14px;
    margin-bottom: 12px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 32px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;
const StudentPlace = styled(StyledElement)`
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 8px;
  @media (min-width: ${theme.tablet}) {
    line-height: 24px;
    margin-bottom: 4px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 20px;
  }
`;
const StudentCompetition = styled(StyledElement)`
  text-align: left;
  text-transform: capitalize;
  @media (min-width: ${theme.tablet}) {
    line-height: 24px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 20px;
  }
`;

const AwardedStudent = ({ image, name, placement, winner_name }) => {
  return (
    <StudentContainer>
      <StudentImage src={image} />
      <StudentName
        as="h1"
        fontFamily="'Exo 2', sans-serif"
        fontSize="24px"
        fontWeight="700"
        lineheight="32px"
      >
        {winner_name}
      </StudentName>
      <StudentPlace
        as="h2"
        fontSize="16px"
        fontStyle="italic"
        fontWeight="400"
        lineheight="20px"
      >
        {placement} Место
      </StudentPlace>
      <StudentCompetition
        as="h2"
        fontSize="16px"
        fontStyle="italic"
        fontWeight="400"
        lineheight="20px"
      >
        {name}
      </StudentCompetition>
    </StudentContainer>
  );
};
export default AwardedStudent;
