import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaArrowUp } from "react-icons/fa";
import theme from "../../Consts/Theme";

const StyledScroll = styled.span`
  display: ${(props) => (props.showScroll ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  position: fixed;
  font-size: 25px;
  right: 30px;
  bottom: 100px;
  align-items: center;
  width: 60px;
  height: 60px;
  border: 3px solid ${theme.white};
  border-radius: 15px;
  background-color: ${theme.darkBlue};
  justify-content: center;
  z-index: 10;
  cursor: pointer;
  animation: fadeIn 0.6s;
  transition: opacity 0.8s;
  opacity: 1;

  svg path {
    fill: ${theme.white};
  }

  &:active {
    opacity: 1;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
`;

const ScrollToTopPage = () => {
  const [showScroll, setShowScroll] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.scrollY > 400) {
        setShowScroll(true);
      } else if (showScroll && window.scrollY <= 400) {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", checkScrollTop);

    return () => window.removeEventListener("scroll", checkScrollTop);
  }, [showScroll]);
  return (
    <StyledScroll showScroll={showScroll} onClick={scrollToTop}>
      <FaArrowUp onClick={scrollToTop} />
    </StyledScroll>
  );
};
export default ScrollToTopPage;
