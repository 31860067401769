import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

// import required modules
import { Grid, Pagination } from "swiper/modules";

import AnnouncementCard from "./AnnouncementCard";
import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import useMediaQuery from "../../Hooks/useMediaQuery";
import theme from "../../Consts/Theme";
import { makeAppRoute } from "../../Api";
import { useNavigate } from "react-router-dom";

const StyledContainer = styled.div`
  @media (min-width: ${theme.tablet}) {
    margin: 0% 0.5%;
  }
  @media (min-width: ${theme.smallDesktop}) {
    margin: 0% 2.5%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 0% 7.5%;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    margin: 0% 8%;
  }
  .swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 7% 0% 13%;
    @media (min-width: ${theme.tablet}) {
      padding: 3% 2% 5%;
    }
    @media (min-width: ${theme.smallDesktop}) {
      padding: 4% 2.5% 5%;
    }
    @media (min-width: ${theme.mediumDesktop}) {
      padding: 4% 1.5% 3%;
    }
    @media (min-width: ${theme.extraLargeDesktop}) {
      padding: 3% 1%;
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: calc((100% - 30px) / 2) !important;
    /* padding-right: 60px; */

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledTitle = styled(StyledElement)`
  text-align: center;
  padding-top: 3%;

  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 46px;
  }
`;



const AnnouncementCards = ({ data }) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  return (
    <StyledContainer>
      <StyledTitle
        as="h1"
        fontFamily="'Exo 2', sans-serif"
        fontWeight="800"
        fontSize="32px"
        lineheight="56px"
      >
        Соопштенија
      </StyledTitle>
      <Swiper
        slidesPerView={1}
        grid={{
          rows: isDesktop ? 2 : 0,
          fill: "row",
        }}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Grid, Pagination]}
        breakpoints={{
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
            rows: 2,
            fill: "row",
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 40,
            rows: 2,
            fill: "row",
          },
          1440: {
            slidesPerView: 3,
            spaceBetween: 25,
            rows: 2,
            fill: "row",
          },
        }}
      >
        {data?.map((card, index) => (
          <SwiperSlide key={card?.id}>
            <AnnouncementCard
              name={card.name}
              date={card.created_at}
              description={card.description}
              to={makeAppRoute("announcementsCard", {ANNOUNCEMENT_ID: card.id})}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledContainer>
  );
};

export default AnnouncementCards;
