import React, { useEffect } from "react";
import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";
import Document from "../Document/Document";

const StyledContainer = styled.div`
  text-align: center;
  margin: 7% 5%;

  @media (min-width: ${theme.standardDesktop}) {
    margin: 5%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 3% 10%;
  }
`;
const StyledHeading = styled(StyledElement)`
  margin-bottom: 38px;
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 46px;
    line-height: 56px;
  }
`;
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${theme.tablet}) {
    border-radius: 40px;
    border: 3px solid var(--light-blue, #5fa4bd);
    padding: 4% 7%;
  }

  .doc {
    width: 100%;  
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    @media (min-width: ${theme.tablet}) {
      /* width: 50%; */
    }
    @media (min-width: ${theme.smallDesktop}) {
      /* width: 40%; */
    }
    @media (min-width: ${theme.mediumDesktop}) {
      /* width: 30%; */
    }
  }
`;
const StyledParagraph = styled(StyledElement)`
  text-align: left;
  padding-bottom: 15px;
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 20px;
    line-height: 32px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 25px;
  }
`;

const OpenDay = ({ data, hash }) => {
  useEffect(() => {
    // Get the element with id "openDay"
    const openDaySection = document.getElementById("openDay");

    if (hash === "#openDay") {
      // Scroll to the section
      openDaySection.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  return (
    <StyledContainer id="openDay">
      <StyledHeading
        as="h1"
        fontFamily="'Exo 2', sans-serif"
        fontWeight="800"
        fontSize="32px"
        lineheight="32px"
      >
        {data?.[0]?.name}
      </StyledHeading>
      <StyledDiv>
        <StyledParagraph
          as="p"
          fontWeight="400"
          fontSize="16px"
          lineheight="24px"
        >
          {data?.[0]?.description}
        </StyledParagraph>
        {/* <div className="doc">
          {data?.[0]?.documents?.map((document) => (
            <Document
              key={document.id}
              name={document?.name}
              link={document?.link}
            />
          ))}
        </div> */}
      </StyledDiv>
    </StyledContainer>
  );
};

export default OpenDay;
