import React from "react";
import styled from "styled-components";
import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";

const StyledContainer = styled.article`
  width: 100%;
  background-color: ${theme.lightBlue};
  padding: 16px 18px 40px;
  border-radius: 18px;

  @media (min-width: ${theme.standardDesktop}) {
    display: flex;
    flex-direction: row-reverse;
    width: 90%;
    margin: 0 auto;
    padding: 48px 40px 70px;
  }

  @media (min-width: ${theme.mediumDesktop}) {
    padding: 48px 80px 70px;
  }
`;

const StyledImageContainer = styled.article`
  height: 248px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    border-radius: 18px;
    display: block;
    object-fit: cover;
  }
  @media (min-width: ${theme.standardDesktop}) {
    /* width: 40%; */
    align-self: center;
    width: 466px;
    height: 312px;
  }
`;

const StyledContentContainer = styled.div`
  margin-top: 24px;
  color: ${theme.white};
  padding: 0 10px;

  h3 {
    margin-bottom: 8px;
  }
  h4 {
    margin-bottom: 25px;
  }
  @media (min-width: ${theme.standardDesktop}) {
    width: 60%;
    padding-right: 40px;
  }
`;

const StyledHeading = styled(StyledElement)`
  @media (min-width: ${theme.standardDesktop}) {
    font-size: 36px;
    line-height: 56px;
  }
`;
const StyledSubHeading = styled(StyledElement)`
  @media (min-width: ${theme.standardDesktop}) {
    font-size: 26px;
    line-height: 32px;
  }
`;
const StyledDiv = styled(StyledElement)`
  line-height: 24px;
  height: 300px;
  overflow-y: auto;
  a {
    text-decoration: underline;
    color: purple !important;
  }
  @media (min-width: ${theme.standardDesktop}) {
    font-size: 22px;
    line-height: 40px;
  }
`;

const AwardsCard = ({ award }) => {
  const { winner_name, image, awardName, description } = award;

  return (
    <StyledContainer>
      <StyledImageContainer>
        <img src={image} alt="" />
      </StyledImageContainer>
      <StyledContentContainer>
        <StyledHeading
          as="h3"
          fontFamily="'Exo 2', sans-serif"
          fontSize="20px"
          fontWeight="900"
        >
          {winner_name}
        </StyledHeading>
        <StyledSubHeading as="h4" fontSize="18px" fontWeight="500">
          -1-во место на државен натпревар по математика-
        </StyledSubHeading>
        <StyledDiv as="div" fontSize="16px"  dangerouslySetInnerHTML={{ __html: description}}>
        </StyledDiv>
      </StyledContentContainer>
    </StyledContainer>
  );
};

export default AwardsCard;
