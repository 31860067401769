import React, { useEffect, useState } from "react";
import HeroBanner from "../Components/HeroBanner/HeroBanner";
import SchoolBuildingGallery from "../Components/SchoolBuildingGallery/SchoolBuildingGallery";
import GraduatesOverTheYears from "../Components/GraduatesOverTheYears/GraduatesOverTheYears";
import { axios, makeApiRoute } from "../Api";
import { Footer } from "../Components/Footer/Footer";
import StaffOverTheYears from "../Components/StaffOverTheYears/StaffOverTheYears";
import Header from "../Components/Header/Header";
import ScrollToTopPage from "../Components/ScrollToTop/ScrollToTop";

export const Gallery = () => {
  const [galleryData, setGalleryData] = useState([]);

  useEffect(() => {
    axios
      .get(makeApiRoute("gallery"))
      .then((response) => {
        setGalleryData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching gallery data:", error);
      });
  }, []);
  return (
    <div>
      <ScrollToTopPage />
      <Header />
      <HeroBanner
        heading="Галерија"
        paragraph="Почетна / За нас / Галерија"
        imageUrl="/images/heroBanner/aboutUs.svg"
        backgroundPosition="80%"
      />
      <SchoolBuildingGallery />
      {galleryData?.graduates_over_the_years?.length > 0 ? <GraduatesOverTheYears data={galleryData?.graduates_over_the_years} /> : ""}
      {galleryData?.staff_over_the_years?.length > 0 ? <StaffOverTheYears data={galleryData?.staff_over_the_years} /> : ""}
      <Footer />
    </div>
  );
};
