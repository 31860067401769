import React from "react";
import styled from "styled-components";

import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 16px;
  margin: 0 0 40px;
  @media (min-width: ${theme.tablet}) {
    display: flex;
  }
  @media (min-width: ${theme.standardDesktop}) {
    margin: 0 auto 80px;
    gap: 75px;
    justify-content: center;
  }
`;

const StyledFilterButton = styled(StyledElement)`
  outline: none;
  display: inline-block;
  flex-basis: 48%;
  padding: 30px;
  border-radius: 20px;
  border: 2px solid ${theme.black};
  background-color: ${props => props.isActive  ? theme.lightBlue : "transparent"};
  color: ${props => props.isActive  ? theme.white : theme.black};
  cursor: pointer;

  p.year-name {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 700;
  }

  @media (min-width: ${theme.standardDesktop}) {
    flex-basis: 13%;
  }
`;

const SchoolYears = ({ years, onClick, isActive }) => {

  return (
    <StyledContainer isActive={isActive}>
      {years?.map((year, index) => (
        <StyledFilterButton as="button" key={year.id} onClick={() => onClick(year.id, index)} isActive={isActive === index}>
          <p className="year-name">Во {year.name} година</p>
          <p>{year.classes} часа</p>
        </StyledFilterButton>
      ))}
    </StyledContainer>
  );
};

export default SchoolYears;
