import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";

const CardContainer = styled.article`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 22vh;
  border-radius: 30px;
  /* box-shadow: 0px 0px 12px 8px rgba(9, 30, 57, 0.1); */
  background-color: ${theme.white};
  margin-bottom: 32px;

  @media (min-width: ${theme.mediumMobile}) {
    height: 26vh;
  }
  @media (min-width: ${theme.tablet}) {
    height: 30vh;
  }
  @media (min-width: ${theme.standardDesktop}) {
    /* box-shadow: 29px 38px 57px 4px rgba(9, 30, 57, 0.1); */
  }
`;
const CardNumber = styled(StyledElement)`
  /* padding-top: 16px; */
  height: 40px;

  @media (min-width: ${theme.tablet}) {
    font-size: 64px;
  }
  @media (min-width: ${theme.smallDesktop}) {
    font-size: 60px;
    font-weight: 700;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 88px;
    font-weight: 700;
    line-height: 56px;
  }
`;

const CardTitle = styled(StyledElement)`
  text-transform: uppercase;
  padding-top: 16px;

  @media (min-width: ${theme.tablet}) {
    padding-top: 30px;
    font-size: 22px;
  }
  @media (min-width: ${theme.smallDesktop}) {
    font-size: 22px;
    font-weight: 400;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 32px;
    font-weight: 400;
    line-height: 58px;
  }
`;

const StyledLine = styled.div``;
const Line = styled.img`
  width: 100%;
`;

const CareerCenterCard = ({ value, name }) => {
  return (
    <CardContainer>
      <CardNumber
        as="h2"
        fontSize="46px"
        fontWeight="700"
        // lineheight="22px"
        color={`${theme.darkBlue}`}
      >
        {value === "unlimited" ? (
          <svg
            width="99"
            height="51"
            viewBox="0 0 99 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.7812 0.78125C11.1683 0.78125 0.0625 11.887 0.0625 25.5C0.0625 39.1306 11.1506 50.2188 24.7812 50.2188C35.1278 50.2188 41.4452 44.5334 46.3007 37.5274C44.8529 35.1968 43.6381 32.8944 42.4375 30.6874C38.1047 37.8452 33.2492 43.1562 24.7812 43.1562C15.0421 43.1562 7.125 35.2392 7.125 25.5C7.125 15.7078 14.9891 7.84375 24.7812 7.84375C30.4913 7.84375 34.1214 9.88481 37.4726 13.3631C40.8237 16.8378 43.6523 21.8593 46.5196 27.1562C49.3905 32.453 52.3003 38.0253 56.5625 42.4924C60.8247 46.9629 66.7007 50.2188 74.2188 50.2188C87.8317 50.2188 98.9375 39.113 98.9375 25.5C98.9375 11.8694 87.8494 0.78125 74.2188 0.78125C63.9287 0.78125 57.5689 6.28647 52.6993 13.1406C54.1471 15.4289 55.4713 17.6783 56.672 19.8712C60.9766 12.9182 65.7791 7.84375 74.2188 7.84375C83.9579 7.84375 91.875 15.7608 91.875 25.5C91.875 35.2922 84.0109 43.1562 74.2188 43.1562C68.6041 43.1562 65.0728 41.1152 61.7499 37.6369C58.4235 34.1622 55.5843 29.1407 52.6993 23.8438C49.8178 18.547 46.8516 12.9747 42.547 8.50409C38.2424 4.03706 32.3664 0.78125 24.7812 0.78125Z"
              fill="#023859"
            />
          </svg>
        ) : (
          value
        )}
      </CardNumber>
      <CardTitle
        as="h2"
        fontSize="18px"
        fontWeight="700"
        lineheight="40px"
        color={`${theme.darkBlue}`}
      >
        {name}
      </CardTitle>
      <StyledLine>
        <Line src="./images/careerCenterPage/greenLine.svg" />
      </StyledLine>
    </CardContainer>
  );
};

export default CareerCenterCard;
