import React, { useEffect, useState } from "react";
import { StateInstitutionsLinks } from "../Components/StateInstitutionsLinks/StateInstitutionsLinks";
import { Footer } from "../Components/Footer/Footer";
import { HeroBannerHomePage } from "../Components/HeroBanner/HeroBannerHomePage";
import { GoogleMap } from "../Components/GoogleMap/GoogleMap";
import { LinkCards } from "../Components/LinkCards/LinkCards";
import { CountdownEvent } from "../Components/CountdownEvent/CountdownEvent";
import { AnnouncementBoard } from "../Components/AnnouncementBoard/AnnouncementBoard";

import AwardsContainer from "../Components/Awards/AwardsContainer";
import UpcomingEvents from "../Components/UpcomingEvents/UpcomingEvents";
import { axios, makeApiRoute } from "../Api";
import Header from "../Components/Header/Header";
import PageLoader from "../Components/Loader/PageLoader";
import ScrollToTopPage from "../Components/ScrollToTop/ScrollToTop";

export const Home = () => {
  const [data, setData] = useState(null);

  const eventCountDownDate = data?.event_countdown && new Date(data?.event_countdown?.start_at)
  const today = new Date()
  const isEventCountdownUpcoming = eventCountDownDate > today

  useEffect(() => {
    axios
      .get(makeApiRoute("home"))
      .then((response) => {
        setData(response.data);
      })
      .catch(function (error) {
        throw error.response;
      });
  }, []);


  if (!data) return <PageLoader />;

  console.log(data)

  return (
    <div>
      <ScrollToTopPage />
      <Header />
      <HeroBannerHomePage content={data?.contents?.hero} statistics={data?.statistics}/>
      <LinkCards />
      {data?.event_countdown && isEventCountdownUpcoming   ? <CountdownEvent data={data?.event_countdown}/> : ""}
      {data?.activities?.length > 0 || data?.news?.length > 0 ? <AnnouncementBoard activities={data.activities} news={data.news} /> : ""}
      {data?.events?.length > 0 ? <UpcomingEvents events={data?.events}/> : ""}
      <AwardsContainer awards={data?.awards}/>
      <StateInstitutionsLinks />
      <GoogleMap />
      <Footer />
    </div>
  );
};
