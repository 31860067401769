import React, { createContext, useEffect, useState } from "react";
import { axios, makeApiRoute } from "../Api";

export const GlobalContext = createContext({})

export const GlobalProvider = ({children}) => {
    const [ homepageData, setHomepageData ] = useState("")


  useEffect(() => {
    axios
      .get(makeApiRoute("home"))
      .then((response) => {
        setHomepageData(response.data);
      })
      .catch(function (error) {
        throw error.response;
      });
  }, []);


    const contextObject = {
        homepageData
    }


    return (
        <GlobalContext.Provider value={contextObject}>
            {children}
        </GlobalContext.Provider>
    )
}