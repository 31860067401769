import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper/modules";

import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";
import GroupAward from "./GroupAward";

const StyledContainer = styled.div`
  text-align: center;
  margin: 7% 5%;
  @media (min-width: ${theme.tablet}) {
    margin: 5%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 5% 10%;
  }
`;
const StyledHeading = styled(StyledElement)`
  margin-bottom: 38px;
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 46px;
    line-height: 56px;
  }
`;
const StyledGroupContainer = styled.div`
  display: flex;
  width: 100%;

  .swiper {
    width: 100%;
    height: 100%;
    padding-bottom: 1%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* padding: 0 0 50px 0; */

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-wrapper {
    padding-bottom: 30px;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
`;

const GroupAwards = ({ data }) => {
  return (
    <StyledContainer>
      <StyledHeading
        as="h1"
        fontFamily="'Exo 2', sans-serif"
        fontWeight="800"
        fontSize="32px"
        lineheight="48px"
      >
        Групни награди /пофалници
      </StyledHeading>
      <StyledGroupContainer>
        <Swiper
          spaceBetween={50}
          modules={[Pagination]}
          pagination={{
            clickable: true,
          }}
          className="mySwiper"
        >
          {data?.map((group, index) => (
            <SwiperSlide key={index}>
              <GroupAward
                image={group.image}
                placement={group.placement}
                name={group.name}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledGroupContainer>
    </StyledContainer>
  );
};

export default GroupAwards;
