import React from "react";
import styled from "styled-components";

import { CiCircleAlert } from "react-icons/ci";
import { BiPlus } from "react-icons/bi";
import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";
import Dropdown from "../Dropdown/Dropdown";

const StyledContainer = styled.div`
  margin-bottom: 24px;
`;
const StyledHeading = styled(StyledElement)`
  margin-bottom: 24px;
`;
const StyledDescription = styled(StyledElement)`
  line-height: 24px;
  margin-bottom: 42px;
  height: 150px;
  overflow: auto;
`;
const StyledUl = styled.ul`
  padding: 16px;
  margin-top: 8px;

  li {
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 0.5px solid #d4d1d1;
    cursor: not-allowed;
  }

  li.missing-value {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom:none;
    span {
      margin-left: 5px;
    }

  }
`;

const SubjectsPerYear = ({ title, description, subjects }) => {
  return (
    <StyledContainer>
      <StyledHeading
        as="h3"
        fontFamily="'Exo 2', sans-serif"
        fontWeight="700"
        fontSize="25px"
      >
        {title}
      </StyledHeading>
      <StyledDescription as="p" fontSize="16px">
        {description}
      </StyledDescription>
      <Dropdown name={`${title}`} icon={<BiPlus />} borderColor={theme.gray}>
        <StyledUl>
          {subjects?.length > 0 ? (
            subjects?.map((subject) => <li key={subject.id}>{subject.name}</li>)
          ) : (
            <li className="missing-value">
              <CiCircleAlert /> <span>Не се пронајдени предмети!</span>
            </li>
          )}
        </StyledUl>
      </Dropdown>
    </StyledContainer>
  );
};

export default SubjectsPerYear;
