import React from "react";
import styled from "styled-components";
import LinkCard from "./LinkCard";
import theme from "../../Consts/Theme";
import { makeAppRoute } from "../../Api";

const StyledContainer = styled.div`
  padding: 64px;

  @media (min-width: ${theme.mediumMobile}) {
    display: grid;
    grid-template-columns: 46% 46%;
    grid-column-gap: 6%;
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (min-width: ${theme.tablet}) {
    padding-left: 15%;
    padding-right: 15%;
  }

  @media (min-width: ${theme.standardDesktop}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 2%;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: -18vh;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    padding-left: 10%;
    padding-right: 10%;
    margin-top: -20vh;
  }
`;

const cards = [
  {
    icon: "./images/linkCards/admissions.svg",
    title: "Уписи",
    linkTo: makeAppRoute("enrollments"),
  },
  {
    icon: "./images/linkCards/activities.svg",
    title: "Активности",
    linkTo: makeAppRoute("activities"),
  },
  {
    icon: "./images/linkCards/teaching.svg",
    title: "Настава",
    linkTo: makeAppRoute("classes"),
  },
  {
    icon: "./images/linkCards/careerCenter.svg",
    title: "Кариерен Центар",
    linkTo: makeAppRoute("careerCenter"),
  },
];

export const LinkCards = () => {
  return (
    <StyledContainer>
      {cards.map((card, index) => (
        <LinkCard
          key={index}
          title={card.title}
          icon={card.icon}
          to={card.linkTo}
        />
      ))}
    </StyledContainer>
  );
};
