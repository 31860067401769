import React, { useState } from "react";
import styled from "styled-components";

import ProjectCard from "./ProjectCard";
import theme from "../../Consts/Theme";

const StyledShowMoreButton = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  button {
    background-color: ${theme.darkBlue};;
    display: inline-block;
    padding: 15px 10px;
    outline: none;
    border: none;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;

    :hover {
      box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.3);
    }
    :active {
      box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.3);
    }
  }
`;

const Projects = ({ data }) => {
  const [projectsShown, setProjectsShown] = useState(2);
  const onShowMoreProjects = () => {
    if (projectsShown < data?.length) {
      setProjectsShown(projectsShown + 2);
    } else {
      setProjectsShown(2);
    }
  };
  return (
    <div>
      {data?.slice(0, projectsShown)?.map((project, index) => (
        <ProjectCard key={index} {...project} />
      ))}

      {data?.length > 2 ? (
        <StyledShowMoreButton>
          <button onClick={onShowMoreProjects}>
            {projectsShown < data?.length ? "Прикажи повеќе" : "Прикажи помалку"}
          </button>
        </StyledShowMoreButton>
      ) : (
        ""
      )}
    </div>
  );
};

export default Projects;
