import React, { useEffect, useState } from "react";
import HeroBanner from "../Components/HeroBanner/HeroBanner";
import { Footer } from "../Components/Footer/Footer";
import { axios, makeApiRoute } from "../Api";
import styled from "styled-components";
import theme from "../Consts/Theme";
import StyledElement from "../Consts/Text";
import PageLoader from "../Components/Loader/PageLoader";
import Header from "../Components/Header/Header";
import ScrollToTopPage from "../Components/ScrollToTop/ScrollToTop";

const StyledContainer = styled(StyledElement)`
  margin: 10% 5%;
  text-align: left;
  a {
    text-decoration: underline;
    color: purple !important;
  }

  img {
    width: 100%;
    display: inline-block;
  }

  @media (min-width: ${theme.tablet}) {
    margin: 5%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 5% 10%;
    font-size: 20px;
    line-height: 32px;
    img {
    width: auto;
    display: inline-block;
  }

  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 25px;
  }
`;

export const BilingualClass = () => {
  const [bilingualClassData, setBilingualClassData] = useState({});
  const isObjectEmpty = Object.keys(bilingualClassData).length === 0;

  useEffect(() => {
    axios
      .get(makeApiRoute("bilingualClass"))
      .then((response) => {
        setBilingualClassData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching bilingual class data:", error);
      });
  }, []);
  if (isObjectEmpty) return <PageLoader />;
  return (
    <div>
      <ScrollToTopPage />
      <Header />
      <HeroBanner
        heading="Билингвална паралелка"
        paragraph="Почетна / Настава/ Билингвална паралелка"
        imageUrl="/images/heroBanner/teaching.svg"
      />
      <StyledContainer
        as="div"
        fontWeight="400"
        fontSize="16px"
        lineheight="24px"
        dangerouslySetInnerHTML={{ __html: bilingualClassData?.description }}
      />
      <Footer />
    </div>
  );
};
