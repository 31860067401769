import React from "react";
import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";

const StyledContainer = styled.div`
  text-align: center;
  margin: 5%;
  @media (min-width: ${theme.smallDesktop}) {
    display: flex;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 5% 10% 3%;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    margin: 5% 10% 0%;
  }
`;
const StyledImageDiv = styled.div`
  width: 100%;
  @media (min-width: ${theme.smallDesktop}) {
    margin-right: 5%;
    align-self: center;
  }
`;
const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
`;
const StyledContentDiv = styled.div`
  width: 100%;
`;

const StyledTitle = styled(StyledElement)`
  text-align: center;
  margin-bottom: 38px;
  @media (min-width: ${theme.smallDesktop}) {
    text-align: left;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 40px;
    line-height: 48px;
  }
`;
const StyledParagraph = styled(StyledElement)`
  text-align: left;
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 20px;
    line-height: 32px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 22px;
  }
`;
const StyledStickerDiv = styled.div`
  width: 100%;
  padding-top: 8%;
  @media (min-width: ${theme.tablet}) {
    padding-top: 3%;
    width: 55%;
  }
  @media (min-width: ${theme.smallDesktop}) {
    padding-top: 5%;
    width: 90%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    width: 70%;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    width: 50%;
  }
`;
const StyledSticker = styled.img`
  object-fit: cover;
  width: auto;
`;
const StyledSocialMediaIcons = styled.div`
  text-align: left;
  padding-left: 15px;
  margin-top: -10px;
`;
const StyledIcons = styled.img`
  width: 40px;
  margin-right: 10px;
`;

const CareerCenterRJK = ({ data }) => {
  return (
    <StyledContainer>
      <StyledImageDiv>
        <StyledImage src="./images/careerCenterPage/careerCenterRJK.svg" />
      </StyledImageDiv>
      <StyledContentDiv>
        <StyledTitle
          as="h1"
          fontFamily="'Exo 2', sans-serif"
          fontWeight="800"
          fontSize="32px"
          lineheight="48px"
        >
          {data?.name}
        </StyledTitle>
        <StyledParagraph
          as="p"
          fontWeight="400"
          fontSize="16px"
          lineheight="24px"
        >
          {data?.description}
        </StyledParagraph>
        <StyledStickerDiv>
          <StyledSticker src="./images/careerCenterPage/checkUsOut.svg" />
        </StyledStickerDiv>
        <StyledSocialMediaIcons>
          <a href="https://www.instagram.com/rjkorchagin/" target="_blank">
            <StyledIcons src="./images/socialIcons/instagramLogo.svg" />
          </a>
          <a href="https://www.facebook.com/Korchagin2022/" target="_blank">
            <StyledIcons src="./images/socialIcons/facebookLogo.svg" />
          </a>
        </StyledSocialMediaIcons>
      </StyledContentDiv>
    </StyledContainer>
  );
};

export default CareerCenterRJK;
