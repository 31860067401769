import React, { useEffect, useState } from "react";
import HeroBanner from "../Components/HeroBanner/HeroBanner";
import Director from "../Components/Director/Director";
import ManagementStaff from "../Components/ManagementStaff/ManagementStaff";
import { Footer } from "../Components/Footer/Footer";
import { axios, makeApiRoute } from "../Api";
import PageLoader from "../Components/Loader/PageLoader";
import Header from "../Components/Header/Header";
import SubjectStaff from "../Components/SubjectStaff/SubjectStaff";
import ScrollToTopPage from "../Components/ScrollToTop/ScrollToTop";

export const Staff = () => {
  const [staffData, setStaffData] = useState({});

  const isObjectEmpty = Object.keys(staffData)?.length === 0;

  useEffect(() => {
    axios
      .get(makeApiRoute("staff"))
      .then((response) => {
        setStaffData(response.data);
      })
      .catch((error) => {
      });
  }, []);
  if (isObjectEmpty) return <PageLoader />;
  return (
    <div>
      <ScrollToTopPage />
      <Header />
      <HeroBanner
        heading="Наставен кадар"
        paragraph="Почетна / Настава / Кадар"
        imageUrl="/images/heroBanner/teaching.svg"
      />
      <Director data={staffData?.director} />
      <ManagementStaff data={staffData?.managament_staff} />
      <SubjectStaff data={staffData?.subjects} />
      <Footer />
    </div>
  );
};
