import React from "react";
import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";
import { Link } from "react-router-dom";

const CardContainer = styled(Link)`
  text-align: left;
  width: 90%;
  height: 470px;
  display: grid;
  padding: 24px 16px;
  margin-bottom: 5%;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  border-radius: 15px;
  background-color: ${theme.white};
  box-shadow: 0px 14px 30px 4px rgba(9, 30, 57, 0.1);
  cursor: pointer;

  @media (min-width: ${theme.standardMobile}) {
    height: 445px;
  }
  @media (min-width: ${theme.mediumMobile}) {
    height: 422px;
  }
  @media (min-width: ${theme.tablet}) {
    height: 445px;
  }
  @media (min-width: ${theme.smallDesktop}) {
    height: 355px;
  }
  @media (min-width: ${theme.standardDesktop}) {
    height: 535px;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    padding: 30px;
    width: 93%;
    height: 525px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    gap: 8px;
    height: 500px;
  }
`;

const StyledTitle = styled(StyledElement)`
  @media (min-width: ${theme.smallDesktop}) {
    font-size: 22px;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 26px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 30px;
  }
`;
const StyledDate = styled(StyledElement)`
  color: ${theme.darkBlue};
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 15px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 22px;
  }
`;
const StyledParagraph = styled(StyledElement)`
  text-transform: capitalize;

  @media (min-width: ${theme.smallDesktop}) {
    font-size: 13px;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 17px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 20px;
  }
`;
const StyledReadMore = styled(StyledElement)`
  @media (min-width: ${theme.smallDesktop}) {
    font-size: 16px;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 20px;
  }
`;

const MAX_DESCRIPTION_LENGTH = 310;
const MAX_NAME_LENGTH = 88;

const AnnouncementCard = ({ name, date, description, to }) => {
  const getDate = new Date(date).getDate();
  const getMonth = new Date(date).getMonth() + 1;
  const getYear = new Date(date).getFullYear();

  const truncatedParagraph =
    description.length > MAX_DESCRIPTION_LENGTH
      ? description.substring(0, MAX_DESCRIPTION_LENGTH - 3) + "..."
      : description;

  const truncatedTitle =
    name.length > MAX_NAME_LENGTH
      ? name.substring(0, MAX_NAME_LENGTH - 3) + "..."
      : name;

  return (
    <CardContainer to={to}>
      <StyledTitle
        as="h1"
        fontFamily="'Exo 2', sans-serif"
        fontWeight="700"
        fontSize="24px"
        lineheight="40px"
      >
        {truncatedTitle}
      </StyledTitle>
      <StyledDate as="h2" fontWeight="400" fontSize="12px" lineheight="32px">
        <span>{getDate}</span>/<span>{getMonth}</span>/<span>{getYear}</span> |
        Соопштенија
      </StyledDate>
      <StyledParagraph
        as="p"
        fontWeight="400"
        fontSize="14px"
        lineheight="24px"
        dangerouslySetInnerHTML={{ __html: truncatedParagraph }}
      ></StyledParagraph>
      <StyledReadMore
        as="h2"
        fontWeight="500"
        fontSize="20px"
        lineheight="32px"
        color={`${theme.magenta}`}
      >
        {" "}
        Прочитај повеќе...
      </StyledReadMore>
    </CardContainer>
  );
};

export default AnnouncementCard;
