import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import styled from "styled-components";

import theme from "../../../Consts/Theme";
import { MenuData } from "../../../Consts/utils";
import "./BodyStyle.css";
import { makeAppRoute } from "../../../Api";
const StyledContainer = styled.div`
  padding: 25px 0;
  height: 100%;
  width: ${(props) => (props.isOpen ? "100%" : 0)};
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  background-color: ${theme.white};
  overflow-x: hidden;
  transition: 0.5s;
`;

const StyledMenuHeader = styled.div`
  margin-bottom: 50px;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  .menu-title {
    letter-spacing: 1.2px;
  }
  .btn-close {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #f3eeee;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledLink = styled(Link)`
  padding: 13px 16px;
  font-size: 24px;
  letter-spacing: 1.2px;
  cursor: pointer;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "transparent"};
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const ListItems = styled.div``;

const StyledUl = styled.ul`
  padding: ${(props) => (props.isActive ? "18px 0 0 40px" : "0")};
  overflow: hidden;
  height: ${(props) => (props.isActive ? "100%" : "0")};

  li a {
    font-size: 20px !important;
    padding: 0 !important;
  }
`;

const SideMenu = ({ isOpen, closeSideMenu }) => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const refs = useRef(MenuData?.map(() => React.createRef()));

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isOpen]);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <StyledContainer isOpen={isOpen}>
      <StyledMenuHeader>
        <span className="menu-title">Мени</span>
        <span className="btn-close" onClick={closeSideMenu}>
          <AiOutlineClose />
        </span>
      </StyledMenuHeader>

      <StyledLink to={makeAppRoute("home")}>Почетна</StyledLink>

      {MenuData &&
        MenuData?.map((item, index) => (
          <ListItems key={index} isActive={activeAccordion === index} name={item.name}>
            <StyledLink
              onClick={() => toggleAccordion(index)}
              bgColor={theme.beige}
            >
              <span>{item.link}</span>
              {item?.submenu?.length > 0 ? (
                <span>
                  {" "}
                  {activeAccordion !== index ? (
                    <FiChevronDown />
                  ) : (
                    <FiChevronUp />
                  )}{" "}
                </span>
              ) : (
                ""
              )}
            </StyledLink>
            {item?.submenu?.length > 0 ? (
              <StyledUl
                ref={refs.current[index]}
                isActive={activeAccordion === index}
              >
                {item.submenu.map((el) => (
                  <li key={el.id} >
                    <StyledLink to={el.to} >{el.link}</StyledLink>
                  </li>
                ))}
              </StyledUl>
            ) : (
              ""
            )}
          </ListItems>
        ))}
    </StyledContainer>
  );
};

export default SideMenu;
