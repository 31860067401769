import React from "react";
import styled from "styled-components";
import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";

const GraduatesContainer = styled.div`
  background-color: ${theme.white};
  width: 100%;
`;
const GraduatesImage = styled.img`
  object-fit: cover;
  width: 100%;
  flex-shrink: 0;
  border-radius: 10.608px;
  height: 245px;
  @media (min-width: ${theme.standardMobile}) {
    height: 285px;
  }
  @media (min-width: ${theme.mediumMobile}) {
    height: 320px;
  }
  @media (min-width: ${theme.tablet}) {
    height: 305px;
  }
  @media (min-width: ${theme.smallDesktop}) {
    height: 265px;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    height: 255px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    height: 332px;
  }
`;

const GraduatesYear = styled(StyledElement)`
  text-align: left;
  text-transform: capitalize;
  margin-top: 14px;
  @media (min-width: ${theme.tablet}) {
    line-height: 24px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 20px;
  }
`;

const StaffOfTheYear = ({ image, year }) => {
  return (
    <GraduatesContainer>
      <GraduatesImage src={image} />
      <GraduatesYear as="h2" fontSize="16px" fontWeight="600" lineheight="20px">
        {year}
      </GraduatesYear>
    </GraduatesContainer>
  );
};

export default StaffOfTheYear;
