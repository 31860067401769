import React from "react";
import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";
import StaffMember from "../StaffMember/StaffMember";

const StyledContainer = styled.div`
  text-align: center;
  margin: 7% 5%;
  @media (min-width: ${theme.tablet}) {
    margin: 5%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 5% 10%;
  }
`;
const StyledHeading = styled(StyledElement)`
  margin-bottom: 38px;
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 36px;
    line-height: 46px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 40px;
    line-height: 48px;
  }
`;
const MemberContainer = styled.div``;

const ManagementStaff = ({ data }) => {
  return (
    <StyledContainer>
      <StyledHeading
        as="h1"
        fontFamily="'Exo 2', sans-serif"
        fontWeight="800"
        fontSize="32px"
        lineheight="48px"
      >
        Раководен кадар
      </StyledHeading>
      <div>
        {data?.map((member, index) => (
          <MemberContainer key={index}>
            <StaffMember name={member.name} email={member.email} />
          </MemberContainer>
        ))}
      </div>
    </StyledContainer>
  );
};

export default ManagementStaff;
