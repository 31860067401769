import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";

const CardContainer = styled.article`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 22vh;
  border-radius: 30px;
  box-shadow: 0px 0px 12px 8px rgba(9, 30, 57, 0.1);
  background-color: ${theme.white};
  margin-bottom: 32px;

  @media (min-width: ${theme.mediumMobile}) {
    height: 26vh;
  }
  @media (min-width: ${theme.tablet}) {
    height: 30vh;
  }
  @media (min-width: ${theme.standardDesktop}) {
    box-shadow: 29px 38px 57px 4px rgba(9, 30, 57, 0.1);
  }
`;
const CardNumber = styled(StyledElement)`
  padding-top: 16px;

  @media (min-width: ${theme.tablet}) {
    font-size: 64px;
  }
  @media (min-width: ${theme.smallDesktop}) {
    font-size: 60px;
    font-weight: 700;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 88px;
    font-weight: 700;
    line-height: 56px;
  }
`;

const CardTitle = styled(StyledElement)`
  text-transform: uppercase;
  padding-top: 16px;

  @media (min-width: ${theme.tablet}) {
    padding-top: 22px;
    font-size: 22px;
  }
  @media (min-width: ${theme.smallDesktop}) {
    font-size: 22px;
    font-weight: 400;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 32px;
    font-weight: 400;
    line-height: 58px;
  }
`;

const Card = ({ numbers, title }) => {
  return (
    <CardContainer>
      <CardNumber
        as="h2"
        fontSize="46px"
        fontWeight="700"
        lineheight="22px"
        color={`${theme.darkBlue}`}
      >
        {numbers}
      </CardNumber>
      <CardTitle
        as="h2"
        fontSize="20px"
        fontWeight="700"
        lineheight="40px"
        color={`${theme.darkBlue}`}
      >
        {title}
      </CardTitle>
    </CardContainer>
  );
};

export default Card;
