import React from "react";
import styled from "styled-components";
import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";

const CardContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  border-radius: 15px;
  background: ${(props) => props.bgColor || theme.magenta};
  padding: 5px 14px;

  @media (min-width: ${theme.largeMobile}) {
    padding: 10px 17px;
  }

  @media (min-width: ${theme.smallDesktop}) {
    padding: 8px 14px;
  }

  @media (min-width: ${theme.largeDesktop}) {
    padding: 10px 35px;
  }
`;
const CardImage = styled.img`
  width: 24px;
  flex-shrink: 0;
  @media (min-width: ${theme.mediumMobile}) {
    width: 30px;
  }
  @media (min-width: ${theme.tablet}) {
    width: 45px;
  }

  @media (min-width: ${theme.smallDesktop}) {
    width: 28px;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    width: 45px;
  }
`;

const CardTitle = styled(StyledElement)`
  color: ${theme.white};
  padding-left: 10px;
  margin-right: 5px;

  @media (min-width: ${theme.smallDesktop}) {
    font-size: 16px;
  }

  @media (min-width: ${theme.tablet}) {
    font-size: 20px;
    padding-left: 17px;
  }

`;

const Card = ({ icon, title, bgColor, className }) => {
  return (
    <CardContainer bgColor={bgColor} className={className}>
      <CardImage src={icon} />
      <CardTitle as="h2" fontWeight="700" fontSize="16px" lineHeight="32px">
        {title}
      </CardTitle>
    </CardContainer>
  );
};

export default Card;
