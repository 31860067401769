import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { axios, makeApiRoute } from "../Api";
import theme from "../Consts/Theme";
import StyledElement from "../Consts/Text";
import HeroBanner from "../Components/HeroBanner/HeroBanner";
import SchoolYears from "../Components/SchoolYears/SchoolYears";
import SubjectsPerYear from "../Components/Subjects/SubjectsPerYear";
import ContentBlock from "../Components/ContentBlock/ContentBlock";
import AdditionalClasses from "../Components/AdditionalClasses/AdditionalClasses";
import PageLoader from "../Components/Loader/PageLoader";
import AreasAccordion from "../Components/Areas/AreasAccordion";
import Header from "../Components/Header/Header";
import { Footer } from "../Components/Footer/Footer";
import ScrollToTopPage from "../Components/ScrollToTop/ScrollToTop";

const StyledHeading = styled(StyledElement)``;
const StyledMainContainer = styled.div`
  margin: 0 16px;

  @media (min-width: ${theme.standardDesktop}) {
    width: 80%;
    margin: 0 auto;
  }
`;
const StyledSubjectsContainer = styled.section`
  margin-bottom: 40px;
  @media (min-width: ${theme.standardDesktop}) {
    display: flex;
    justify-content: space-between;

    .subject-wrap {
      flex-basis: 45%;
    }
  }
`;
const StyledMediaContainer = styled.section`
  padding: 0 16px;
  margin-top: 39px;
  @media (min-width: ${theme.standardDesktop}) {
    width: 80%;
    padding: 0;
    margin: 50px auto 100px;
  }
`;
const StyledDescriptionContainer = styled.div`
  text-align: center;
  padding: 17px 25px;
  border-radius: 15px;
  border: 2px solid ${theme.lightBlue};
  box-shadow: 18px 14px 50px rgba(9, 30, 57, 0.1);
  margin-bottom: 40px;

  @media (min-width: ${theme.standardDesktop}) {
    z-index: 999;
    position: relative;
    padding: 75px 130px;
    margin-bottom: 50px;
    img {
      width: 100%;
    }

    div.sticker {
      display: block;
      width: 100px;
      position: absolute;
      left: -5%;
      top: -100%;
      z-index: 0;
    }

    div.sticker2 {
      display: block;
      width: 100px;
      position: absolute;
      top: 0;
      right: -5%;
      z-index: 0;
    }
  }
`;
const StyledDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
`;
const StyledFiltersSection = styled.section`
  text-align: center;
  margin: 30px 0 0;
  .filter-title {
    margin-bottom: 24px;
  }

  @media (min-width: ${theme.standardDesktop}) {
    margin-top: 40px;

    .filter-title {
      width: 43%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;
    }
  }
`;
const ImageContainer = styled.section`
  width: 100%;
  box-shadow: 18px 14px 50px 4px rgba(9, 30, 57, 0.1);

  margin-bottom: 30px;

  @media (min-width: ${theme.standardDesktop}) {
    margin-bottom: 50px;
  }
`;
const StyledImage = styled.img`
  width: 100%;
  display: block;
  object-fit: contain;
  border-radius: 15px;
`;

const StyledMediaContent = styled.section`
  margin-top: 30px;
  margin-bottom: 80px;
  padding: 0 16px;

  .image-container {
    margin-top: 30px;
  }
  @media (min-width: ${theme.standardDesktop}) {
    display: flex;
    justify-content: space-between;
    width: 80%;
    padding: 0;
    margin: 50px auto 100px;

    .content-container {
      align-self: center;
    }
    .image-container {
      flex-basis: 60%;
      margin-left: 30px;
    }
  }
`;
export const Classes = () => {
  const [classedData, setClassesData] = useState([]);
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  const [subjectsPerYear, setSubjectsPertYear] = useState("");
  const [areas, setAreas] = useState([]);

  const fetchData = (_route, setter, object) => {
    axios
      .get(makeApiRoute(_route, object && object))
      .then((response) => {
        setter(response.data);
      })
      .catch(function (error) {});
  };

  //fetch data
  useEffect(() => {
    fetchData("classes", setClassesData);
    fetchData("subjectYears", setSubjectsPertYear, { SUBJECT_YEAR: 1 });

    axios
      .get(makeApiRoute("areas"))
      .then((response) => {
        setAreas(response.data.areas);
      })
      .catch(function (error) {});
  }, []);

  // on click on each filter button
  const getSubjectsPerSchoolYear = (year, index) => {
    axios
      .get(makeApiRoute("subjectYears", { SUBJECT_YEAR: year }))
      .then((response) => {
        setSubjectsPertYear(response.data);
        setActiveButtonIndex(index);
      })
      .catch(function (error) {});
  };
  if (classedData.length === 0) {
    return <PageLoader />;
  }
  return (
    <div>
      <ScrollToTopPage />
      <Header />
      <HeroBanner
        heading="Настава"
        imageUrl="/images/classes/hero-classes.svg"
      />
      <StyledMainContainer>
        <StyledFiltersSection>
          <StyledHeading
            as="h2"
            fontFamily="'Exo 2', san-serif"
            fontSize="25px"
            fontWeight="700"
            lineheight="35px"
            className="filter-title"
          >
            Вкупниот годишен фонд на часови во гиманзиско образование е
            следниот:
          </StyledHeading>
          <SchoolYears
            years={classedData.years}
            onClick={getSubjectsPerSchoolYear}
            isActive={activeButtonIndex}
          />
        </StyledFiltersSection>
        <StyledDescriptionContainer>
          <StyledDescription>
            {classedData?.contents?.classes_1?.description}
          </StyledDescription>

          {/* <div className="sticker">
            <img src="./images/announcementBoard/sticker.svg" />
          </div>
          <div className="sticker2">
            <img src="./images/announcementBoard/sticker.svg" />
          </div> */}
        </StyledDescriptionContainer>
        <ImageContainer>
          <StyledImage src="./images/classes/image-01.svg" />
        </ImageContainer>
        <StyledSubjectsContainer>
          <div className="subject-wrap">
            <SubjectsPerYear
              title={classedData?.contents?.classes_2?.name}
              description={classedData?.contents?.classes_2?.description}
              subjects={subjectsPerYear.mandatory}
            />
          </div>

          <div className="subject-wrap">
            <SubjectsPerYear
              title={classedData?.contents?.classes_3?.name}
              description={classedData?.contents?.classes_3?.description}
              subjects={subjectsPerYear.optional}
            />
          </div>
        </StyledSubjectsContainer>
      </StyledMainContainer>
      {/* Areas  */}
      {activeButtonIndex > 1 ? <AreasAccordion data={areas} /> : ""}
      <StyledMediaContainer>
        <ImageContainer>
          <StyledImage src="./images/classes/image-02.svg" />
        </ImageContainer>
        <ContentBlock data={classedData?.contents?.classes_4} />
      </StyledMediaContainer>

      <AdditionalClasses data={classedData?.contents?.classes_5} />
      <StyledMediaContent>
        <div className="content-container">
          <ContentBlock data={classedData?.contents?.classes_6} />
        </div>
        <div className="image-container">
          <StyledImage src="./images/classes/image-03.svg" alt="" />
        </div>
      </StyledMediaContent>
      <Footer />
    </div>
  );
};
