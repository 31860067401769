import React, { useEffect, useRef } from "react";
import styled from "styled-components";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper/modules";

import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";
import AwardedStudent from "./AwardedStudent";

const StyledContainer = styled.div`
  text-align: center;
  margin: 7% 0% 7% 5%;
  @media (min-width: ${theme.tablet}) {
    margin: 5%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 5% 10%;
  }
`;
const StyledHeading = styled(StyledElement)`
  margin-bottom: 38px;
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 46px;
    line-height: 56px;
  }
`;

const StyledStudentsContainer = styled.div`
  display: flex;
  width: 100%;

  .swiper {
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding-bottom: 1%;
  }

  .swiper-wrapper {
    padding-bottom: 30px;
  }

  .swiper-slide {
    width: 80%;
  }

  .swiper-pagination {
    z-index: 0;
  }
`;

const AwardedStudents = ({ data, hash }) => {

  useEffect(() => {
    // Get the element with id "awards"
    const awardsSection = document.getElementById("awards");

    if (hash === "#awards") {
      // Scroll to the section
      awardsSection.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  return (
    <StyledContainer id="awards">
      <StyledHeading
        as="h1"
        fontFamily="'Exo 2', sans-serif"
        fontWeight="800"
        fontSize="32px"
        lineheight="48px"
      >
        Наградени и успешни ученици
      </StyledHeading>
      <StyledStudentsContainer>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={35}
          modules={[Pagination]}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
            1440: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
        >
          {data?.map((student, index) => (
            <SwiperSlide key={index}>
              <AwardedStudent
                image={student.image}
                winner_name={student.winner_name}
                placement={student.placement}
                name={student.name}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledStudentsContainer>
    </StyledContainer>
  );
};

export default AwardedStudents;
