import React, { useEffect, useState } from "react";
import HeroBanner from "../Components/HeroBanner/HeroBanner";
import StatisticCards from "../Components/StatisticCards/StatisticCards";
import ContentAboutUs from "../Components/ContentAboutUs/ContentAboutUs";
import OurGoals from "../Components/OurGoals/OurGoals";
import AboutKorchagin from "../Components/AboutKorchagin/AboutKorchagin";
import { axios, makeApiRoute } from "../Api";
import { Footer } from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import PageLoader from "../Components/Loader/PageLoader";
import ScrollToTopPage from "../Components/ScrollToTop/ScrollToTop";

export const AboutUs = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get(makeApiRoute("aboutUs"))
      .then((response) => {
        setData(response.data);
      })
      .catch(function (error) {
      });
  }, []);
  if (!data) return <PageLoader />;
  return (
    <div>
      <ScrollToTopPage />
      <Header />
      <HeroBanner
        heading="За нас"
        paragraph="Почетна / За нас"
        imageUrl="/images/heroBanner/aboutUs.svg"
        backgroundPosition="80%"
      />
      <StatisticCards data={data?.statistics} />
      <ContentAboutUs data={data?.contents} />
      <OurGoals data={data?.contents} />
      <AboutKorchagin />
      <Footer />
    </div>
  );
};
