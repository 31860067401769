import axios from "axios";

let axiosSetup = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

var makeApiRoute = (route, data) => {
  let apiRoute = Routes.api[route];
  if (typeof data !== typeof undefined) {
    for (let key in data) {
      apiRoute = apiRoute.replace(`{${key}}`, data[key]);
    }
  }

  return apiRoute;
};

var makeAppRoute = (route, data) => {
  let appRoute = Routes.app[route];
  if (typeof data !== typeof undefined) {
    for (let key in data) {
      appRoute = appRoute.replace(`{${key}}`, data[key]);
    }
  }

  return appRoute;
};

const makeExternalLink = (key) => {
  let link = Routes.external[key];
  return link;
};

const Routes = {
  api: {
    home: "/homepage",
    aboutUs: "/about-us",
    clubs: "/clubs",
    activities: "/activities?perPage=6",
    activityCard: "/activities/{ACTIVITY_ID}",
    activitiesAllPerPage: "/activities?perPage=6&page={CURRENT_PAGE}",
    announcementsCard: "/announcements/{ANNOUNCEMENT_ID}",
    filteredActivities: "/activities?perPage=6&&club={CARD_ID}",
    filteredActivitiesPerPage:
      "/activities?perPage=6&club={CARD_ID}&page={CURRENT_PAGE}",
    staff: "/staff",
    newsPage: "/news-page",
    newsCardPage: "/news/{NEWS_ID}",
    careerCenter: "/career-center",
    houseRules: "/contents/house-rules",
    schoolCommunity: "/contents/school-community",
    enrollments: "/contents/enrollments",
    stateGraduation: "/contents/state-graduation",
    philosophy: "/contents/philosophy",
    bilingualClass: "/contents/bilingual-class",
    classes: "/classes",
    subjectYears: "/subjects?year={SUBJECT_YEAR}",
    areas: "/areas",
    gallery: "/gallery",
    eventPage: "/events/{EVENT_ID}",
  },
  app: {
    home: "/",
    aboutUs: "/about-us",
    activities: "/activities",
    activityCard: "/activities/{ACTIVITY_ID}",
    announcementsCard: "/announcements/{ANNOUNCEMENT_ID}",
    careerCenter: "/career-center",
    classes: "/classes",
    newsPage: "/news",
    newsCardPage: "/news/{NEWS_ID}",
    staff: "/staff",
    enrollments: "/enrollments",
    houseRules: "/house-rules",
    philosophy: "/philosophy",
    bilingualClass: "/bilingual-class",
    stateGraduation: "/state-graduation",
    schoolCommunity: "/school-community",
    gallery: "/gallery",
    eventPage: "/events/{EVENT_ID}",
  },
  external: {
    sido: "https://sido.mk/",
    eDnevnik: "https://ednevnik.edu.mk/",
    eBiblioteka: "https://e-biblioteka.gov.mk/",
    microsoft: "https://www.microsoft.com/en-us/microsoft-teams/group-chat-software",
    schoolsMk: "http://www.schools.mk",
    stateGraduation: "http://matura.gov.mk/",
    bro: "https://www.bro.gov.mk/",
    mon: "https://mon.gov.mk/",
    dic: "https://dic.edu.mk/",
    azlp: "https://azlp.mk/",
    brainsterNext: "https://next.edu.mk/?utm_medium=organic&utm_source=portal&utm_campaign=korcagin_web"




  },
};

export { makeApiRoute, makeAppRoute, makeExternalLink, axiosSetup as axios };
