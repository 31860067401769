import { makeAppRoute, makeExternalLink } from "../Api";

export const MenuData = [
  {
    id: 1,
    link: "За нас",
    submenu: [
      {
        id: 1,
        link: "За нас",
        to: makeAppRoute("aboutUs"),
      },
      {
        id: 2,
        link: "Директор",
        to: makeAppRoute("staff"),
      },
      {
        id: 3,
        link: "Стефан Сидовски - Сидо",
        to: makeExternalLink("sido"),
      },
      {
        id: 4,
        link: "Куќен ред",
        to: makeAppRoute("houseRules"),
      },
      // {
      //   id: 5,
      //   link: "Годишна програма",
      // },
      // {
      //   id: 6,
      //   link: "Правилници и извештаи",
      // },
      // {
      //   id: 7,
      //   link: "Завршни Сметки",
      // },
      {
        id: 8,
        link: "Галерија",
        to: makeAppRoute("gallery"),
      },
    ],
  },
  {
    id: 2,
    link: "Настава",
    submenu: [
      {
        id: 1,
        link: "Настава",
        to: makeAppRoute("classes"),
      },
      {
        id: 2,
        link: "Уписи",
        to: makeAppRoute("enrollments"),
      },
      {
        id: 3,
        link: "Кадар",
        to: makeAppRoute("staff"),
      },
      {
        id: 4,
        link: "Филозофија",
        to: makeAppRoute("philosophy"),
      },
      // {
      //   id: 4,
      //   link: "Распоред на часови",
      // },
      {
        id: 5,
        link: "Државна матура",
        to: makeAppRoute("stateGraduation"),
      },
      {
        id: 6,
        link: "Билингвална паралелка",
        to: makeAppRoute("bilingualClass"),
      },
    ],
  },
  {
    id: 3,
    link: "Новости",
    submenu: [
      {
        id: 1,
        link: "Соопштенија",
        to: makeAppRoute("newsPage"),
      },
      {
        id: 2,
        link: "Настани",
        to: "/news#eventsBoard",
      },
      {
        id: 3,
        link: "Отворен ден",
        to: "/news#openDay",
      },
      {
        id: 4,
        link: "Наградени и успешни ученици",
        to: "/news#awards",
      },
    ],
  },
  {
    id: 4,
    link: "Кариерен центар",
    submenu: [
      // {
      //   id: 1,
      //   link: "Алумни",
      // },
      {
        id: 1,
        link: "Кариерен центар",
        to: makeAppRoute("careerCenter"),
      },
      {
        id: 2,
        link: "Училишна заедница",
        to: makeAppRoute("schoolCommunity"),
      },
    ],
  },
  {
    id: 5,
    link: "Активности",
    submenu: [
      {
        id: 1,
        link: "Клубови и драмски секции",
        to: makeAppRoute("activities"),
      },
    ],
  },
  {
    id: 6,
    link: "Услуги",
    submenu: [
      {
        id: 1,
        link: "Е-дневник",
        to: makeExternalLink("eDnevnik"),
      },
      {
        id: 2,
        link: "Е-библиотека",
        to: makeExternalLink("eBiblioteka"),
      },
      {
        id: 3,
        link: "Microsoft365",
        to: makeExternalLink("microsoft"),
      },
    ],
  },
  {
    id: 6,
    link: "Erasmus+",
    submenu: [],
  },
  {
    id: 7,
    link: "Контакт",
    submenu: [],
  },
];

export const months = [
  {
    id: 1,
    month: 1,
    name: "Јан",
  },
  {
    id: 2,
    month: 2,
    name: "Фев",
  },
  {
    id: 3,
    month: 3,
    name: "Март",
  },
  {
    id: 4,
    month: 4,
    name: "Апр",
  },
  {
    id: 5,
    month: 5,
    name: "Мај",
  },
  {
    id: 6,
    month: 6,
    name: "Јуни",
  },
  {
    id: 7,
    month: 7,
    name: "Јули",
  },
  {
    id: 8,
    month: 8,
    name: "Авг",
  },
  {
    id: 9,
    month: 9,
    name: "Сеп",
  },
  {
    id: 10,
    month: 10,
    name: "Окт",
  },
  {
    id: 11,
    month: 11,
    name: "Ное",
  },
  {
    id: 12,
    month: 12,
    name: "Дек",
  },
];
