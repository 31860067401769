import React, { useEffect, useState } from "react";
import HeroBanner from "../Components/HeroBanner/HeroBanner";
import ContentEventPage from "../Components/ContentEventPage/ContentEventPage";
import { Footer } from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import ScrollToTopPage from "../Components/ScrollToTop/ScrollToTop";
import { useParams } from "react-router-dom";
import PageLoader from "../Components/Loader/PageLoader";
import { axios, makeApiRoute } from "../Api";

export const Event = () => {

    // params
    const { EVENT_ID } = useParams();
    const [data, setData] = useState(null);

 
    useEffect(() => {
      axios
        .get(makeApiRoute("eventPage", { EVENT_ID: EVENT_ID }))
        .then((response) => {
          setData(response.data);
        })
        .catch(function (error) {
          throw error.response;
        });
    }, [EVENT_ID]);
    if (!data) return <PageLoader />;
  return (
    <div>
      <ScrollToTopPage />
      <Header />
      <HeroBanner
        heading="Настани"
        paragraph=""
        imageUrl="/images/heroBanner/teaching.svg"
      />
      <ContentEventPage data={data?.data}/>
      <Footer />
    </div>
  );
};
