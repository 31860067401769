import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";

const CardContainer = styled(Link)`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 22vh;
  border-radius: 30px;
  box-shadow: 0px 0px 12px 8px rgba(9, 30, 57, 0.1);
  background-color: ${theme.white};
  margin-bottom: 32px;

  @media (min-width: ${theme.standardDesktop}) {
    height: 25vh;
    box-shadow: 29px 38px 57px 4px rgba(9, 30, 57, 0.1);

    &:hover {
      box-shadow:  0px 21px 43px 1px rgba(9, 30, 57,0.2);;
    }
  }
  @media (min-width: ${theme.largeDesktop}) {
    height: 30vh;
  }
`;
const CardImage = styled.img`
  padding-bottom: 12px;

  @media (min-width: ${theme.tablet}) {
    padding-bottom: 20px;
  }
`;

const CardTitle = styled(StyledElement)`
  @media (min-width: ${theme.standardDesktop}) {
    font-size: 24px;
  }
`;

const Card = ({ icon, title, to }) => {
  return (
    <CardContainer to={to}>
      <CardImage src={icon} />
      <CardTitle
        as="h2"
        fontSize="20px"
        fontWeight="700"
        lineheight="40px"
        color={`${theme.darkBlue}`}
      >
        {title}
      </CardTitle>
    </CardContainer>
  );
};

export default Card;
