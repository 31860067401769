import React from "react";
import styled from "styled-components";
import theme from "../../../Consts/Theme";
import { Link, NavLink } from "react-router-dom";

const DropdownContent = styled.div`
  display: ${(props) => (props.showDropdown ? "block" : "none")};
  position: absolute;
  background-color: ${theme.white};
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  z-index: 1;
  padding: 16px 8px;

  a {
    color: rgba(0, 0, 0, 0.5);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  a:hover {
    color: ${theme.black};
    background-color: ${theme.beige};
    border-radius: 8px;
  }
`;

const MenuDropdown = ({ links, showDropdown }) => {

  return (
    <DropdownContent showDropdown={showDropdown}>
      {links?.map((link) => (
        <Link to={link.to} ><span>{link?.link}</span></Link>
      ))}
    </DropdownContent>
  );
};

export default MenuDropdown;
