import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import theme from "../../Consts/Theme";

const StyledContainer = styled.div`
  width: 100%;

  div.accordion {
    width: 100%;
    border-radius: 15px;
    padding: 20px 15px;
    border: 1px solid ${(props) => props.borderColor};
    background-color: ${(props) =>
      props.bgColor && props.isExpanded ? props.bgColor : "transparent"};
    outline: none;
  }
  div.accordion-label {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    cursor: pointer;
    color: ${(props) =>
      props.color && !props.isExpanded ? props.color : theme.black};
  }

  @media (min-width: ${theme.standardDesktop}) {
    div.accordion-label {
      font-size: 22px;
      font-weight: 700;
      line-height: 32px;
    }
  }
`;
const StyledDropdownContainer = styled.div`
  overflow: hidden;
  height: ${(props) => props?.maxHeight};
  transition: height 0.6s ease;
`;

const Dropdown = ({ name, children, color, borderColor, bgColor }) => {
  const refContent = useRef();
  const refToggleButton = useRef();

  const [isExpanded, setIsExpanded] = useState(false);

  const [maxHeight, setMaxHeight] = useState("0px");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (refContent?.current && !refContent?.current?.contains(event.target)) {
        setIsExpanded(false);
        setMaxHeight(
          isExpanded === false ? "0px" : `${refContent.current.scrollHeight}px`
        );
      } else if (
        refContent?.current &&
        refContent?.current?.contains(event.target)
      ) {
        setIsExpanded(false);
        setMaxHeight(
          isExpanded === false ? "0px" : `${refContent.current.scrollHeight}px`
        );
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [refContent]);

  const toggleAccordion = (event) => {
    event.stopPropagation();
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
    setMaxHeight(
      isExpanded === true ? "0px" : `${refContent.current.scrollHeight}px`
    );
  };

  return (
    <StyledContainer
      color={color}
      borderColor={borderColor}
      bgColor={bgColor}
      isExpanded={isExpanded}
    >
      <div
        className="accordion"
        onClick={toggleAccordion}
        ref={refToggleButton}
      >
        <div className="accordion-label">
          <span>{name} </span>
          <span>{!isExpanded ? "+" : "-"}</span>
        </div>

        <StyledDropdownContainer
          ref={refContent}
          maxHeight={maxHeight}
          className={isExpanded ? "active" : ""}
        >
          {children}
        </StyledDropdownContainer>
      </div>
    </StyledContainer>
  );
};

export default Dropdown;
