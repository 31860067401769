import React, { useEffect, useState } from "react";
import styled from "styled-components";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper/modules";

import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";
import ActivityCard from "../ActivityCard/ActivityCard";
import Button from "../Button/Button";
import useMediaQuery from "../../Hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { axios, makeApiRoute, makeAppRoute } from "../../Api";

const StyledContainer = styled.div`
  text-align: center;
  position: relative;

  hr.line {
    border-top: 1px solid #cfcfcf;
    width: 90%;
    margin-top: 20px;

    @media (min-width: 768px) {
      margin-top: 50px;
    }
  }
`;
const StyledSticker = styled.img`
  @media (max-width: 768px) {
    display: none;
  }
`;
const StyledHeading = styled(StyledElement)`
  @media (min-width: 768px) {
    font-size: 46px;
    line-height: 56px;
  }
`;
const ButtonContainer = styled.div`
  padding-top: 32px;
  margin-left: 16px;
  margin-right: 16px;
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;

  button {
    padding: 15px 0;
    transition: transform 0.2s ease-in-out;
    vertical-align: middle;
    transform-origin: center center; /* Set the transform origin to the center for consistent scaling */
    backface-visibility: hidden; /* Optimize text rendering */

    &:hover {
      transform: scale(1.03);
    }
  }

  @media (min-width: ${theme.tablet}) {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: ${theme.standardDesktop}) {
    width: 50%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    width: 30%;
  }
`;

const StyledCardsContainer = styled.div`
  display: flex;
  padding: 48px 0px;
  padding-left: 16px;
  width: 100%;
  position: relative;

  .swiper {
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  .swiper-wrapper {
    padding-bottom: 30px;
  }

  .swiper-slide {
    width: 80%;
    padding: 15px 0;
  }

  .swiper-pagination {
    z-index: 0;
  }

  @media (min-width: ${theme.standardDesktop}) {
    width: 80%;
    div.sticker {
      display: block;
      position: absolute;
      left: -5%;
      bottom: 0;
      z-index: 0;
    }

    div.sticker2 {
      display: block;
      position: absolute;
      top: 0;
      right: -5%;
      z-index: 0;
    }
  }

  @media (min-width: ${theme.standardDesktop}) {
    overflow: unset;
    margin-left: 10%;
    margin-right: 10%;

    .swiper-pagination {
      z-index: 10;
    }
  }
`;

export const AnnouncementBoard = ({ activities, news }) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const [isNewsActive, setIsNewsActive] = useState(true);
  const [activeCards, setActiveCards] = useState(news && news);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isNewsActive) {
      const arr1 = activities;
      setActiveCards(arr1);
    } else {
      const arr2 = news;
      setActiveCards(arr2);
    }
  }, [isNewsActive]);

  const onDisplayActivities = () => {
    setIsNewsActive(false);
  };
  const onDisplayNews = () => {
    setIsNewsActive(true);
  };

  const navigateToCardPage = (card_id) => {
    if (isNewsActive) {
      navigate(makeAppRoute("newsCardPage", { NEWS_ID: card_id }));
    } else {
      navigate(makeAppRoute(`activityCard`, { ACTIVITY_ID: card_id }));
    }
  };

  return (
    <>
      <StyledContainer>
        <StyledHeading
          as="h1"
          fontFamily="Exo 2, sans-serif"
          fontWeight="900"
          fontSize="24px"
          lineHeight="32px"
        >
          Огласна табла
        </StyledHeading>
        <ButtonContainer>
          <Button
            type="button"
            onClick={onDisplayNews}
            bgColor={isNewsActive ? theme.green : theme.white}
            border={isNewsActive ? "none" : `2px solid ${theme.green}`}
            content="Новости"
            color={isNewsActive ? theme.white : theme.darkBlue}
            hoverBgColor={isNewsActive ? theme.darkGreen : ""}
          />
          <Button
            type="button"
            onClick={onDisplayActivities}
            bgColor={isNewsActive ? theme.white : theme.green}
            content="Активности"
            color={isNewsActive ? theme.darkBlue : theme.white}
            border={isNewsActive ? `2px solid ${theme.green}` : "none"}
            hoverBgColor={isNewsActive ? "" : theme.darkGreen}
          />
        </ButtonContainer>
        <StyledCardsContainer className="cardContainer">
          <Swiper
            slidesPerView={!isDesktop ? "auto" : 3}
            spaceBetween={20}
            modules={[Pagination]}
            pagination={{
              clickable: true,
            }}
          >
            {activeCards?.map((card, index) => (
              <SwiperSlide key={card?.id}>
                <ActivityCard
                  image={card.image}
                  title={card.name}
                  description={card.description}
                  date={card.created_at}
                  onClick={() => navigateToCardPage(card?.id)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="sticker">
            <StyledSticker src="./images/announcementBoard/sticker.svg" />
          </div>
          <div className="sticker2">
            <StyledSticker src="./images/announcementBoard/sticker.svg" />
          </div>
        </StyledCardsContainer>
      </StyledContainer>
    </>
  );
};
