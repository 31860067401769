import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

// import required modules
import { Grid, Pagination } from "swiper/modules";

import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";
import useMediaQuery from "../../Hooks/useMediaQuery";
import SchoolBuildingImage from "./SchoolBuildingImage";

const StyledContainer = styled.div`
  text-align: center;
  margin: 7% 0%;
  @media (min-width: ${theme.tablet}) {
    margin: 3% 5%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 3% 10%;
  }
`;

const StyledGallery = styled.div`
  .swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 8% 5% 12%;

    @media (min-width: ${theme.tablet}) {
      padding: 5% 0 8%;
    }
    @media (min-width: ${theme.smallDesktop}) {
      padding: 3% 0 5%;
    }
    @media (min-width: ${theme.mediumDesktop}) {
      padding: 4% 0;
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledHeading = styled(StyledElement)`
  margin: 0% 5%;
  @media (min-width: ${theme.tablet}) {
    margin: 0%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 46px;
    line-height: 56px;
  }
`;

const images = [
  {
    src: "./images/schoolBuildingGallery/img1.svg",
  },
  {
    src: "./images/schoolBuildingGallery/img2.svg",
  },
  {
    src: "./images/schoolBuildingGallery/img3.svg",
  },
  {
    src: "./images/schoolBuildingGallery/img4.svg",
  },
  {
    src: "./images/schoolBuildingGallery/img5.svg",
  },
  {
    src: "./images/schoolBuildingGallery/img6.svg",
  },
  {
    src: "./images/schoolBuildingGallery/img7.svg",
  },
  {
    src: "./images/schoolBuildingGallery/img8.svg",
  },
  {
    src: "./images/schoolBuildingGallery/img5.svg",
  },
  {
    src: "./images/schoolBuildingGallery/img6.svg",
  },
  {
    src: "./images/schoolBuildingGallery/img7.svg",
  },
  {
    src: "./images/schoolBuildingGallery/img8.svg",
  },
  {
    src: "./images/schoolBuildingGallery/img1.svg",
  },
  {
    src: "./images/schoolBuildingGallery/img2.svg",
  },
  {
    src: "./images/schoolBuildingGallery/img3.svg",
  },
  {
    src: "./images/schoolBuildingGallery/img4.svg",
  },
];

const SchoolBuildingGallery = () => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  return (
    <StyledContainer>
      <StyledHeading
        as="h1"
        fontFamily="'Exo 2', sans-serif"
        fontWeight="800"
        fontSize="32px"
        lineheight="48px"
      >
        Зградата на нашето училиште{" "}
      </StyledHeading>
      <StyledGallery>
        <Swiper
          slidesPerView={1}
          grid={{
            rows: isDesktop ? 2 : 0,
            fill: "row",
          }}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Grid, Pagination]}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1440: {
              slidesPerView: 4,
            },
          }}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <SchoolBuildingImage src={image.src} />
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledGallery>
    </StyledContainer>
  );
};

export default SchoolBuildingGallery;
