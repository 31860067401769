import React from "react";
import styled from "styled-components";
import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";

const CardContainer = styled.div`
  background-color: ${theme.white};
  width: 100%;
  border-radius: 10px;
  box-shadow: 13px 10px 36px 3px rgba(9, 30, 57, 0.1);
  padding: 22px 22px 30px;
  cursor: pointer;
  height: 450px;

  .image-wrap {
    position: relative;
    height: 240px;
  }

  @media (min-width: ${theme.tablet}) {
    padding: 34px 39px;
    border-radius: 15px;
    &:hover {
      box-shadow: 1px 8px 20px grey;
      -webkit-transition: box-shadow 0.2s ease-in;
    }
  }

  @media (min-width: ${theme.standardDesktop}) {
    box-shadow: 16px 10px 36px 3px rgba(9, 30, 57, 0.1);
    height: 520px;
    padding: 32px 22px;
    .image-wrap {
      margin-bottom: 24px;
    }
  }
`;
const CardImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
  flex-shrink: 0;
  border-radius: 10.608px;
  background: lightgray 50% / cover no-repeat;
`;

const CardLabel = styled.span`
  position: absolute;
  right: 5%;
  bottom: 5%;
  border-radius: 15px;
  background-color: ${theme.white};
  color: ${theme.black};
  padding: 8px 25px;
`;

const CardTitle = styled(StyledElement)`
  text-align: left;
  margin-top: 8px;
  margin-bottom: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 32px;
  overflow: hidden;

  @media (min-width: ${theme.tablet}) {
    font-size: 32px;
    margin-top: 16px;
  }
  @media (min-width: ${theme.standardDesktop}) {
    margin-bottom: 16px;
  }
`;
const CardDescription = styled(StyledElement)`
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 8px;
  min-height: 80px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;

  a {
    text-decoration: underline;
    color: purple !important;
  }
  @media (min-width: ${theme.tablet}) {
    font-size: 20px;
    line-height: 24px;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    overflow: hidden;
  }

  @media (min-width: ${theme.standardDesktop}) {
    min-height: 96px;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    overflow: hidden;
  }
`;
const CardDate = styled(StyledElement)`
  text-align: right;
  text-transform: capitalize;
  @media (min-width: ${theme.tablet}) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (min-width: ${theme.standardDesktop}) {
    margin-top: 18px;
  }
`;

const ActivityCard = ({ image, title, description, date, label, onClick }) => {
  let getDate = new Date(date).getDate();
  let getMonth = new Date(date).getMonth() + 1;
  const getYear = new Date(date).getFullYear();


  if (getDate < 10) {
    getDate = '0' + getDate;
  }
  if (getMonth < 10) {
    getMonth = '0' + getMonth;
  }

  return (
    <CardContainer onClick={onClick}>
      <div className="image-wrap">
        <CardImage src={image} />
        {label ? <CardLabel>{label}</CardLabel> : ""}
      </div>

      <CardTitle
        as="h2"
        fontFamily="'Exo 2', sans-serif"
        fontSize="22px"
        fontWeight="700"
      >
        {title}
      </CardTitle>
      <CardDescription
        as="p"
        fontFamily="'Roboto', sans-serif"
        fontSize="14px"
        fontWeight="400"
        lineheight="20px"
        dangerouslySetInnerHTML={{ __html: description}}>
        
      
      </CardDescription>
      <CardDate as="h3" fontSize="12px" fontWeight="600" lineheight="20px">
        <span>{getDate}</span>/<span>{getMonth}</span>/<span>{getYear}</span>
      </CardDate>
    </CardContainer>
  );
};

export default ActivityCard;
