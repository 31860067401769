import React from "react";
import styled from "styled-components";
import theme from "../../Consts/Theme";
import CareerCenterCard from "./CareerCenterCard";

const StyledContainer = styled.div`
  padding: 0px 64px;

  @media (min-width: ${theme.standardMobile}) {
    display: grid;
    grid-template-columns: 46% 46%;
    grid-column-gap: 6%;
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (min-width: ${theme.tablet}) {
    padding-left: 15%;
    padding-right: 15%;
  }

  @media (min-width: ${theme.smallDesktop}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 2%;
    padding-left: 5%;
    padding-right: 5%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    padding-left: 10%;
    padding-right: 10%;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    /* margin-top: -18vh; */
  }
`;

const CareerCenterCards = ({ data }) => {
  return (
    <StyledContainer>
      {data?.map((card, index) => (
        <CareerCenterCard key={index} value={card.value} name={card.name} />
      ))}
    </StyledContainer>
  );
};

export default CareerCenterCards;
