import React from "react";
import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";
import Button from "../Button/Button";

const StyledContainer = styled.div`
  text-align: center;
  margin: 10% 5%;
  position: relative;

  @media (min-width: ${theme.smallDesktop}) {
    display: flex;
    margin: 3% 10% 200px;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 3% 10% 300px;
    div.sticker2 {
      position: absolute;
      left: -11%;
      top: 96%;
      z-index: 1;
    }
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    div.sticker2 {
      position: absolute;
      left: -14%;
      top: 300px;
      z-index: 1;
    }
  }
`;

const StyledSticker = styled.img`
  display: none;

  @media (min-width: ${theme.mediumDesktop}) {
    display: block;
    width: 65%;
  }
  @media (min-width: ${theme.largeDesktop}) {
    width: 95%;
  }
`;

const StyledDesktopImageDiv = styled.div`
  display: none;
  width: 35%;
  margin-left: 5%;

  @media (min-width: ${theme.smallDesktop}) {
    display: block;
    position: relative;
  }
`;
const StyledDesktopImage = styled.img`
  border-radius: 15px;
  object-fit: cover;
  width: 100%;

  @media (min-width: ${theme.smallDesktop}) {
    &.second-image {
      position: absolute;
      right: -200px;
      bottom: -80px;
    }
  }
  @media (min-width: ${theme.standardDesktop}) {
    &.second-image {
      right: -100px;
      bottom: -30px;
    }
  }
  @media (min-width: ${theme.mediumDesktop}) {
    &.second-image {
      right: -200px;
      bottom: -50px;
    }
  }
  @media (min-width: ${theme.largeDesktop}) {
    &.second-image {
      right: -200px;
      bottom: -200px;
    }
  }
`;

const StyledImageDiv = styled.div`
  width: 100%;
  margin-bottom: 5%;

  @media (min-width: ${theme.smallDesktop}) {
    display: none;
  }
`;
const StyledImage = styled.img`
  border-radius: 15px;
  object-fit: cover;
  width: 100%;
`;
const StyledContentDiv = styled.div`
  width: 100%;
  position: relative;
  z-index: 11;
  button.btn-about-us {
    width: 100%;
    padding: 16px 0;

    @media (min-width: ${theme.tablet}) {
      width: auto;
      padding: 20px 80px;
    }
    @media (min-width: ${theme.smallDesktop}) {
      display: block;
    }
  }
  @media (min-width: ${theme.smallDesktop}) {
    align-self: center;
    width: 50%;
  }
`;

const StyledTitle = styled(StyledElement)`
  text-align: center;
  margin-bottom: 38px;
  @media (min-width: ${theme.smallDesktop}) {
    text-align: left;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 40px;
    line-height: 48px;
  }
`;
const StyledDiv = styled(StyledElement)`
  text-align: left;
  margin-bottom: 30px;
  a {
    text-decoration: underline;
    color: purple !important;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 38px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 22px;
  }
`;

const ProjectCard = ( data ) => {

  return (
    <StyledContainer>
      <StyledContentDiv>
        <StyledTitle
          as="h1"
          fontFamily="'Exo 2', sans-serif"
          fontWeight="800"
          fontSize="32px"
          lineheight="48px"
        >
          {data?.name}
        </StyledTitle>
        <StyledImageDiv>
          <StyledImage src={data?.image1} />
        </StyledImageDiv>
        <StyledDiv
          as="div"
          fontWeight="400"
          fontSize="16px"
          lineheight="24px"
          dangerouslySetInnerHTML={{ __html: data?.description }}
        ></StyledDiv>
        {data?.button ? <a href={data?.button?.link} target="_blank">
          <Button
            type="button"
            bgColor={theme.green}
            hoverBgColor={theme.darkGreen}
            color={theme.white}
            content={data?.button?.name}
            className="btn-about-us"
          />
        </a> : ""}
      </StyledContentDiv>
      <StyledDesktopImageDiv>
        {/* <StyledDesktopImage src="./images/careerCenterPage/benchesDesktop.svg" /> */}
        <StyledDesktopImage src={data?.image1} />
        <StyledDesktopImage src={data?.image2} className="second-image" />
      </StyledDesktopImageDiv>
      <div className="sticker2">
        <StyledSticker src="./images/careerCenterPage/sticker2.svg" />
      </div>
    </StyledContainer>
  );
};
export default ProjectCard;
