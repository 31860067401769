import React from "react";
import styled from "styled-components";

import theme from "../../Consts/Theme";
import ContentBlock from "../ContentBlock/ContentBlock";


const StyledContainer = styled.section`
  margin-top: 30px;
  background-image: url("./images/classes/bg-darkBlue-mobile.svg");
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: ${theme.tablet}) {
    margin-top: 60px;
    background-image: url("./images/classes/bg-darkBlue-desktop.svg");
  }
`;

const StyledInnerContainer = styled.div`
  margin: 0 16px;
  padding: 90px 0 40px;

  @media (min-width: ${theme.standardDesktop}) {
    padding: 110px 0 40px;
    width: 80%;
    margin: 0 auto;
  }
`;


const AdditionalClasses = ({ data }) => {
  return (
    <StyledContainer>
      <StyledInnerContainer>
        <ContentBlock data={data} color={theme.white} />
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default AdditionalClasses;
