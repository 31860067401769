import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

// import required modules
import { Grid, Pagination } from "swiper/modules";

import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";
import Document from "../Document/Document";

const StyledContainer = styled.div`
  margin: 2% 5% 7%;

  .swiper {
    padding: 3% 0% 8%;

    @media (min-width: ${theme.tablet}) {
      padding: 2% 0% 7%;
    }
    @media (min-width: ${theme.smallDesktop}) {
      padding: 1% 0% 4%;
    }
  }
  @media (min-width: ${theme.standardDesktop}) {
    margin: 1% 5% 5%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 2% 10% 4%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 0% 10% 3%;
  }
`;
const StyledTitle = styled(StyledElement)`
  border-bottom: 1px solid #000;
  width: 25%;
  @media (min-width: ${theme.tablet}) {
    width: 14%;
  }
  @media (min-width: ${theme.smallDesktop}) {
    width: 10%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 30px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    width: 7%;
  }
`;

const Documents = ({ data }) => {
  return (
    <StyledContainer>
      <StyledTitle
        as="h1"
        fontFamily="'Exo 2', sans-serif"
        fontWeight="700"
        fontSize="26px"
        lineheight="58px"
      >
        Документи
      </StyledTitle>
      <Swiper
        slidesPerView={1}
        grid={{
          rows: 2,
          fill: "row",
        }}
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
        modules={[Grid, Pagination]}
        breakpoints={{
          768: {
            slidesPerView: 2,
            spaceBetween: 0,
            rows: 2,
            fill: "row",
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 5,
            rows: 2,
            fill: "row",
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 5,
            rows: 2,
            fill: "row",
          },
        }}
      >
        {data?.map((document, index) => (
          <SwiperSlide key={index}>
            <Document name={document.name} link={document.link} />
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledContainer>
  );
};

export default Documents;
