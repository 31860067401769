import React, { useEffect, useState } from "react";
import HeroBanner from "../Components/HeroBanner/HeroBanner";
import AnnouncementCards from "../Components/AnnouncementCards/AnnouncementCards";
import Documents from "../Components/Documents/Documents";
import EventsBoard from "../Components/EventsBoard/EventsBoard";
import OpenDay from "../Components/OpenDay/OpenDay";
import AwardedStudents from "../Components/AwardedStudents/AwardedStudents";
import GroupAwards from "../Components/GroupAwards/GroupAwards";
import Header from "../Components/Header/Header";
import { axios, makeApiRoute } from "../Api";
import { Footer } from "../Components/Footer/Footer";
import PageLoader from "../Components/Loader/PageLoader";
import ScrollToTopPage from "../Components/ScrollToTop/ScrollToTop";
import { useLocation, useParams } from "react-router-dom";

export const News = () => {
  const [newsData, setNewsData] = useState([]);
  const isObjectEmpty = Object.keys(newsData).length === 0;

  const location = useLocation()


  useEffect(() => {
    axios
      .get(makeApiRoute("newsPage"))
      .then((response) => {
        setNewsData(response.data);
      })
      .catch((error) => {

      });
  }, []);
  if (isObjectEmpty) return <PageLoader />;

  return (
    <div>
      <ScrollToTopPage />
      <Header />
      <HeroBanner
        heading="Новости"
        paragraph=""
        imageUrl="/images/heroBanner/news.svg"
      />
      {newsData?.announcements?.length > 0  ? <AnnouncementCards data={newsData?.announcements} /> : ""}
      {newsData?.documents?.length > 0 ? <Documents data={newsData?.documents} /> : ""}
      <EventsBoard data={newsData?.events} hash={location?.hash}/>
      <OpenDay data={newsData?.contents?.news} hash={location?.hash}/>
      <AwardedStudents data={newsData?.individual_awards} hash={location?.hash}/>
      <GroupAwards data={newsData?.group_awards} />
      <Footer />
    </div>
  );
};
