import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
    width: 100%;
    height: 440px;

    iframe {
        width: 100%;
        height: 100%;
    }
`

export const GoogleMap = () => {
  return (
    <StyledContainer>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2965.2851743406445!2d21.44155217609051!3d41.99415497122922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135415b95fbfc221%3A0xe807462f255ca038!2z0KAu0IjQvtCy0YfQtdCy0YHQutC4INCa0L7RgNGH0LDQs9C40L0sIFNrb3BqZSAxMDAw!5e0!3m2!1sen!2smk!4v1694682944677!5m2!1sen!2smk" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </StyledContainer>
  )
}
