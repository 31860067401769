import React from "react";
import styled from "styled-components";
import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";

const StyledTable = styled(StyledElement)`
  width: 100%;
  text-align: center;
  th {
    font-size: 16px;
    padding-bottom: 8px;
    font-weight: 300;
  }
  td {
    font-weight: 700;
    font-size: 30px;
  }
  td.time-countdown {
    position: relative;
  }
  td span.timer-dots {
    position: absolute;
    right: -8%;
  }
  td span.value-countdown {
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${theme.mediumMobile}) {
    th {
      font-size: 20px;
      line-height: 40px;
    }
    td {
      font-size: 48px;
      line-height: 56px;
    }
  }

  @media (min-width: ${theme.standardDesktop}) {
    td span.timer-dots {
      right: 10%;
    }
  }

  @media (min-width: ${theme.largeDesktop}) {
    td {
      font-size: 60px;
    }
  }
`;

const CountdownTimer = ({ days, hours, minutes, seconds }) => {
  return (
    <StyledTable as="table" color={`${theme.white}`}>
      <thead>
        <tr>
          <th>Денови</th>
          <th>Часови</th>
          <th>Минути</th>
          <th>Секунди</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td className="time-countdown">
            <span className="value-countdown">{days}</span>
            <span className="timer-dots">:</span>
          </td>
          <td className="time-countdown">
            <span className="value-countdown">{hours}</span>
            <span className="timer-dots">:</span>
          </td>
          <td className="time-countdown">
            <span className="value-countdown">{minutes}</span>
            <span className="timer-dots">:</span>
          </td>
          <td>
            <span>{seconds}</span>
          </td>
        </tr>
      </tbody>
    </StyledTable>
  );
};

export default CountdownTimer;
