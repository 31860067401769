import React from "react";
import styled from "styled-components";
import AwardsCard from "./AwardsCard";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper/modules";

const StyledContainer = styled.section`
  background-image: url("./images/awards/background.svg");
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: ${theme.tablet}) {
    background-image: url("./images/awards/background-desktop.svg");
  }
`;

const StyledInnerContainer = styled.div`
  margin: 0 16px;
  padding: 100px 0 40px;

  .swiper-slide {
    padding: 50px 0;
  }

  @media (min-width: ${theme.mediumDesktop}) {
    width: 85%;
    margin: 0 auto;
  }
`;

const StyledHeading = styled(StyledElement)`
  text-align: center;

  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 46px;
  }
`;

const AwardsContainer = ({ awards }) => {
  return (
    <StyledContainer>
      <StyledInnerContainer>
        <StyledHeading
          as="h2"
          fontFamily="'Exo 2', sans-serif"
          fontSize="25px"
          fontWeight="900"
          color={theme.darkBlue}
        >
          Награди и признанија
        </StyledHeading>
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          modules={[Pagination]}
          pagination={{
            clickable: true,
          }}
        >
          {awards?.map((award) => (
            <SwiperSlide key={award.id}>
              <AwardsCard award={award}/>
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default AwardsContainer;
