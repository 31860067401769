import React, { useEffect, useState } from "react";
import HeroBanner from "../Components/HeroBanner/HeroBanner";
import { Footer } from "../Components/Footer/Footer";
import { axios, makeApiRoute } from "../Api";
import styled from "styled-components";
import theme from "../Consts/Theme";
import StyledElement from "../Consts/Text";
import PageLoader from "../Components/Loader/PageLoader";
import Header from "../Components/Header/Header";
import ScrollToTopPage from "../Components/ScrollToTop/ScrollToTop";
import Documents from "../Components/Documents/Documents";

const StyledContainer = styled(StyledElement)`
  margin: 10% 5%;
  text-align: left;

  a {
    text-decoration: underline;
    color: purple !important;
  }

  @media (min-width: ${theme.tablet}) {
    margin: 5%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 5% 10%;
    font-size: 20px;
    line-height: 32px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 25px;
  }
`;

export const SchoolCommunity = () => {
  const [schoolCommunityData, setSchoolCommunityData] = useState([]);
  const isObjectEmpty = Object.keys(schoolCommunityData).length === 0;

  useEffect(() => {
    axios
      .get(makeApiRoute("schoolCommunity"))
      .then((response) => {
        setSchoolCommunityData(response.data);
      })
      .catch((error) => {});
  }, []);
  if (isObjectEmpty) return <PageLoader />;


  return (
    <div>
      <ScrollToTopPage />
      <Header />
      <HeroBanner
        heading="Училишна заедница"
        paragraph=""
        imageUrl="/images/heroBanner/news.svg"
      />
      <StyledContainer
        as="div"
        fontWeight="400"
        fontSize="16px"
        lineheight="24px"
        dangerouslySetInnerHTML={{ __html: schoolCommunityData?.description }}
      />
       {schoolCommunityData?.documents?.length > 0 ? <Documents data={schoolCommunityData?.documents} /> : ""}
      <Footer />
    </div>
  );
};
