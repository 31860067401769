import React from "react";
import styled from "styled-components";
import theme from "../../Consts/Theme";
import MenuMobile from "./Mobile/MenuMobile";
import MenuDesktop from "./Desktop/MenuDesktop";
import useMediaQuery from "../../Hooks/useMediaQuery";
import { makeExternalLink } from "../../Api";

const StyledContainer = styled.section`
  background-color: ${theme.green};
  color: ${theme.white};
  padding: 12px 16px;
  @media (min-width: ${theme.standardDesktop}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
  }
`;

const StyledContactContainer = styled.div`
  text-align: center;

  @media (min-width: ${theme.standardDesktop}) {
    display: flex;
  }
`;
const LocationInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 5px;
  }

  @media (min-width: ${theme.standardDesktop}) {
    font-size: 18px;
  }
`;
const SocialLinksContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  gap: 16px;

  @media (min-width: ${theme.standardDesktop}) {
    margin-top: 0;
    margin-left: 20px;
  }
`;

const ExternalLinks = styled.div`
  display: none;

  @media (min-width: ${theme.standardDesktop}) {
    display: block;
    font-weight: 500;
    font-size: 16px;
    a {
      margin-left: 20px;
    }
  }
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 18px;
    a {
      margin-left: 40px;
    }
  }
`;

const Header = () => {
  const isLargeDesktop = useMediaQuery("(min-width: 1024px)");
  return (
    <>
      <StyledContainer>
        <StyledContactContainer>
          <LocationInfo>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
            >
              <path
                d="M9.44922 10.125C8.86515 10.125 8.29419 9.96005 7.80855 9.65101C7.32291 9.34197 6.9444 8.90272 6.72089 8.3888C6.49737 7.87488 6.43889 7.30938 6.55284 6.76381C6.66679 6.21824 6.94804 5.7171 7.36105 5.32376C7.77405 4.93043 8.30024 4.66256 8.87309 4.55404C9.44594 4.44552 10.0397 4.50122 10.5793 4.71409C11.1189 4.92696 11.5802 5.28745 11.9047 5.74996C12.2291 6.21247 12.4023 6.75624 12.4023 7.3125C12.4014 8.05815 12.09 8.773 11.5364 9.30025C10.9827 9.82751 10.2321 10.1241 9.44922 10.125ZM9.44922 5.625C9.09878 5.625 8.7562 5.72397 8.46482 5.9094C8.17344 6.09482 7.94633 6.35837 7.81222 6.66672C7.67811 6.97507 7.64302 7.31437 7.71139 7.64172C7.77976 7.96906 7.94851 8.26974 8.19632 8.50574C8.44412 8.74175 8.75983 8.90246 9.10354 8.96758C9.44725 9.03269 9.80352 8.99927 10.1273 8.87155C10.4511 8.74383 10.7278 8.52753 10.9225 8.25003C11.1172 7.97252 11.2211 7.64626 11.2211 7.3125C11.2206 6.86509 11.0338 6.43612 10.7016 6.11975C10.3694 5.80338 9.91901 5.62545 9.44922 5.625Z"
                fill="white"
              />
              <path
                d="M9.45001 16.875L4.46749 11.2787C4.39826 11.1947 4.32975 11.1101 4.26196 11.025C3.41085 9.95725 2.95107 8.65306 2.95313 7.3125C2.95313 5.67147 3.63762 4.09766 4.85602 2.93728C6.07442 1.7769 7.72693 1.125 9.45001 1.125C11.1731 1.125 12.8256 1.7769 14.044 2.93728C15.2624 4.09766 15.9469 5.67147 15.9469 7.3125C15.9489 8.65245 15.4894 9.95605 14.6386 11.0233L14.6381 11.025C14.6381 11.025 14.4609 11.2466 14.4343 11.2764L9.45001 16.875ZM5.20459 10.3472C5.20578 10.3472 5.3428 10.5204 5.3741 10.5576L9.45001 15.1358L13.5312 10.5514C13.5572 10.5204 13.6954 10.3461 13.696 10.3455C14.3913 9.47313 14.767 8.4077 14.7656 7.3125C14.7656 5.96984 14.2056 4.68217 13.2087 3.73277C12.2118 2.78337 10.8598 2.25 9.45001 2.25C8.04022 2.25 6.68817 2.78337 5.69129 3.73277C4.69442 4.68217 4.13438 5.96984 4.13438 7.3125C4.13314 8.40838 4.50871 9.47442 5.20459 10.3472Z"
                fill="white"
              />
            </svg>
            <span>ул. „Трета Македонска Ударна Бригада“ бр. 9</span>
          </LocationInfo>
          <SocialLinksContainer>
            <a href="https://www.facebook.com/Korchagin2022/" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.9993 21.3332C10.7737 21.3332 9.56001 21.0918 8.42764 20.6227C7.29526 20.1537 6.26637 19.4662 5.39969 18.5995C4.53301 17.7328 3.84552 16.7039 3.37647 15.5715C2.90743 14.4392 2.66602 13.2255 2.66602 11.9998C2.66602 10.7742 2.90743 9.5605 3.37647 8.42813C3.84552 7.29575 4.53301 6.26685 5.39969 5.40017C6.26637 4.53349 7.29526 3.84601 8.42764 3.37696C9.56001 2.90792 10.7737 2.6665 11.9993 2.6665C14.4747 2.6665 16.8487 3.64983 18.599 5.40017C20.3494 7.15051 21.3327 9.52448 21.3327 11.9998C21.3327 14.4752 20.3494 16.8492 18.599 18.5995C16.8487 20.3498 14.4747 21.3332 11.9993 21.3332ZM11.9993 21.3332V10.6665C11.9993 9.95926 12.2803 9.28098 12.7804 8.78089C13.2805 8.28079 13.9588 7.99984 14.666 7.99984H15.3327M8.66602 13.3332H15.3327"
                  stroke="white"
                  stroke-width="2"
                />
              </svg>
            </a>
            <a href="https://www.instagram.com/rjkorchagin/" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M16.666 6.6665H17.9993M7.99935 2.6665H15.9993C17.4138 2.6665 18.7704 3.22841 19.7706 4.2286C20.7708 5.22879 21.3327 6.58535 21.3327 7.99984V15.9998C21.3327 17.4143 20.7708 18.7709 19.7706 19.7711C18.7704 20.7713 17.4138 21.3332 15.9993 21.3332H7.99935C6.58486 21.3332 5.22831 20.7713 4.22811 19.7711C3.22792 18.7709 2.66602 17.4143 2.66602 15.9998V7.99984C2.66602 6.58535 3.22792 5.22879 4.22811 4.2286C5.22831 3.22841 6.58486 2.6665 7.99935 2.6665ZM11.9993 15.9998C10.9385 15.9998 9.92107 15.5784 9.17092 14.8283C8.42078 14.0781 7.99935 13.0607 7.99935 11.9998C7.99935 10.939 8.42078 9.92156 9.17092 9.17141C9.92107 8.42126 10.9385 7.99984 11.9993 7.99984C13.0602 7.99984 14.0776 8.42126 14.8278 9.17141C15.5779 9.92156 15.9993 10.939 15.9993 11.9998C15.9993 13.0607 15.5779 14.0781 14.8278 14.8283C14.0776 15.5784 13.0602 15.9998 11.9993 15.9998Z"
                  stroke="white"
                  stroke-width="2"
                />
              </svg>
            </a>
            <a href="https://www.youtube.com/@radejovcevskikorchagin7232" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19.607 6.995C19.531 6.697 19.315 6.472 19.068 6.403C18.63 6.28 16.501 6 12.001 6C7.501 6 5.373 6.28 4.932 6.403C4.688 6.471 4.472 6.696 4.395 6.995C4.286 7.419 4.001 9.195 4.001 12C4.001 14.804 4.286 16.58 4.395 17.006C4.471 17.303 4.687 17.528 4.933 17.596C5.373 17.72 7.5 18 12 18C16.5 18 18.629 17.72 19.069 17.597C19.313 17.529 19.529 17.304 19.606 17.005C19.716 16.581 20 14.8 20 12C20 9.2 19.716 7.42 19.607 6.995ZM21.544 6.498C22.001 8.28 22.001 12 22.001 12C22.001 12 22.001 15.72 21.544 17.502C21.29 18.487 20.547 19.262 19.606 19.524C17.897 20 12 20 12 20C12 20 6.107 20 4.395 19.524C3.45 19.258 2.708 18.484 2.457 17.502C2 15.72 2 12 2 12C2 12 2 8.28 2.457 6.498C2.711 5.513 3.454 4.738 4.395 4.476C6.108 4 12.001 4 12.001 4C12.001 4 17.897 4 19.606 4.476C20.55 4.742 21.293 5.516 21.544 6.498ZM10.001 15.5V8.5L16.001 12L10.001 15.5Z"
                  fill="white"
                />
              </svg>
            </a>
          </SocialLinksContainer>
        </StyledContactContainer>

        <ExternalLinks>
          <a href={makeExternalLink("eBiblioteka")} target="_blank">
            <span>Е-библиотека</span>
          </a>
          <a href={makeExternalLink("microsoft")} target="_blank">
            <span>Microsoft 365</span>
          </a>
          <a href={makeExternalLink("eDnevnik")} target="_blank">
            <span>Е-дневник</span>
          </a>
        </ExternalLinks>
      </StyledContainer>

      {!isLargeDesktop ? <MenuMobile /> : <MenuDesktop />}
    </>
  );
};

export default Header;
