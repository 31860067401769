import React from "react";
import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";
import { Link } from "react-router-dom";

const StyledContainer = styled.div`
  text-align: center;
  margin: 7% 5%;
  @media (min-width: ${theme.tablet}) {
    margin: 5%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 5% 10%;
  }
`;
const GoBackContainer = styled(Link)`
  display: block;
  text-align: left;
  margin-bottom: 2%;
`;
const GoBack = styled(StyledElement)`
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 20px;
  }
`;
const EventImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 35vh;
  flex-shrink: 0;
  border-radius: 10px;
  margin-bottom: 8%;
  @media (min-width: ${theme.standardMobile}) {
    height: 40vh;
  }
  @media (min-width: ${theme.mediumMobile}) {
    height: 45vh;
  }
  @media (min-width: ${theme.tablet}) {
    height: 50vh;
    margin-bottom: 3%;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    height: 40vh;
  }
`;

const StyledHeading = styled(StyledElement)`
  margin-bottom: 38px;
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 36px;
    line-height: 46px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 40px;
    line-height: 48px;
  }
`;
const StyledDiv = styled(StyledElement)`
  text-align: left;
  a {
    text-decoration: underline;
    color: purple !important;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 20px;
    line-height: 32px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 25px;
  }
`;

const StyledDate = styled(StyledElement)`
  display: block;
  text-align: right;
  margin-top: 3%;
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 20px;
  }
`;

const ContentEventPage = ({data}) => {
  const date = data?.start_at
  const getDate = new Date(date).getDate();
  const getMonth = new Date(date).getMonth() + 1;
  const getYear = new Date(date).getFullYear();

  return (
    <StyledContainer>
      <GoBackContainer to="/news">
        <GoBack
          as="span"
          fontWeight="500"
          fontSize="16px"
          lineheight="32px"
          color={`${theme.magenta}`}
        >
          {" "}
          Назад
        </GoBack>
      </GoBackContainer>
      {data?.image ? <EventImage src={data?.image} /> : ""}
      <StyledHeading
        as="h1"
        fontFamily="'Exo 2', sans-serif"
        fontWeight="800"
        fontSize="32px"
        lineheight="48px"
      >
        {data?.name}
      </StyledHeading>
      <StyledDiv
        as="div"
        fontWeight="400"
        fontSize="16px"
        lineheight="24px"
        dangerouslySetInnerHTML={{ __html: data?.description}}>
      </StyledDiv>
      <StyledDate
        as="span"
        fontWeight="500"
        fontSize="16px"
        lineheight="32px"
        color={`${theme.magenta}`}
      >
        {" "}
        {getDate}.{getMonth}.{getYear}
      </StyledDate>
    </StyledContainer>
  );
};

export default ContentEventPage;
