import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";

import { axios, makeApiRoute, makeAppRoute } from "../Api";
import { Footer } from "../Components/Footer/Footer";
import HeroBanner from "../Components/HeroBanner/HeroBanner";
import ContentAnnouncementPage from "../Components/ContentAnnouncementPage/ContentAnnouncementPage";
import Header from "../Components/Header/Header";
import ScrollToTopPage from "../Components/ScrollToTop/ScrollToTop";
import PageLoader from "../Components/Loader/PageLoader";
import StyledElement from "../Consts/Text";
import theme from "../Consts/Theme";

const GoBackContainer = styled(Link)`
  display: block;
  text-align: left;
  margin-bottom: 2%;
  margin: 30px 16px 30px;

  @media (min-width: ${theme.mediumDesktop}) {
  width: 80%;
  margin: 50px auto 30px;
  }
`;
const GoBack = styled(StyledElement)`
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 20px;
  }
`;

export const NewsCardPage = () => {
  // params
  const { NEWS_ID } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get(makeApiRoute("newsCardPage", { NEWS_ID: NEWS_ID }))
      .then((response) => {
        setData(response.data);
      })
      .catch(function (error) {
        throw error.response;
      });
  }, [NEWS_ID]);
  if (!data) return <PageLoader />;
  return (
    <div>
      <ScrollToTopPage />
      <Header />
      <HeroBanner
        heading="Новости"
        paragraph=""
        imageUrl="/images/heroBanner/teaching.svg"
      />
      <GoBackContainer to={makeAppRoute("home")}>
        <GoBack
          as="span"
          fontWeight="500"
          fontSize="16px"
          lineheight="32px"
          color={`${theme.magenta}`}
        >
          {" "}
          Назад
        </GoBack>
      </GoBackContainer>
      <ContentAnnouncementPage data={data?.data} />
      <Footer />
    </div>
  );
};
