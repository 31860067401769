import React from "react";
import styled from "styled-components";
import { makeExternalLink } from "../../Api";

const StyledContainer = styled.div`
  display: grid;
  gap: 16px;
  justify-content: space-evenly;
  margin: 50px 10px;

  a {
    display: inline-flex;
    align-items: center;
  }
  @media (min-width: 425px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 768px) {
    margin: 80px 150px;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1440px) {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const links = [
  {
    name: "gov",
    externalLink: makeExternalLink("mon") ,
    imgMobile: "./images/institutions/small/mon-mobile.svg",
    imgDesktop: "./images/institutions/large/mon.svg",
  },
  {
    name: "dic",
    externalLink:  makeExternalLink("dic") ,
    imgMobile: "./images/institutions/small/dic-mobile.svg",
    imgDesktop: "./images/institutions/large/dic.svg",
  },
  {
    name: "bro",
    externalLink:  makeExternalLink("bro") ,
    imgMobile: "./images/institutions/small/bro-mobile.svg",
    imgDesktop: "./images/institutions/large/bro.svg",
  },
  {
    name: "brainsterNext",
    externalLink:  makeExternalLink("brainsterNext") ,
    imgMobile: "https://next.edu.mk/wp-content/uploads/2021/12/COLLEGE-BRAINSTER-NEXT-logo-1.svg",
    imgDesktop: "https://next.edu.mk/wp-content/uploads/2021/12/COLLEGE-BRAINSTER-NEXT-logo-1.svg",
  },
  {
    name: "azlp",
    externalLink:  makeExternalLink("azlp") ,
    imgMobile: "./images/institutions/small/dzlp-mobile.svg",
    imgDesktop: "./images/institutions/large/dzlp.svg",
  },
  {
    name: "ednevnik",
    externalLink:  makeExternalLink("eDnevnik") ,
    imgMobile: "./images/institutions/small/eDnevnik-mobile.svg",
    imgDesktop: "./images/institutions/large/eDnevnik.svg",
  },

];

export const StateInstitutionsLinks = () => {
  return (
    <StyledContainer>
      {links.map((link, index) => (
        <a key={index} href={link.externalLink} target="_blank">
          <picture>
            <source media="(min-width:768px)" srcSet={link.imgDesktop}></source>
            <img src={link.imgMobile} />
          </picture>
        </a>
      ))}
    </StyledContainer>
  );
};
