import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";
import Card from "./Card";
import Button from "../Button/Button";

const StyledContainer = styled.div`
  background-color: ${theme.babyBlue};
  padding-top: 8px;

  @media (min-width: ${theme.smallDesktop}) {
    padding: 0 5% 15%;
    display: flex;
    flex-direction: row-reverse;
  }

  @media (min-width: ${theme.mediumDesktop}) {
    padding: 0 10% 10%;
  }
`;
const StyledContentContainer = styled.div`
  padding: 5px 16px 44px;
  button.btn-about-us {
    width: 100%;
    padding: 16px 0;
  }
  @media (min-width: ${theme.tablet}) {
    padding: 42px 20px;
    button.btn-about-us {
      width: auto;
      padding: 20px 80px;
    }
  }
  @media (min-width: ${theme.smallDesktop}) {
    width: 60%;
    align-self: center;
    padding: 0;
  }
`;
const StyledImageContainer = styled.div`
  position: relative;

  img.girl-mobile {
    display: block;
    width: 100%;
  }

  div.students,
  div.classes,
  div.years,
  div.projects {
    position: absolute;
  }

  @keyframes fade-in-top {
    0% {
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  div.students {
    top: 38%;
    left: 3%;
    animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  div.classes {
    top: 55%;
    right: 0;
    animation: fade-in-top 0.8s cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  div.years {
    top: 70%;
    left: 3%;
    animation: fade-in-top 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  div.projects {
    top: 85%;
    right: 5%;
    animation: fade-in-top 1.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  @media (min-width: ${theme.standardMobile}) {
    div.projects {
      top: 87%;
    }
  }

  @media (min-width: ${theme.smallDesktop}) {
    width: 40%;
    align-self: center;

    div.students {
      top: 20%;
      left: -15%;
    }

    div.classes {
      top: 50%;
      right: -5%;
    }

    div.years {
      top: 60%;
      left: -15%;
    }

    div.projects {
      top: 83%;
      right: 20%;
    }
  }
  @media (min-width: ${theme.mediumDesktop}) {
    width: 40%;
    div.sticker {
      position: absolute;
      top: 50%;
      left: -20%;
    }
  }

  @media (min-width: ${theme.largeDesktop}) {
    div.classes {
      right: -15%;
    }

    div.years {
      top: 70%;
      left: -25%;
    }

    div.projects {
      top: 85%;
    }

    div.sticker {
      position: absolute;
      top: 55%;
      left: -17%;
    }
  }
`;
const StyledSticker = styled.img`
  display: none;

  @media (min-width: ${theme.mediumDesktop}) {
    display: block;
    width: 75%;
  }
  @media (min-width: ${theme.largeDesktop}) {
    width: 90%;
  }
`;
const StyledHeading = styled(StyledElement)`
  text-transform: uppercase;

  @media (min-width: ${theme.mediumMobile}) {
    font-size: 60px;
    line-height: 68px;
  }

  @media (min-width: ${theme.smallDesktop}) {
    display: none;
  }
`;
const StyledHeadingDesktop = styled(StyledElement)`
  text-transform: uppercase;
  padding-top: 100px;
  display: none;

  @media (min-width: ${theme.smallDesktop}) {
    display: block;

    /* animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1);
    @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  } */
  }

  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 70px;
    line-height: 80px;
  }
  @media (min-width: ${theme.largeDesktop}) {
    font-size: 90px;
    line-height: 100px;
  }
`;
const MagentaText = styled.span`
  color: ${theme.magenta};
`;

const StyledParagraph = styled(StyledElement)`
  padding: 38px 0;

  @media (min-width: ${theme.tablet}) {
    font-size: 25px;
  }

  @media (min-width: ${theme.smallDesktop}) {
    font-size: 18px;
    line-height: 28px;
    width: 80%;
  }

  @media (min-width: ${theme.mediumDesktop}) {
    width: 60%;
    font-size: 20px;
    line-height: 32px;
  }
  @media (min-width: ${theme.largeDesktop}) {
    width: 65%;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    width: 65%;
    font-size: 30px;
    line-height: 35px;
  }
`;

export const HeroBannerHomePage = ({ content, statistics }) => {
  const navigate = useNavigate();

  const onAboutUsClick = () => {
    navigate("/about-us");
  };
  return (
    <StyledContainer>
      <StyledImageContainer>
        <picture>
          <source
            media="(min-width:992px)"
            srcSet="./images/heroBanner/girl.png"
          ></source>
          <img
            className="girl-mobile"
            src="./images/heroBanner/girl-mobile.png"
          />
        </picture>
        <div className="sticker">
          <StyledSticker src="./images/heroBanner/sticker.svg" />
        </div>
        <div className="students">
          <Card
            icon="./images/heroBanner/icons/student.svg"
            title={`${statistics?.[0]?.value} ${statistics?.[0]?.name}`}
            bgColor="#FF5656"
          />
        </div>
        <div className="classes">
          <Card
            icon="./images/heroBanner/icons/class.svg"
            title={`${statistics?.[2]?.value} ${statistics?.[2]?.name}`}
            bgColor="#84AEFF"
          />
        </div>
        <div className="years">
          <Card
            icon="./images/heroBanner/icons/years.svg"
            title={`${statistics?.[1]?.value} ${statistics?.[1]?.name}`}
            bgColor="#5FA4BD"
          />
        </div>
        <div className="projects">
          <Card
            icon="./images/heroBanner/icons/erasmus.svg"
            title={`${statistics?.[3]?.value} ${statistics?.[3]?.name}`}
            bgColor="#FF7373"
          />
        </div>
      </StyledImageContainer>
      <StyledContentContainer>
        <StyledHeading
          as="h1"
          fontFamily="'Exo 2', sans-serif"
          fontWeight="900"
          fontSize="46px"
          lineheight="55px"
          color={`${theme.darkBlue}`}
        >
          Раде Јовчевски-
          <wbr />
          Корчагин
        </StyledHeading>
        <StyledHeadingDesktop
          as="h1"
          fontFamily="'Exo 2', sans-serif"
          fontWeight="900"
          fontSize="50px"
          lineheight="60px"
          color={`${theme.darkBlue}`}
        >
          Биди бренд <br /> биди <MagentaText>корчагин</MagentaText>
        </StyledHeadingDesktop>
        <StyledParagraph
          as="p"
          fontSize="16px"
          lineheight="32px"
          color={`${theme.darkBlue}`}
        >
          {content?.description}
        </StyledParagraph>

        <Button
          type="button"
          onClick={onAboutUsClick}
          bgColor={theme.green}
          hoverBgColor={theme.darkGreen}
          color={theme.white}
          content="За Нас"
          className="btn-about-us"
        />
      </StyledContentContainer>
    </StyledContainer>
  );
};
