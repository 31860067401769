import React from "react";
import styled from "styled-components";
import StatisticCard from "./StatisticCard";
import theme from "../../Consts/Theme";

const StyledContainer = styled.div`
  padding: 64px;

  @media (min-width: ${theme.standardMobile}) {
    display: grid;
    grid-template-columns: 46% 46%;
    grid-column-gap: 6%;
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (min-width: ${theme.tablet}) {
    padding-left: 15%;
    padding-right: 15%;
  }

  @media (min-width: ${theme.smallDesktop}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 2%;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: -22vh;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    padding-left: 10%;
    padding-right: 10%;
    margin-top: -20vh;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    margin-top: -18vh;
  }
`;

const cards = [
  {
    numbers: "62",
    title: "Години",
  },
  {
    numbers: "120+",
    title: "Вработени",
  },
  {
    numbers: "1000+",
    title: "Ученици",
  },
  {
    numbers: "36",
    title: "Паралелки",
  },
];

const StatisticCards = ({data}) => {

  return (
    <StyledContainer>
      {data?.map((card, index) => (
        <StatisticCard key={index} numbers={card.value} title={card.name} />
      ))}
    </StyledContainer>
  );
};

export default StatisticCards;
