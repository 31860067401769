import React from "react";
import styled from "styled-components";

import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";
import useCountdown from "../../Hooks/useCountdown";
import CountdownTimer from "../CountdownTimer/CountdownTimer";
import Button from "../Button/Button";
import useMediaQuery from "../../Hooks/useMediaQuery";

const StyledContainer = styled.section`
  text-align: center;
  background-color: ${theme.lightBlue};
  flex-shrink: 0;
  margin: 120px 16px 80px;
  border-radius: 15px;
  padding: 8px;
  filter: drop-shadow(29px 38px 57px rgba(9, 30, 57, 0.2));

  button.btn-alumni {
    padding: 16px 60px;
    margin-bottom: 40px;
  }

  @media (min-width: ${theme.tablet}) {
    text-align: unset;
    border-radius: 30px;
    margin-left: 5%;
    margin-right: 5%;
  }
  @media (min-width: ${theme.standardDesktop}) {
    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
    margin: 5% 5% 80px;
    padding-bottom: 0;
  }

  @media (min-width: ${theme.mediumDesktop}) {
    margin: 80px 10% 80px;
    justify-content: space-between;
    padding-left: 8%;
    padding-right: 5%;
  }
  @media (min-width: ${theme.largeDesktop}) {
    padding-right: 8%;
  }
`;
const StyledImageContainer = styled.div`
  position: relative;
  text-align: center;

  div.img-absolute {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -60%);
  }
  svg path.pulse-path-lights {
    animation: pulse-path-lights 1.5s infinite;
  }

  svg path.pulse-path-lamp {
    animation: pulse-path-lamp 1.5s infinite;
    animation-delay: 0.2;
  }

  @keyframes pulse-path-lamp {
    0% {
      fill: rgba(235, 188, 59);
      filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
    }

    50% {
      fill: transparent;
    }

    100% {
      fill: #ebbc3b;
      filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
    }
  }
  @keyframes pulse-path-lights {
    0% {
      stroke: #d4780e;
      filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
    }

    50% {
      stroke: transparent;
    }

    100% {
      stroke: #d4780e;
      filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
    }
  }

  @media (min-width: ${theme.standardDesktop}) {
    margin-top: -10%;
    width: 45%;

    div.img-absolute {
      position: static;
      transform: translate(0, 0);
    }
    div svg {
      width: 100%;
    }
  }

  @media (min-width: ${theme.mediumDesktop}) {
    width: auto;
  }
`;
const StyledContentContainer = styled.article`
  padding-top: 100px;
  text-align: center;

  @media (min-width: ${theme.standardDesktop}) {
    padding-top: 50px;
    height: auto;
    text-align: left;
    width: 50%;

    table {
      width: 80%;
      margin-right: auto;
      text-align: left;
    }
  }
`;
const StyledHeading = styled(StyledElement)`
  margin-bottom: 24px;
  text-align: center;

  @media (min-width: ${theme.mediumMobile}) {
    font-size: 38px;
  }

  @media (min-width: ${theme.tablet}) {
    font-size: 56px;
  }
  @media (min-width: ${theme.standardDesktop}) {
    text-align: left;
  }
`;

const StyledLocation = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 48px;

  @media (min-width: ${theme.tablet}) {
    padding-bottom: 27px;
  }

  @media (min-width: ${theme.standardDesktop}) {
    margin-bottom: 0;
  }
`;
const LocationIcon = styled.img`
  width: 20px;
  height: 20px;
`;
const LocationTitle = styled(StyledElement)`
  padding-left: 8px;
  @media (min-width: ${theme.tablet}) {
    font-size: 20px;
    line-height: 40px;
  }
`;

export const CountdownEvent = ({ data }) => {
  const [days, hours, minutes, seconds] = useCountdown(data?.start_at);

  const isDesktop = useMediaQuery("(min-width: 1024px)");

  return (
    <StyledContainer>
      <StyledImageContainer>
        <div className="img-absolute">
          {!isDesktop ? (
            <svg
              width="197"
              height="236"
              viewBox="0 0 197 236"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M191.483 201.15C192.456 199.438 193.251 197.592 193.824 195.619C194.398 193.645 194.716 191.66 194.812 189.694L189.402 188.944C189.446 187.119 189.238 185.322 188.807 183.591L193.93 181.678C192.861 177.795 190.888 174.207 188.183 171.223L183.822 174.523C182.593 173.231 181.187 172.092 179.622 171.151L181.889 166.182C180.177 165.21 178.33 164.414 176.357 163.841C174.384 163.267 172.398 162.95 170.432 162.854L169.683 168.264C167.858 168.22 166.06 168.427 164.329 168.859L162.416 163.736C158.533 164.805 154.945 166.778 151.961 169.483L155.261 173.844C153.969 175.073 152.83 176.479 151.89 178.044L146.921 175.777C145.948 177.489 145.153 179.336 144.579 181.309C144.006 183.282 143.688 185.267 143.592 187.233L149.002 187.983C148.957 189.808 149.165 191.605 149.597 193.336L144.474 195.249C145.544 199.132 147.516 202.72 150.222 205.704L154.582 202.404C155.811 203.696 157.218 204.835 158.782 205.776L156.516 210.745C158.227 211.717 160.074 212.513 162.047 213.086C164.02 213.66 166.005 213.977 167.972 214.074L168.721 208.663C170.546 208.708 172.344 208.5 174.074 208.068L175.987 213.191C179.871 212.122 183.458 210.149 186.443 207.444L183.143 203.084C184.435 201.854 185.574 200.448 186.514 198.883L191.483 201.15ZM165.591 200.889C158.729 198.895 154.782 191.716 156.777 184.853C158.771 177.99 165.95 174.043 172.813 176.038C179.676 178.032 183.622 185.212 181.628 192.074C179.634 198.937 172.454 202.883 165.591 200.889ZM166.54 197.626C161.479 196.156 158.568 190.861 160.039 185.801C161.51 180.74 166.805 177.83 171.865 179.301C176.926 180.771 179.836 186.066 178.365 191.126C176.894 196.187 171.6 199.097 166.54 197.626Z"
                fill="#E4ECF9"
              />
              <path
                d="M197 148.42C196.915 147.462 196.72 146.499 196.397 145.546C196.075 144.594 195.644 143.708 195.128 142.891L192.704 144.09C192.207 143.344 191.614 142.679 190.949 142.104L192.505 139.913C190.971 138.649 189.152 137.754 187.202 137.304L186.35 139.841C185.483 139.662 184.586 139.595 183.68 139.651L183.205 137.024C182.23 137.108 181.25 137.301 180.281 137.618C179.312 137.934 178.41 138.357 177.579 138.865L178.799 141.247C178.04 141.736 177.363 142.318 176.777 142.971L174.549 141.443C173.262 142.95 172.352 144.738 171.894 146.654L174.475 147.492C174.293 148.344 174.225 149.226 174.282 150.116L171.609 150.583C171.695 151.541 171.89 152.504 172.213 153.456C172.535 154.408 172.966 155.295 173.482 156.111L175.906 154.912C176.403 155.658 176.996 156.323 177.66 156.899L176.105 159.089C177.638 160.353 179.458 161.248 181.407 161.698L182.259 159.162C183.127 159.34 184.024 159.408 184.929 159.352L185.404 161.978C186.379 161.894 187.359 161.702 188.327 161.384C189.296 161.068 190.198 160.645 191.03 160.138L189.809 157.755C190.568 157.266 191.245 156.684 191.831 156.031L194.059 157.559C195.346 156.052 196.257 154.264 196.714 152.348L194.133 151.511C194.315 150.658 194.384 149.777 194.327 148.887L197 148.42ZM186.014 154.547C183.179 155.474 180.115 153.967 179.171 151.179C178.228 148.392 179.761 145.381 182.597 144.454C185.433 143.527 188.497 145.034 189.441 147.822C190.384 150.608 188.85 153.62 186.014 154.547Z"
                fill="#E4ECF9"
              />
              <path
                d="M63.9151 130.103C63.7856 128.624 63.4886 127.137 62.9993 125.667C62.51 124.197 61.8571 122.828 61.0747 121.566L57.3959 123.419C56.6421 122.267 55.7431 121.24 54.7343 120.351L57.0944 116.97C54.7674 115.018 52.0066 113.636 49.0496 112.942L47.7566 116.857C46.4407 116.582 45.0799 116.477 43.7057 116.564L42.9844 112.509C41.5053 112.639 40.0185 112.936 38.5484 113.425C37.0783 113.914 35.7097 114.567 34.4481 115.35L36.3004 119.029C35.1487 119.782 34.1219 120.681 33.233 121.69L29.8518 119.33C27.8998 121.657 26.5177 124.418 25.8239 127.375L29.7392 128.668C29.464 129.984 29.3592 131.345 29.4455 132.719L25.3906 133.44C25.5201 134.919 25.8171 136.406 26.3064 137.876C26.7957 139.346 27.4486 140.715 28.231 141.977L31.9098 140.124C32.6636 141.276 33.5626 142.303 34.572 143.192L32.2119 146.573C34.5389 148.525 37.2997 149.908 40.2566 150.601L41.5496 146.686C42.8661 146.961 44.2269 147.066 45.6006 146.979L46.3219 151.034C47.8004 150.904 49.2878 150.607 50.7579 150.118C52.2279 149.629 53.5966 148.976 54.8581 148.193L53.0058 144.514C54.1576 143.761 55.1843 142.862 56.0732 141.853L59.4544 144.213C61.4065 141.886 62.7886 139.125 63.4824 136.168L59.5671 134.875C59.8423 133.559 59.9471 132.198 59.8602 130.824L63.9151 130.103ZM47.2449 139.563C42.9418 140.995 38.2934 138.667 36.8614 134.364C35.4295 130.061 37.757 125.412 42.0602 123.98C46.3634 122.548 51.0118 124.876 52.4437 129.179C53.8757 133.482 51.5481 138.131 47.2449 139.563Z"
                fill="#E4ECF9"
              />
              <path
                d="M27.0152 160.816C27.5639 159.851 28.0125 158.809 28.3363 157.696C28.6595 156.583 28.8391 155.463 28.8933 154.354L25.8413 153.932C25.8665 152.902 25.749 151.888 25.5057 150.912L28.3956 149.833C27.7921 147.642 26.6797 145.618 25.1534 143.935L22.6936 145.796C22.0001 145.068 21.2071 144.425 20.3245 143.895L21.603 141.092C20.6377 140.543 19.5957 140.094 18.4828 139.771C17.3698 139.447 16.2495 139.268 15.1405 139.214L14.7176 142.266C13.688 142.24 12.674 142.358 11.698 142.601L10.6192 139.711C8.42846 140.315 6.40496 141.427 4.7215 142.954L6.58281 145.413C5.85406 146.107 5.21144 146.9 4.68124 147.783L1.87809 146.504C1.32943 147.47 0.880878 148.512 0.557051 149.625C0.233783 150.738 0.0542508 151.858 0 152.967L3.05147 153.39C3.02631 154.419 3.14375 155.433 3.38704 156.409L0.49721 157.488C1.10068 159.679 2.2131 161.703 3.7394 163.386L6.19915 161.524C6.89266 162.253 7.68573 162.896 8.56884 163.426L7.29031 166.229C8.25564 166.778 9.2976 167.226 10.4106 167.55C11.5236 167.873 12.6438 168.053 13.7529 168.107L14.1757 165.055C15.2053 165.08 16.2193 164.963 17.1953 164.719L18.2747 167.609C20.4654 167.006 22.4889 165.893 24.1724 164.367L22.3111 161.907C23.0398 161.214 23.6825 160.421 24.2127 159.538L27.0152 160.816ZM12.41 160.669C8.5392 159.544 6.31267 155.494 7.4374 151.623C8.56213 147.753 12.6125 145.526 16.4833 146.651C20.3541 147.776 22.5807 151.826 21.4554 155.697C20.3312 159.568 16.2814 161.794 12.41 160.669ZM12.9453 158.829C10.0907 158 8.44916 155.013 9.27858 152.159C10.108 149.304 13.0946 147.663 15.9492 148.492C18.8038 149.321 20.4453 152.308 19.6159 155.163C18.7859 158.016 15.7999 159.659 12.9453 158.829Z"
                fill="#E4ECF9"
              />
              <path
                d="M72.0684 172.204C72.0684 172.204 79.4085 153.854 101.907 140.61C101.907 140.61 107.651 125.644 115.874 120.436C118.626 118.692 121.997 121.569 120.803 124.601L112.597 145.44H136.695L72.0684 172.204Z"
                fill="#365896"
              />
              <path
                className="pulse-path-lamp"
                d="M136.214 60.8281C134.374 47.517 122.81 30.4043 100.471 30.4043C78.1317 30.4043 66.5681 47.517 64.7283 60.8281C62.9201 73.9121 66.324 81.4654 72.8128 92.1031C79.9115 103.74 82.5993 119.761 82.5993 119.761H100.471H118.342C118.342 119.761 121.03 103.74 128.128 92.1031C134.618 81.4647 138.022 73.9121 136.214 60.8281Z"
                fill="#EBBC3B"
              />
              <path
                d="M100.469 119.074C92.6242 119.074 85.6911 118.701 81.6406 118.135L85.2101 142.734C85.3177 143.476 92.149 144.07 100.469 144.07C108.789 144.07 115.621 143.476 115.728 142.734L119.298 118.135C115.247 118.702 108.314 119.074 100.469 119.074Z"
                fill="#1B2536"
              />
              <path
                d="M100.471 148.295C105.846 148.295 110.202 145.187 110.202 141.354C110.202 137.521 105.846 134.413 100.471 134.413C95.097 134.413 90.7402 137.521 90.7402 141.354C90.7402 145.187 95.097 148.295 100.471 148.295Z"
                fill="#1B2536"
              />
              <path
                d="M109.975 88.8049L111.643 77.3694H111.627C111.702 76.9266 111.774 76.4719 111.842 76.0067C113.08 67.5196 112.519 59.1526 110.322 59.1526C108.958 59.1526 107.493 62.5617 106.473 67.3091C106.111 62.5617 105.118 59.1526 103.754 59.1526C102.391 59.1526 101.162 62.5617 100.471 67.3091C99.7797 62.5617 98.551 59.1526 97.187 59.1526C95.8236 59.1526 94.8311 62.5617 94.4681 67.3091C93.4479 62.5617 91.9836 59.1526 90.6195 59.1526C88.422 59.1526 87.8611 67.5202 89.0991 76.0067C89.1671 76.4719 89.239 76.9266 89.3142 77.3694L90.966 88.8049"
                stroke="white"
                stroke-width="0.875556"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M83.8796 22.6534L77.1327 4.11523"
                stroke="#D4780E"
                stroke-width="1.31333"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M69.2901 31.0765L56.6093 15.9646"
                stroke="#D4780E"
                stroke-width="1.31333"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M58.4612 43.9825L41.3768 34.1182"
                stroke="#D4780E"
                stroke-width="1.31333"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M52.6998 59.813L33.2715 56.3867"
                stroke="#D4780E"
                stroke-width="1.31333"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M100.471 0V19.728"
                stroke="#D4780E"
                stroke-width="1.31333"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M123.807 4.11523L117.061 22.6534"
                stroke="#D4780E"
                stroke-width="1.31333"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M144.331 15.9646L131.651 31.0765"
                stroke="#D4780E"
                stroke-width="1.31333"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M159.565 34.1182L142.48 43.9825"
                stroke="#D4780E"
                stroke-width="1.31333"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M167.668 56.3867L148.24 59.813"
                stroke="#D4780E"
                stroke-width="1.31333"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M102.543 199.49V180.502L144.565 155.132C150.614 151.481 154.792 145.397 156.03 138.441L160.204 114.976C160.472 113.468 159.411 112.031 157.887 111.879C155.42 111.632 151.764 112.315 148.977 117.314L149.359 104.93C149.409 103.304 147.997 102.018 146.384 102.22L145.492 102.332C140.466 102.96 136.694 107.233 136.694 112.298V133.111L132.276 112.829C131.948 111.325 130.19 110.644 128.934 111.535L127.406 112.619C123.774 115.195 122.005 119.673 122.898 124.035L126.797 143.085L115.75 125.367C115.13 124.373 113.73 124.253 112.951 125.128C110.704 127.646 110.181 131.265 111.622 134.317L117.383 146.515L108.926 154.334C108.926 154.334 67.918 157.685 67.918 181.62C67.918 191.991 67.918 199.491 67.918 199.491H102.543V199.49Z"
                fill="#4A75C9"
              />
              <path
                d="M67.918 190.235L102.543 192.788V199.49H67.918V190.235Z"
                fill="#1B2536"
              />
              <path
                d="M113.077 194.543H62.0156V214.169H113.077V194.543Z"
                fill="#BEDCE0"
              />
              <path
                d="M115.332 210.04H59.7598V235.982H115.332V210.04Z"
                fill="#1B2536"
              />
              <path
                d="M92.1934 228.509V210.04"
                stroke="#BEDCE0"
                stroke-width="0.437778"
                stroke-miterlimit="10"
              />
              <path
                d="M101.146 215.553C101.146 217.051 99.9322 218.266 98.4336 218.266C96.9356 218.266 95.7207 217.051 95.7207 215.553C95.7207 214.055 96.9349 212.84 98.4336 212.84C99.9316 212.84 101.146 214.054 101.146 215.553Z"
                stroke="#BEDCE0"
                stroke-width="0.437778"
                stroke-miterlimit="10"
              />
              <path
                d="M101.146 225.251C101.146 226.749 99.9322 227.964 98.4336 227.964C96.9356 227.964 95.7207 226.75 95.7207 225.251C95.7207 223.753 96.9349 222.539 98.4336 222.539C99.9316 222.539 101.146 223.753 101.146 225.251Z"
                stroke="#BEDCE0"
                stroke-width="0.437778"
                stroke-miterlimit="10"
              />
            </svg>
          ) : (
            <svg
              width="450"
              height="540"
              viewBox="0 0 450 540"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M437.396 459.804C439.618 455.898 441.435 451.684 442.745 447.181C444.054 442.678 444.781 438.146 445 433.659L432.642 431.949C432.744 427.783 432.269 423.681 431.284 419.732L442.986 415.366C440.543 406.503 436.037 398.315 429.858 391.505L419.897 399.035C417.089 396.087 413.876 393.488 410.302 391.342L415.48 380.001C411.571 377.781 407.351 375.966 402.844 374.658C398.337 373.349 393.801 372.623 389.31 372.404L387.598 384.752C383.43 384.651 379.323 385.125 375.37 386.109L371 374.418C362.13 376.858 353.934 381.36 347.118 387.533L354.655 397.486C351.704 400.291 349.102 403.501 346.955 407.072L335.604 401.899C333.382 405.805 331.565 410.021 330.255 414.522C328.946 419.025 328.219 423.557 328 428.044L340.358 429.754C340.256 433.919 340.731 438.022 341.718 441.971L330.016 446.337C332.458 455.2 336.964 463.388 343.144 470.198L353.104 462.668C355.913 465.616 359.125 468.215 362.699 470.361L357.521 481.701C361.431 483.921 365.649 485.737 370.156 487.045C374.663 488.354 379.199 489.08 383.69 489.299L385.402 476.951C389.571 477.053 393.678 476.578 397.63 475.593L402 487.285C410.87 484.844 419.066 480.343 425.882 474.168L418.345 464.217C421.296 461.411 423.898 458.202 426.045 454.631L437.396 459.804ZM378.253 459.209C362.578 454.659 353.562 438.273 358.118 422.611C362.673 406.949 379.073 397.942 394.75 402.494C410.426 407.045 419.44 423.43 414.885 439.092C410.329 454.754 393.928 463.76 378.253 459.209ZM380.419 451.763C368.859 448.407 362.211 436.324 365.571 424.774C368.93 413.225 381.024 406.583 392.584 409.94C404.144 413.296 410.792 425.379 407.432 436.929C404.072 448.478 391.978 455.119 380.419 451.763Z"
                fill="#E4ECF9"
              />

              <path
                d="M450 339.464C449.805 337.278 449.358 335.08 448.621 332.906C447.885 330.733 446.901 328.711 445.723 326.846L440.185 329.583C439.05 327.88 437.696 326.362 436.178 325.049L439.73 320.051C436.227 317.165 432.071 315.122 427.619 314.096L425.671 319.885C423.69 319.477 421.641 319.323 419.572 319.451L418.486 313.457C416.26 313.649 414.021 314.087 411.808 314.811C409.594 315.534 407.533 316.499 405.635 317.657L408.423 323.095C406.689 324.209 405.143 325.538 403.805 327.029L398.715 323.541C395.776 326.981 393.696 331.061 392.651 335.433L398.546 337.345C398.131 339.29 397.974 341.303 398.104 343.334L392 344.4C392.195 346.586 392.642 348.785 393.379 350.957C394.115 353.13 395.099 355.154 396.277 357.018L401.815 354.28C402.95 355.983 404.304 357.501 405.822 358.814L402.269 363.813C405.772 366.698 409.928 368.741 414.38 369.767L416.327 363.979C418.308 364.386 420.358 364.54 422.425 364.412L423.511 370.406C425.737 370.214 427.976 369.776 430.188 369.052C432.402 368.329 434.461 367.364 436.361 366.207L433.573 360.768C435.307 359.654 436.853 358.325 438.191 356.834L443.281 360.322C446.22 356.882 448.301 352.802 449.345 348.43L443.45 346.518C443.865 344.573 444.022 342.561 443.892 340.531L450 339.464ZM424.904 353.447C418.427 355.563 411.428 352.123 409.273 345.762C407.118 339.401 410.621 332.53 417.1 330.414C423.577 328.297 430.576 331.737 432.731 338.099C434.886 344.459 431.382 351.331 424.904 353.447Z"
                fill="#E4ECF9"
              />
              <path
                d="M146 297.66C145.704 294.285 145.026 290.891 143.908 287.536C142.79 284.181 141.299 281.057 139.512 278.178L131.108 282.405C129.387 279.777 127.333 277.433 125.029 275.405L130.42 267.688C125.104 263.233 118.798 260.079 112.043 258.495L109.09 267.431C106.084 266.803 102.976 266.564 99.8364 266.762L98.1888 257.508C94.8103 257.803 91.4138 258.481 88.0558 259.598C84.6978 260.714 81.5715 262.205 78.6897 263.99L82.9208 272.386C80.29 274.106 77.9446 276.158 75.9142 278.46L68.1906 273.074C63.7316 278.385 60.5745 284.686 58.9896 291.434L67.9332 294.385C67.3046 297.388 67.0653 300.494 67.2624 303.63L58 305.277C58.2957 308.652 58.9742 312.045 60.0919 315.401C61.2095 318.756 62.701 321.879 64.4882 324.758L72.8915 320.531C74.6134 323.159 76.6669 325.503 78.9726 327.531L73.5816 335.248C78.8971 339.703 85.2035 342.859 91.9579 344.441L94.9114 335.505C97.9187 336.133 101.027 336.372 104.165 336.174L105.813 345.428C109.19 345.133 112.587 344.455 115.945 343.338C119.304 342.222 122.43 340.732 125.312 338.946L121.08 330.55C123.711 328.83 126.057 326.778 128.087 324.476L135.811 329.862C140.27 324.551 143.427 318.25 145.012 311.502L136.068 308.551C136.697 305.548 136.936 302.442 136.738 299.306L146 297.66ZM107.921 319.249C98.0915 322.517 87.4733 317.205 84.2023 307.384C80.9314 297.564 86.2481 286.955 96.0777 283.687C105.907 280.419 116.525 285.731 119.796 295.552C123.067 305.371 117.751 315.981 107.921 319.249Z"
                fill="#E4ECF9"
              />
              <path
                d="M61.71 367.754C62.9632 365.551 63.9878 363.173 64.7275 360.633C65.466 358.093 65.8761 355.537 66 353.006L59.0284 352.042C59.0858 349.692 58.8176 347.378 58.2618 345.151L64.863 342.687C63.4845 337.688 60.9434 333.07 57.457 329.228L51.8382 333.476C50.2541 331.813 48.4425 330.346 46.4265 329.136L49.347 322.739C47.1419 321.487 44.7619 320.463 42.2195 319.724C39.6772 318.986 37.1182 318.577 34.5848 318.453L33.619 325.418C31.267 325.361 28.9508 325.629 26.7214 326.184L24.257 319.589C19.2528 320.966 14.6306 323.505 10.7852 326.988L15.0369 332.601C13.3722 334.184 11.9043 335.994 10.6932 338.01L4.29005 335.092C3.03676 337.295 2.01216 339.673 1.27245 342.213C0.534023 344.753 0.123923 347.309 0 349.84L6.97037 350.805C6.91288 353.155 7.18116 355.469 7.7369 357.696L1.13576 360.159C2.51425 365.158 5.05531 369.777 8.54177 373.618L14.1605 369.37C15.7447 371.033 17.5562 372.5 19.5735 373.71L16.653 380.107C18.8581 381.359 21.2382 382.383 23.7805 383.122C26.3228 383.86 28.8818 384.27 31.4152 384.393L32.381 377.428C34.733 377.486 37.0492 377.217 39.2786 376.662L41.7443 383.257C46.7484 381.88 51.3707 379.341 55.2161 375.858L50.9644 370.245C52.6291 368.662 54.097 366.852 55.3081 364.838L61.71 367.754ZM28.3478 367.419C19.5058 364.852 14.4198 355.608 16.989 346.774C19.5582 337.941 28.8102 332.859 37.6522 335.426C46.4942 337.993 51.5802 347.236 49.0097 356.07C46.4418 364.905 37.191 369.986 28.3478 367.419ZM29.5704 363.219C23.0497 361.326 19.3001 354.511 21.1947 347.996C23.0893 341.481 29.9115 337.735 36.4322 339.628C42.9528 341.521 46.7025 348.337 44.8078 354.851C42.912 361.365 36.0911 365.112 29.5704 363.219Z"
                fill="#E4ECF9"
              />
              <path
                d="M164.621 393.744C164.621 393.744 181.388 351.865 232.78 321.64C232.78 321.64 245.9 287.485 264.684 275.597C270.972 271.618 278.671 278.185 275.944 285.104L257.2 332.662H312.246L164.621 393.744Z"
                fill="#365896"
              />
              <path
                className="pulse-path-lamp"
                d="M311.147 139.561C306.944 109.183 280.53 70.1277 229.501 70.1277C178.472 70.1277 152.058 109.183 147.855 139.561C143.725 169.421 151.5 186.66 166.323 210.937C182.538 237.495 188.677 274.059 188.677 274.059H229.501H270.323C270.323 274.059 276.463 237.495 292.678 210.937C307.502 186.658 315.277 169.421 311.147 139.561Z"
                fill="#EBBC3B"
              />
              <path
                d="M229.501 272.491C211.581 272.491 195.745 271.64 186.492 270.348L194.646 326.487C194.891 328.181 210.496 329.537 229.501 329.537C248.507 329.537 264.112 328.181 264.357 326.487L272.511 270.348C263.258 271.641 247.422 272.491 229.501 272.491Z"
                fill="#1B2536"
              />
              <path
                d="M229.502 339.179C241.778 339.179 251.73 332.086 251.73 323.338C251.73 314.589 241.778 307.497 229.502 307.497C217.225 307.497 207.273 314.589 207.273 323.338C207.273 332.086 217.225 339.179 229.502 339.179Z"
                fill="#1B2536"
              />
              <path
                d="M251.213 203.41L255.022 177.312H254.986C255.158 176.301 255.322 175.263 255.477 174.202C258.305 154.832 257.024 135.737 252.004 135.737C248.888 135.737 245.543 143.517 243.213 154.352C242.385 143.517 240.118 135.737 237.002 135.737C233.888 135.737 231.081 143.517 229.501 154.352C227.923 143.517 225.116 135.737 222.001 135.737C218.886 135.737 216.619 143.517 215.79 154.352C213.46 143.517 210.115 135.737 206.999 135.737C201.979 135.737 200.698 154.834 203.526 174.202C203.681 175.263 203.845 176.301 204.017 177.312L207.79 203.41"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M191.603 52.4385L176.191 10.1306"
                stroke="#D4780E"
                stroke-width="3"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M158.279 71.6616L129.312 37.1731"
                stroke="#D4780E"
                stroke-width="3"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M133.541 101.116L94.5156 78.6035"
                stroke="#D4780E"
                stroke-width="3"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M120.38 137.244L76 129.424"
                stroke="#D4780E"
                stroke-width="3"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M229.5 0.739136V45.7624"
                stroke="#D4780E"
                stroke-width="3"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M282.81 10.1306L267.398 52.4385"
                stroke="#D4780E"
                stroke-width="3"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M329.689 37.1731L300.723 71.6616"
                stroke="#D4780E"
                stroke-width="3"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M364.486 78.6035L325.461 101.116"
                stroke="#D4780E"
                stroke-width="3"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M382.999 129.424L338.621 137.244"
                stroke="#D4780E"
                stroke-width="3"
                stroke-miterlimit="10"
                className="pulse-path-lights"
              />
              <path
                d="M234.238 456.016V412.681L330.227 354.782C344.044 346.449 353.588 332.564 356.415 316.688L365.949 263.138C366.563 259.695 364.139 256.416 360.657 256.068C355.022 255.505 346.671 257.065 340.304 268.472L341.178 240.21C341.293 236.5 338.067 233.565 334.381 234.026L332.343 234.28C320.863 235.714 312.246 245.466 312.246 257.026V304.525L302.154 258.239C301.405 254.805 297.391 253.251 294.522 255.284L291.031 257.758C282.734 263.636 278.694 273.856 280.734 283.813L289.64 327.288L264.406 286.852C262.99 284.584 259.792 284.31 258.011 286.305C252.879 292.054 251.685 300.312 254.977 307.277L268.135 335.116L248.818 352.96C248.818 352.96 155.145 360.608 155.145 415.232C155.145 438.903 155.145 456.019 155.145 456.019H234.238V456.016Z"
                fill="#4A75C9"
              />
              <path
                d="M155.145 434.894L234.238 440.721V456.016H155.145V434.894Z"
                fill="#1B2536"
              />
              <path
                d="M258.293 444.726H141.656V489.518H258.293V444.726Z"
                fill="#BEDCE0"
              />
              <path
                d="M263.449 480.093H136.508V539.298H263.449V480.093Z"
                fill="#1B2536"
              />
              <path
                d="M210.598 522.244V480.093"
                stroke="#BEDCE0"
                stroke-miterlimit="10"
              />
              <path
                d="M231.046 492.674C231.046 496.093 228.273 498.866 224.849 498.866C221.427 498.866 218.652 496.094 218.652 492.674C218.652 489.256 221.426 486.483 224.849 486.483C228.271 486.483 231.046 489.254 231.046 492.674Z"
                stroke="#BEDCE0"
                stroke-miterlimit="10"
              />
              <path
                d="M231.046 514.809C231.046 518.228 228.273 521 224.849 521C221.427 521 218.652 518.229 218.652 514.809C218.652 511.39 221.426 508.618 224.849 508.618C228.271 508.618 231.046 511.389 231.046 514.809Z"
                stroke="#BEDCE0"
                stroke-miterlimit="10"
              />
            </svg>
          )}
        </div>
      </StyledImageContainer>
      <StyledContentContainer>
        <StyledHeading
          as="h1"
          fontWeight="600"
          fontSize="34px"
          lineheight="56px"
          color={`${theme.white}`}
        >
          {data?.name}
        </StyledHeading>
        <CountdownTimer
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
          className="countdown-timer"
        />

        <StyledLocation>
          <LocationIcon src="./images/countdownEvent/locationLogo.svg" />
          <LocationTitle
            as="h2"
            fontWeight="300"
            fontSize="16px"
            lineheight="32px"
            color={`${theme.white}`}
          >
            {data?.location}
          </LocationTitle>
        </StyledLocation>
        <br />
        {data?.button ? (
          <a href={data?.button?.link} target="_blank">
            <Button
              type="button"
              bgColor={theme.darkBlue}
              hoverBgColor={theme.lightDarkBlue}
              color={theme.white}
              content={data?.button?.name}
              className="btn-alumni"
            />
          </a>
        ) : (
          ""
        )}
      </StyledContentContainer>
    </StyledContainer>
  );
};
