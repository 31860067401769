import React from "react";
import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";
import Button from "../Button/Button";

const StyledContainer = styled.section`
  background-image: url("./images/careerCenterPage/background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 10%;
  margin-top: 45%;

  @media (min-width: ${theme.smallDesktop}) {
    margin-top: 0px;
    padding-bottom: 0.5%;
  }
`;
const StyledInnerContainer = styled.div`
  text-align: center;
  margin: 5%;
  position: relative;
  @media (min-width: ${theme.smallDesktop}) {
    display: flex;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 1% 10% 3%;
    div.sticker {
      position: absolute;
      right: -12%;
      top: -11%;
    }
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    div.sticker {
      position: absolute;
      right: -10%;
      top: -10%;
    }
  }
`;

const StyledSticker = styled.img`
  display: none;

  @media (min-width: ${theme.mediumDesktop}) {
    display: block;
    width: 75%;
  }
  @media (min-width: ${theme.largeDesktop}) {
    width: 90%;
  }
`;

const StyledDesktopImageDiv = styled.div`
  display: none;
  position: relative;
  width: 100%;
  margin-right: 15%;

  @media (min-width: ${theme.smallDesktop}) {
    display: block;
    width: 75%;
  }
`;
const StyledDesktopImage = styled.img`
  object-fit: cover;
  width: 100%;
`;

const StyledImageDiv = styled.div`
  width: 40%;
  margin-bottom: 5%;
  margin-top: -30%;
  margin-left: 30%;
  @media (min-width: ${theme.smallDesktop}) {
    display: none;
  }
`;
const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  margin-top: -30%;
  @media (min-width: ${theme.standardMobile}) {
    margin-top: -45%;
  }
  @media (min-width: ${theme.mediumMobile}) {
    margin-top: -50%;
  }
  @media (min-width: ${theme.tablet}) {
    width: 75%;
    margin-top: -55%;
  }
`;
const StyledContentDiv = styled.div`
  width: 100%;
  button.btn-about-us {
    display: block;
    justify-content: left;
    width: 100%;
    padding: 16px 0;
  }
  @media (min-width: ${theme.tablet}) {
    button.btn-about-us {
      width: auto;
      padding: 20px 80px;
    }
  }
  @media (min-width: ${theme.smallDesktop}) {
    align-self: center;
  }
`;

const StyledTitle = styled(StyledElement)`
  text-align: center;
  margin-bottom: 38px;
  @media (min-width: ${theme.smallDesktop}) {
    text-align: left;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 40px;
    line-height: 48px;
  }
`;
const StyledParagraph = styled(StyledElement)`
  text-align: left;
  margin-bottom: 30px;
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 38px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 22px;
  }
`;

const CareerCenterProsvetilka = ({ data }) => {

  return (
    <StyledContainer>
      <StyledInnerContainer>
        <StyledDesktopImageDiv>
          <StyledDesktopImage src="./images/careerCenterPage/careerCenterProsvetilka.svg" />
        </StyledDesktopImageDiv>
        <StyledContentDiv>
          <StyledImageDiv>
            <StyledImage src="./images/careerCenterPage/careerCenterProsvetilka.svg" />
          </StyledImageDiv>
          <StyledTitle
            as="h1"
            fontFamily="'Exo 2', sans-serif"
            fontWeight="800"
            fontSize="32px"
            lineheight="48px"
            color={`${theme.white}`}
          >
            {data?.name}
          </StyledTitle>

          <StyledParagraph
            as="p"
            fontWeight="400"
            fontSize="16px"
            lineheight="24px"
            color={`${theme.white}`}
          >
            {data?.description}
          </StyledParagraph>
          <a href={data?.button_link} target="_blank">
            <Button
              type="button"
              bgColor={theme.green}
              hoverBgColor={theme.darkGreen}
              color={theme.white}
              content="Види повеќе"
              className="btn-about-us"
            />
          </a>
        </StyledContentDiv>
        <div className="sticker">
          <StyledSticker src="./images/careerCenterPage/sticker.svg" />
        </div>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default CareerCenterProsvetilka;
