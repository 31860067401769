import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";
import Button from "../Button/Button";
import StaffMember from "../StaffMember/StaffMember";
import { CiCircleAlert } from "react-icons/ci";

const StyledContainer = styled.div`
  text-align: center;
  margin: 7% 5%;

  .missing-value {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
    span {
      margin-left: 5px;
    }
  }
  @media (min-width: ${theme.tablet}) {
    margin: 5%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 5% 10%;
  }
`;

const StyledHeading = styled(StyledElement)`
  margin-bottom: 38px;
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 36px;
    line-height: 46px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 40px;
    line-height: 48px;
  }
`;

const ButtonContainer = styled.div`
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
  padding: 30px 15px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
  position: relative;
  z-index: 11;

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  button {
    padding: 15px 25px;
    font-size: 18px;
    font-weight: 600;
    margin-right: 15px;
  }
  @media (min-width: ${theme.standardDesktop}) {
    overflow: unset;
    white-space: normal;
    margin: 60px auto 0;
    text-align: center;

    button {
      margin-bottom: 30px;
    }
  }
`;
const MemberContainer = styled.div``;
const SubjectStaff = ({ data }) => {
  const [activeButton, setActiveButton] = useState(0);
  const [staffPerSubject, setStaffPerSubject] = useState([]);

  useEffect(() => {
    const teachersFromFirstSubject = data?.[0]?.staff;
    setStaffPerSubject(teachersFromFirstSubject);
  }, []);

  // on click on filter buttons
  const onFilterActivities = (staff, index) => {
    setActiveButton(index);
    const teachers = staff;
    setStaffPerSubject(teachers);
  };

  return (
    <StyledContainer>
      <StyledHeading
        as="h1"
        fontFamily="'Exo 2', sans-serif"
        fontWeight="800"
        fontSize="32px"
        lineheight="48px"
      >
        Образовен кадар
      </StyledHeading>

      <ButtonContainer>
        {data?.length > 0
          ? data?.map((subject, index) => (
              <Button
                key={subject.id}
                content={subject.name}
                bgColor={activeButton === index && theme.darkBlue}
                color={activeButton === index ? theme.white : theme.darkBlue}
                border="2px solid #023859"
                onClick={() => onFilterActivities(subject?.staff, index)}
              />
            ))
          : ""}
      </ButtonContainer>

      {staffPerSubject?.length > 0 ? (
        <div>
          {staffPerSubject?.map((member, index) => (
            <MemberContainer key={index}>
              <StaffMember name={member.name} email={member.email} />
            </MemberContainer>
          ))}
        </div>
      ) : (
        <p className="missing-value">
          <CiCircleAlert />{" "}
          <span>Не се пронајдени наставници за овој предмет!</span>
        </p>
      )}
    </StyledContainer>
  );
};

export default SubjectStaff;
