import React from "react";
import styled from "styled-components";
import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";

const StyledContainer = styled.section`
  background-image: url("./images/contentAboutUs/background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 68px;
  margin-top: 64px;

  @media (min-width: ${theme.mediumMobile}) {
    padding-top: 72px;
  }
  @media (min-width: ${theme.tablet}) {
    margin-top: 0px;
    padding-top: 122px;
    background-image: url("./images/contentAboutUs/background-desktop.svg");
  }
  @media (min-width: ${theme.mediumDesktop}) {
    padding-top: 94px;
  }
`;

const StyledInnerContainer = styled.div`
  margin: 0% 5%;
  padding: 4% 0%;

  @media (min-width: ${theme.smallDesktop}) {
    display: flex;
    flex-direction: row-reverse;
    margin: 0% 10%;
    gap: 68px;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    gap: 128px;
  }
`;

const StyledTitle = styled(StyledElement)`
  padding-bottom: 24px;

  @media (min-width: ${theme.smallDesktop}) {
    display: none;
  }
`;
const StyledHeading = styled(StyledElement)`
  padding-bottom: 24px;

  @media (min-width: ${theme.smallDesktop}) {
    display: none;
  }
`;
const StyledImageDiv = styled.div`
  text-align: center;
  @media (min-width: ${theme.smallDesktop}) {
    width: 47%;
    align-self: center;
  }
`;
const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 15px;
  box-shadow: 18px 14px 50px 4px rgba(9, 30, 57, 0.1);
  @media (min-width: ${theme.tablet}) {
    width: 85%;
  }
  @media (min-width: ${theme.smallDesktop}) {
    width: 100%;
  }
`;
const StyledContentContainer = styled(StyledElement)`
  text-align: center;
  @media (min-width: ${theme.smallDesktop}) {
    width: 53%;
    align-self: center;
    text-align: left;
  }
`;

const StyledTitleDesktop = styled(StyledElement)`
  display: none;

  @media (min-width: ${theme.smallDesktop}) {
    padding-bottom: 10px;
    display: block;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    padding-bottom: 24px;
    font-size: 46px;
  }
`;
const StyledHeadingDesktop = styled(StyledElement)`
  display: none;
  @media (min-width: ${theme.smallDesktop}) {
    padding-bottom: 18px;
    display: block;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    padding-bottom: 38px;
    font-size: 40px;
    line-height: 48px;
  }
`;

const StyledParagraph = styled(StyledElement)`
  padding: 24px 0px;
  text-align: left ;

  @media (min-width: ${theme.smallDesktop}) {
    padding: 0px;
    font-size: 16px;
    line-height: 22px;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 18px;
    line-height: 25px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 22px;
    line-height: 32px;
  }
`;
const AboutKorchagin = () => {
  return (
    <StyledContainer>
      <StyledInnerContainer>
        <StyledTitle
          as="h1"
          fontFamily="'Exo 2', sans-serif"
          fontWeight="800"
          fontSize="32px"
          lineheight="56px"
        >
          Раде Јовчевски – Корчагин (1919-1943)
        </StyledTitle>
        <StyledHeading
          as="h2"
          fontFamily="'Exo 2', sans-serif"
          fontWeight="800"
          fontSize="28px"
        >
          Народен херој
        </StyledHeading>
        <StyledImageDiv>
          <StyledImage src="./images/contentAboutUs/korcagin.svg" />
        </StyledImageDiv>
        <StyledContentContainer as="div">
          <StyledTitleDesktop
            as="h1"
            fontFamily="'Exo 2', sans-serif"
            fontWeight="800"
            fontSize="34px"
            lineheight="56px"
          >
            Раде Јовчевски – Корчагин (1919-1943)
          </StyledTitleDesktop>
          <StyledHeadingDesktop
            as="h2"
            fontFamily="'Exo 2', sans-serif"
            fontWeight="800"
            fontSize="28px"
            lineheight="34px"
          >
            Народен херој
          </StyledHeadingDesktop>
          <StyledParagraph
            as="div"
            fontWeight="400"
            fontSize="16px"
            lineheight="24px"
          >
            Раде Јовчевски бил роден на 14 август 1919 година во Скопје во
            скромно занаетчиско семејство. Неговиот татко Коста бил познат
            скопски чевлар. Своето основно образование Раде го започнал во
            месното скопско училиште и бил солиден ученик. Иако Раде сакал да го
            продолжи своето образование, сепак поради сиромаштијата тоа не му
            било овозможено и морал да почен да изучува занает. Раде Јовчевски
            се помирил со тоа и решил да почне да изучува некој занает кој ќе
            биде поплатен и постабилен од тој на татко му. Така, во 1933 година
            почнал да го посетува училиштето за трговска младина како кожарски
            работник. Корчагин работел и изучувал занает цели три години, но
            откако завршил со обуката тој не нашол работа во Скопје. Поради тоа,
            Раде Корчагин заминал за Белград. Таму нашол работа по занает и се
            зачленил во кожарскиот и крзнениот синдикат. Тогаш се стекнал и со
            прекарот „Корчагин“, по јунакот во романот „Како се калеше челикот“
            од Николај Островски Бидејќи се покажал како вреден и одлучен човек,
            Раде Корчагин бил назначен за секретар на синдикатот во 1938 година,
            а веќе во 1939 година станал и член на Комунистичката партија на
            Југославија.
          </StyledParagraph>
        </StyledContentContainer>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default AboutKorchagin;
