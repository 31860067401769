import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

// import required modules
import { Grid, Pagination } from "swiper/modules";

import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";
import GalleryImage from "./GalleryImage";
import Button from "../Button/Button";
import useMediaQuery from "../../Hooks/useMediaQuery";

const StyledContainer = styled.div`
  text-align: center;
  margin: 7% 5%;
  @media (min-width: ${theme.tablet}) {
    margin: 5%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 15% 10% 5%;
  }

  button.btn-about-us {
    width: 100%;
    padding: 16px 0;
    margin-top: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  button.btn-about-us img {
    margin-left: 10px;
  }
  @media (min-width: ${theme.tablet}) {
    button.btn-about-us {
      width: auto;
      padding: 20px 80px;
    }
  }
`;

const StyledGallery = styled.div`
  .swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 8% 0 12%;

    @media (min-width: ${theme.tablet}) {
      padding: 5% 0 8%;
    }
    @media (min-width: ${theme.smallDesktop}) {
      padding: 3% 0 5%;
    }
    @media (min-width: ${theme.mediumDesktop}) {
      padding: 4% 0;
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledHeading = styled(StyledElement)`
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 46px;
    line-height: 56px;
  }
`;

const images = [
  {
    src: "./images/careerCenterPage/gallery/img1.svg",
  },
  {
    src: "./images/careerCenterPage/gallery/img2.svg",
  },
  {
    src: "./images/careerCenterPage/gallery/img3.svg",
  },
  {
    src: "./images/careerCenterPage/gallery/img4.svg",
  },
  {
    src: "./images/careerCenterPage/gallery/img5.svg",
  },
  {
    src: "./images/careerCenterPage/gallery/img6.svg",
  },
  {
    src: "./images/careerCenterPage/gallery/img1.svg",
  },
  {
    src: "./images/careerCenterPage/gallery/img2.svg",
  },
];

const CareerCenterGallery = () => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  return (
    <StyledContainer>
      <StyledHeading
        as="h1"
        fontFamily="'Exo 2', sans-serif"
        fontWeight="800"
        fontSize="32px"
        lineheight="48px"
      >
        ГАЛЕРИЈА{" "}
      </StyledHeading>
      <StyledGallery>
        <Swiper
          slidesPerView={1}
          grid={{
            rows: isDesktop ? 2 : 0,
            fill: "row",
          }}
          spaceBetween={35}
          pagination={{
            clickable: true,
          }}
          modules={[Grid, Pagination]}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1440: {
              slidesPerView: 3,
            },
          }}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <GalleryImage src={image.src} />
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledGallery>
      <div className="btn-container">
        <a href="https://www.instagram.com/rjkorchagin/" target="_blank">
          <Button
            type="button"
            bgColor={theme.green}
            hoverBgColor={theme.darkGreen}
            color={theme.white}
            content="Заследи нè"
            className="btn-about-us"
          >
            <img src="./images/socialIcons/instagramLogoWhite.svg" />
          </Button>
        </a>
      </div>
    </StyledContainer>
  );
};

export default CareerCenterGallery;
