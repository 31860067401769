import React, { useState } from "react";
import styled from "styled-components";

import theme from "../../../Consts/Theme";
import SideMenu from "./SideMenu";
import { Link } from "react-router-dom";

const StyledContainer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  position: relative;

  img {
    width: 50px;
    display: block;
  }
`;
const BarIcon = styled.div`
  width: 40px;
  position: relative;
  display: flex;
  align-items: center;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: ${theme.black};
    border-radius: 15px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 10px;
  }

  span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  /* span:nth-child(2) {
    -webkit-transform: ${(props) => (props.isOpen ? "rotate(45)" : "")};
    -moz-transform: ${(props) => (props.isOpen ? "rotate(45)" : "")};
    -o-transform: ${(props) => (props.isOpen ? "rotate(45)" : "")};
    transform: ${(props) => (props.isOpen ? "rotate(45)" : "")};
  }

  span:nth-child(3) {
    -webkit-transform: ${(props) => (props.isOpen ? "rotate(-45)" : "")};
    -moz-transform: ${(props) => (props.isOpen ? "rotate(-45)" : "")};
    -o-transform: ${(props) => (props.isOpen ? "rotate(-45)" : "")};
    transform: ${(props) => (props.isOpen ? "rotate(-45)" : "")};
  } */
`;
const MenuMobile = () => {
  const [isOpen, setIsOpen] = useState(false);

  const closeSideMenu = () => setIsOpen(false)
  return (
    <StyledContainer>
      <Link to="/"><img src="/images/logo.svg" alt="Logo" /></Link>
      <BarIcon onClick={() => setIsOpen(true)} isOpen={isOpen}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <path
            d="M5 10H25M5 20H25"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </BarIcon>
      <SideMenu isOpen={isOpen} closeSideMenu={closeSideMenu}/>
    </StyledContainer>
  );
};

export default MenuMobile;
