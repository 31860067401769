import React, { useEffect, useState } from "react";
import styled from "styled-components";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper/modules";

import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";
import Event from "./Event";
import useMediaQuery from "../../Hooks/useMediaQuery";
import ActivityCard from "../ActivityCard/ActivityCard";
import { useNavigate } from "react-router-dom";
import { makeAppRoute } from "../../Api";

const StyledContainer = styled.div`
  text-align: center;
  position: relative;
`;
const StyledHeading = styled(StyledElement)`
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 46px;
    line-height: 56px;
  }
`;

const StyledCardsContainer = styled.div`
  display: flex;
  width: 1005;
  position: relative;

  .swiper {
    width: 100%;
    height: 100%;
    background-color: transparent;

    padding: 9% 0% 6% 5%;
    @media (min-width: ${theme.tablet}) {
      padding: 5% 0% 4% 5%;
    }
    @media (min-width: ${theme.standardDesktop}) {
      padding: 4% 3% 3%;
    }
    @media (min-width: ${theme.mediumDesktop}) {
      padding: 3% 1% 2% 4%;
    }
    @media (min-width: ${theme.extraLargeDesktop}) {
      padding: 2.5% 0% 2% 4%;
    }
  }

  .swiper-wrapper {
    padding-bottom: 30px;
  }

  .swiper-slide {
    width: 80%;
  }

  .swiper-pagination {
    z-index: 0;
  }

  @media (min-width: ${theme.standardDesktop}) {
    margin-left: 2%;
    margin-right: 2%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin-left: 6.5%;
    margin-right: 7.5%;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    margin-left: 6.5%;
    margin-right: 8.5%;
  }
`;

const EventBoard = ({ data, hash }) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const navigate = useNavigate()

  useEffect(() => {
    // Get the element with id "eventsBoard"
    const eventsBoardSection = document.getElementById("eventsBoard");

    if (hash === "#eventsBoard") {
      // Scroll to the section
      eventsBoardSection.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  const navigateToCardPage = (card_id) => {
    navigate(makeAppRoute(`eventPage`, { EVENT_ID: card_id }));
  };

  return (
    <>
      <StyledContainer id="eventsBoard">
        <StyledHeading
          as="h1"
          fontFamily="'Exo 2', sans-serif"
          fontWeight="800"
          fontSize="32px"
          lineHeight="32px"
        >
          Настани
        </StyledHeading>
        <StyledCardsContainer>
          <Swiper
            slidesPerView={!isDesktop ? "auto" : 3}
            spaceBetween={35}
            modules={[Pagination]}
            pagination={{
              clickable: true,
            }}
          >
            {data?.map((card, index) => (
              <SwiperSlide key={index}>
                <ActivityCard
                  image={card.image}
                  title={card.name}
                  description={card.description}
                  date={card.created_at}
                  onClick={() => navigateToCardPage(card?.id)}
                />  
              </SwiperSlide>
            ))}
          </Swiper>
        </StyledCardsContainer>
      </StyledContainer>
    </>
  );
};

export default EventBoard;
