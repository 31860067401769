import React from "react";
import StyledElement from "../../Consts/Text";
import styled from "styled-components";
import theme from "../../Consts/Theme";

const StyledContainer = styled.div`
  margin: 0px 5%;
  text-align: center;

  @media (min-width: ${theme.smallDesktop}) {
    margin: 0% 9% 3%;
  }
`;

const StyledHeading = styled(StyledElement)`
  padding-bottom: 24px;

  @media (min-width: ${theme.smallDesktop}) {
    font-size: 46px;
    line-height: 56px;
    padding-bottom: 48px;
  }
`;

const StyledInnerContainer = styled(StyledElement)`
  position: relative;
  @media (min-width: ${theme.smallDesktop}) {
    display: flex;
    gap: 32px;

    div.sticker {
      display: block;
      position: absolute;
      left: -3%;
      top: -5%;
      z-index: 0;
    }

    div.sticker2 {
      display: block;
      position: absolute;
      bottom: -10%;
      right: -3%;
      z-index: 0;
    }
  }
`;

const StyledSticker = styled.img`
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledDiv = styled(StyledElement)`
  text-align: center;
  border-radius: 15px;
  border: 2px solid #00cd42;
  padding: 26px;
  margin-top: 24px;
  width: 100%;

  @media (min-width: ${theme.smallDesktop}) {
    padding: 48px 56px;
    width: 48%;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    padding: 80px 108px;
  }
`;
const StyledGoalsHeading = styled(StyledElement)`
  padding-bottom: 32px;

  @media (min-width: ${theme.smallDesktop}) {
    font-size: 36px;
  }
`;
const StyledParagraph = styled(StyledElement)`
  @media (min-width: ${theme.smallDesktop}) {
    font-size: 22px;
    line-height: 32px;
  }
`;
const OurGoals = ({ data }) => {
  return (
    <StyledContainer>
      <StyledHeading
        as="h2"
        fontFamily="'Exo 2', sans-serif"
        fontWeight="800"
        fontSize="32px"
      >
        Нашите цели
      </StyledHeading>

      <StyledInnerContainer as="div">
        <div className="sticker">
          <StyledSticker src="./images/contentAboutUs/sticker.svg" />
        </div>
        <div className="sticker2">
          <StyledSticker src="./images/contentAboutUs/sticker.svg" />
        </div>
        <StyledDiv as="div" className="main">
          <StyledGoalsHeading
            as="h2"
            fontFamily="'Exo 2', sans-serif"
            fontWeight="700"
            fontSize="32px"
            lineheight="48px"
          >
            {data?.about_us_4?.name}
          </StyledGoalsHeading>
          <StyledParagraph
            as="p"
            fontWeight="400"
            fontSize="16px"
            lineheight="24px"
          >
            {data?.about_us_4?.description}
          </StyledParagraph>
        </StyledDiv>

        <StyledDiv as="div" className="main">
          <StyledGoalsHeading
            as="h2"
            fontFamily="'Exo 2', sans-serif"
            fontWeight="700"
            fontSize="32px"
            lineheight="48px"
          >
            {data?.about_us_5?.name}
          </StyledGoalsHeading>
          <StyledParagraph
            as="p"
            fontWeight="400"
            fontSize="16px"
            lineheight="24px"
          >
            {data?.about_us_5?.description}
          </StyledParagraph>
        </StyledDiv>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default OurGoals;
