import React from "react";
import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";

const StyledContainer = styled(StyledElement)`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "transparent"};
  color: ${(props) => props.color};
  border: ${(props) => props.border || "none"};
  border-radius: 15px;
  font-weight: ${(props) => props.fontWeight || "700"};
  font-size: ${(props) => props.fontSize || "20px"};
  transition: all 0.2s ease;
  cursor: pointer;

  @media (min-width: ${theme.standardDesktop}) {
    &:hover {
      background-color: ${(props) => props.hoverBgColor || ""};
    }
  }
`;

const Button = (props) => {
  return (
    <StyledContainer as="button" {...props}>
      {props.content}
      {props.children}
    </StyledContainer>
  );
};

export default Button;
