import React from "react";
import styled from "styled-components";
import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";

const StyledContainer = styled(StyledElement)`
    background-image: linear-gradient(0deg, #000 -6.46%, rgba(0, 0, 0, 0) 100%),
    url(${props => props.imageUrl});
  background-size: cover;
  background-position-x: center;
  background-position-y: ${(props) => props.backgroundPosition || "50%"};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 44% 0% 46%;
  gap: 22px;

  @media (min-width: ${theme.largeMobile}) {
    padding: 20%;
  }
  @media (min-width: ${theme.smallDesktop}) {
    padding: 9% 0% 11%;
  }
`;

const StyledHeading = styled(StyledElement)`
  @media (min-width: ${theme.mediumMobile}) {
    font-size: 46px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 64px;
  }
`;
const StyledParagraph = styled(StyledElement)`
  @media (min-width: ${theme.mediumMobile}) {
    font-size: 20px;
  }
`;

const HeroBanner = ({ heading, paragraph, imageUrl, backgroundPosition }) => {

  return (
    <StyledContainer
      as="div"
      color={`${theme.white}`}
      imageUrl={imageUrl && imageUrl}
      backgroundPosition={backgroundPosition}
    >
      <StyledHeading
        as="div"
        fontFamily="'Exo 2', sans-serif"
        fontWeight="700"
        fontSize="36px"
      >
        {heading}
      </StyledHeading>
      <StyledParagraph
        as="div"
        fontWeight="400"
        fontSize="16px"
        lineHeight="32px"
      >
        {paragraph}
      </StyledParagraph>
    </StyledContainer>
  );
};

export default HeroBanner;
