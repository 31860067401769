import React, { useEffect, useState } from "react";
import HeroBanner from "../Components/HeroBanner/HeroBanner";
import CareerCenterGallery from "../Components/CareerCenterGallery/CareerCenterGallery";
import CareerCenterRJK from "../Components/CareerCenterRJK/CareerCenterRJK";
import CareerCenterCards from "../Components/CareerCenterCards/CareerCenterCards";
import Projects from "../Components/Projects/Projects";
import CareerCenterProsvetilka from "../Components/CareerCenterProsvetilka/CareerCenterProsvetilka";
import { Footer } from "../Components/Footer/Footer";
import { axios, makeApiRoute } from "../Api";
import PageLoader from "../Components/Loader/PageLoader";
import Header from "../Components/Header/Header";
import ScrollToTopPage from "../Components/ScrollToTop/ScrollToTop";

export const CareerCenter = () => {
  const [careerCenterData, setCareerCenterData] = useState([]);

  useEffect(() => {
    axios
      .get(makeApiRoute("careerCenter"))
      .then((response) => {
        setCareerCenterData(response.data);
      })
      .catch((error) => {});
  }, []);
  if (!careerCenterData) return <PageLoader />;

  return (
    <div>
      <ScrollToTopPage />
      <Header />
      <HeroBanner
        heading="Кариерен Центар"
        paragraph="Почетна / Кариерен Центар"
        imageUrl="/images/heroBanner/careerCenter.svg"
      />
      <CareerCenterRJK data={careerCenterData?.contents?.career_center_1} />
      <CareerCenterCards data={careerCenterData?.statistics} />
      <CareerCenterProsvetilka
        data={careerCenterData?.contents?.career_center_2}
      />
      {careerCenterData?.projects?.length > 0 && (
        <Projects data={careerCenterData?.projects} />
      )}
      <CareerCenterGallery />
      <Footer />
    </div>
  );
};
