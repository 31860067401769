import React from "react";
import styled from "styled-components";

import { BiPlus } from "react-icons/bi";
import theme from "../../Consts/Theme";
import Dropdown from "../Dropdown/Dropdown";
import StyledElement from "../../Consts/Text";

const StyledContainer = styled.section`
  margin-top: 30px;
  background-image: url("./images/classes/bg-darkBlue-mobile.svg");
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: ${theme.tablet}) {
    margin-top: 60px;
    margin-bottom: 140px;
  }
`;

const StyledInnerContainer = styled.div`
  margin: 0 16px;
  padding: 90px 0 40px;

  .dropdown-area {
    margin-bottom: 22px;
    font-size: 20px;
    line-height: 1.4;
  }

  .dropdown-area p {
    margin: 16px 0;
  }

  @media (min-width: ${theme.standardDesktop}) {
    padding: 200px 0 100px;
    width: 80%;
    margin: 0 auto;

    ul {
      display: flex;
      flex-wrap: wrap;
      width: 90%;
      margin: 0 auto;
    }
    li {
      flex-basis: 40%;
      list-style: disc;
    }
  }
`;

const StyledHeading = styled(StyledElement)`
  margin-bottom: 24px;
`;
const AreasAccordion = ({ data }) => {
  return (
    <StyledContainer>
      <StyledInnerContainer>
        <StyledHeading
          as="h2"
          fontFamily="'Exo 2', san-serif"
          fontSize="25px"
          fontWeight="700"
          lineheight="35px"
          className="filter-title"
          color={theme.white}
        >
          Изборни подрачја во 3та и 4та година
        </StyledHeading>
        {data &&
          data?.map((item) => (
            <div key={item.id} className="dropdown-area">
              <Dropdown
                name={item.name}
                color={theme.white}
                borderColor={theme.white}
                bgColor={theme.white}
              >
                <p>{item.description}</p>
                <ul>
                  {item?.subjects?.map((subject) => (
                    <li key={subject.id}>{subject.name}</li>
                  ))}
                </ul>
              </Dropdown>
            </div>
          ))}
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default AreasAccordion;
