import React from "react";
import styled from "styled-components";
import theme from "../../Consts/Theme";

import { months } from "../../Consts/utils";
import StyledElement from "../../Consts/Text";

const CardContainer = styled(StyledElement)`
  display: flex;
  flex-direction: row;
  background-color: ${({ isSelected }) => isSelected ? theme.darkBlue : theme.lightBlue};
  width: 100%;
  border-radius: 20px;
  padding: 20px 22px;
  align-items: start;
  cursor: pointer;
  transition: box-shadow .2s ease-in-out;

  @media (min-width: ${theme.smallDesktop}) {
    padding: 16px 18px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    padding: 20px 22px;
  }

  &:hover {
    box-shadow: 8px 10px 20px 2px rgba(0,0,0,0.3);
  }
`;
const DateContainer = styled(StyledElement)`
  display: flex;
  align-items: center;
  background-color: ${theme.white};
  border-radius: 10px;
  text-transform: uppercase;
  padding: 10px 14px;
  margin-right: 5%;

  @media (min-width: ${theme.standardMobile}) {
    font-size: 18px;
    padding: 14px 18px;
  }

  @media (min-width: ${theme.mediumMobile}) {
    font-size: 24px;
    padding: 16px 26px;
  }

  @media (min-width: ${theme.smallDesktop}) {
    font-size: 18px;
    padding: 12px 16px;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 26px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 32px;
    padding: 18px;
    border-radius: 12px;
  }
`;
const TextContainer = styled(StyledElement)`
  display: block;
  width: 100%;
  text-align: left;
`;
const EventTitle = styled(StyledElement)`
  padding-bottom: 2px;

  @media (min-width: ${theme.standardMobile}) {
    line-height: 20px;
    font-size: 17px;
    padding-bottom: 6px;
  }
  @media (min-width: ${theme.mediumMobile}) {
    line-height: 24px;
    font-size: 20px;
    padding-bottom: 12px;
  }
  @media (min-width: ${theme.tablet}) {
    line-height: 36px;
    font-size: 26px;
    padding-bottom: 14px;
  }
  @media (min-width: ${theme.smallDesktop}) {
    line-height: 30px;
    font-size: 20px;
    padding-bottom: 5px;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    line-height: 40px;
    font-size: 26px;
    padding-bottom: 10px;
  }
  @media (min-width: ${theme.largeDesktop}) {
    line-height: 56px;
    font-size: 34px;
    padding-bottom: 7px;
  }
`;
const EventDescription = styled(StyledElement)`
  display: inline-flex;
  flex-wrap: wrap;
  column-gap: 33px;
  row-gap: 2px;

  @media (min-width: ${theme.standardMobile}) {
    font-size: 14px;
    row-gap: 4px;
  }
  @media (min-width: ${theme.mediumMobile}) {
    font-size: 15px;
    column-gap: 33px;
    row-gap: 5px;
  }
  @media (min-width: ${theme.tablet}) {
    font-size: 20px;
    column-gap: 26px;
  }
  @media (min-width: ${theme.smallDesktop}) {
    font-size: 12px;
    column-gap: 12px;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 14px;
    column-gap: 14px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 20px;
    column-gap: 20px;
  }
`;
const EventCard = ({ title, date, location, isSelected, onClick }) => {
  let getDate = new Date(date).getDate();
  let getMonth = new Date(date).getMonth() + 1;
  const getYear = new Date(date).getFullYear();
  let getHours = new Date(date).getHours();
  let getMinutes = new Date(date).getMinutes();

  const formattedTime = `${getHours}:${getMinutes}`;


  const monthMk = months.find((month) => month?.month == getMonth);

  if (getDate < 10) {
    getDate = "0" + getDate;
  }
  if (getMonth < 10) {
    getMonth = "0" + getMonth;
  }
  return (
    <CardContainer as="div" isSelected={isSelected} onClick={onClick}>
      <DateContainer as="div" fontWeight="700">
        {getDate} <br /> {monthMk?.name}
      </DateContainer>
      <TextContainer as="div">
        <EventTitle
          as="h1"
          fontSize="14px"
          fontWeight="700"
          lineheight="18px"
          color={`${theme.white}`}
        >
          {title}
        </EventTitle>
        <EventDescription
          as="div"
          fontSize="12px"
          fontWeight="400"
          color={`${theme.white}`}
        >
          <div>{formattedTime}</div>
          <div>
            {getDate}.{getMonth}.{getYear}
          </div>
          <div>{location}</div>
        </EventDescription>
      </TextContainer>
    </CardContainer>
  );
};

export default EventCard;
