import React from "react";
import styled from "styled-components";
import StyledElement from "../../Consts/Text";
import theme from "../../Consts/Theme";

const StyledContainer = styled.a`
  display: flex;
  align-items: center;
  padding: 2% 0%;
  width: 100%;  
`;

const StyledIcon = styled(StyledElement)`
  @media (min-width: ${theme.extraLargeDesktop}) {
    width: 35px;
  }
`;
const StyledTitle = styled(StyledElement)`
  padding-left: 3%;
  border-left: 1px solid #000;
  margin-left: 3%;

  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 23px;
  }
`;

const MAX_NAME_LENGTH = 28;
const Document = ({ name, link }) => {
  const truncatedName =
    name?.length > MAX_NAME_LENGTH
      ? name.substring(0, MAX_NAME_LENGTH - 3) + "..."
      : name;
  return (
    <StyledContainer href={link} target="_blank">
      <StyledIcon as="img" src="./images/newsPage/documentIcon.svg" alt="📄" />
      <StyledTitle as="h1" fontWeight="400" fontSize="16px" lineheight="32px">
        {truncatedName}
      </StyledTitle>
    </StyledContainer>
  );
};

export default Document;
