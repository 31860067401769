import React, { useContext } from "react";
import styled from "styled-components";
import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";
import { makeExternalLink } from "../../Api";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../Context/GlobalProvider";

const StyledContainer = styled.div`
  background-color: ${theme.darkBlue};
  color: ${theme.white};
  position: relative;
  z-index: 9999;
`;
const StyledInnerContainer = styled.div`
  a {
    text-decoration: none;
    outline: none;
    color: inherit;
  }
  @media (min-width: ${theme.standardDesktop}) {
    display: flex;
    width: 90%;
    margin: 0 auto;
  }

  @media (min-width: ${theme.mediumDesktop}) {
    width: 80%;
  }
`;
const StyledLogoDiv = styled.div`
  padding-top: 48px;
  display: flex;
  justify-content: center;

  @media (min-width: ${theme.standardDesktop}) {
    align-self: center;
    padding-top: 0;
  }
`;
const StyledContentDiv = styled(StyledElement)`
  width: 100%;
  padding: 48px 16px 40px;
  text-align: center;
  line-height: 24px;

  div.footer-card {
    margin-bottom: 24px;
  }
  div.footer-card p,
  div.footer-card ul {
    font-size: 14px;
    padding-left: 0;
  }

  li {
    margin-bottom: 10px;
    list-style: none;
  }

  @media (min-width: ${theme.tablet}) {
    div.footer-card p {
      width: 80%;
      margin: 0 auto;
    }
  }
  @media (min-width: ${theme.standardDesktop}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    padding-left: 40px;
    div.footer-card {
      text-align: left;
    }

    div.footer-card p {
      width: 90%;
      margin: 0;
    }
  }

  @media (min-width: ${theme.mediumDesktop}) {
    padding-left: 65px;
    div.footer-card {
      text-align: left;
    }
    div.footer-card p,
    div.footer-card ul {
      font-size: 18px;
      line-height: 32px;
    }
    div.footer-card p {
      width: 90%;
      margin: 0;
    }
  }
`;
const StyledParagraph = styled(StyledElement)``;

const StyledHeading = styled(StyledElement)`
  margin-bottom: 10px;

  @media (min-width: ${theme.mediumDesktop}) {
    font-size: 26px;
    line-height: 34px;
  }
`;
const StyledContainer2 = styled.div`
  width: 100%;
  background-color: ${theme.white};
  color: ${theme.black};
  padding: 8px 14px 8px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  display: flex;
  flex-direction: column;

  a.brainster-link {
    text-decoration: underline;
  }

  @media (min-width: ${theme.mediumDesktop}) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    line-height: 32px;
  }
`;
const StyledSocialMediaIcons = styled.div`
  text-align: center;
  padding-top: 6px;

  @media (min-width: 768px) {
    margin-left: 25px;
  }
`;
const StyledIcons = styled.img`
  margin-right: 10px;
`;

export const Footer = () => {
  const { homepageData } =
  useContext(GlobalContext);

  const footerData= homepageData?.contents?.footer

  return (
    <>
      <StyledContainer>
        <StyledInnerContainer>
          <StyledLogoDiv>
            <img src="/images/logo.svg" />
          </StyledLogoDiv>
          <StyledContentDiv
            as="div"
            color={theme.white}
            fontSize="14px"
            lineHeight="24px"
          >
            <div className="footer-card">
              <StyledHeading
                as="h3"
                fontSize="16px"
                fontWeight="700"
                color={theme.white}
              >
                За Нас
              </StyledHeading>
              <StyledParagraph as="p" color={theme.white} lineheight="24px">
                {footerData?.description}
              </StyledParagraph>
            </div>
            <div className="footer-card">
              <StyledHeading
                as="h3"
                fontSize="16px"
                fontWeight="700"
                color={theme.white}
              >
                Контакт
              </StyledHeading>
              <ul>
                <li>
                  ул. „Трета Македонска Ударна Бригада“ <br />
                  број 9, Скопје 1000
                </li>
                <li>7:30-19:30ч., пон-пет</li>
                <li>(02) 3115-116</li>
              </ul>
            </div>
            <div className="footer-card">
              <StyledHeading
                as="h3"
                fontFamily="'Roboto', sans-serif"
                fontSize="16px"
                fontWeight="700"
                color={theme.white}
              >
                Корисни линкови
              </StyledHeading>
              <ul>
                <li>
                  <Link to={makeExternalLink("schoolsMk")} target="_blank">
                    Schools.mk
                  </Link>
                </li>
                <li>
                  <a href={makeExternalLink("eDnevnik")} target="_blank">
                    Е-дневник
                  </a>
                </li>
                <li>
                  <a href={makeExternalLink("stateGraduation")} target="_blank">
                    Државна матура
                  </a>
                </li>
                <li>
                  <a href={makeExternalLink("bro")} target="_blank">
                    Биро за развој на образованието
                  </a>
                </li>
                <li>
                  <a href={makeExternalLink("mon")} target="_blank">
                    Министерство за образование и наука
                  </a>
                </li>
                <li>
                  <a href={makeExternalLink("dic")} target="_blank">
                    Државен испитен центар
                  </a>
                </li>
                <li>
                  <a href={makeExternalLink("azlp")} target="_blank">
                    Агенција за заштита на личните податоци
                  </a>
                </li>
              </ul>
            </div>
          </StyledContentDiv>
        </StyledInnerContainer>
      </StyledContainer>
      <StyledContainer2>
        <div>
          <p>
            2023 СУГС „Раде Јовчевски - Корчагин“. Сите права се задржани. |
            Направено од студенти од{" "}
            <a
              href="https://brainster.co/"
              target="_blank"
              className="brainster-link"
            >
              Brainster
            </a>{" "}
            и{" "}
            <a
              href="https://next.edu.mk/?utm_medium=organic&utm_source=portal&utm_campaign=korcagin_web"
              target="_blank"
              className="brainster-link"
            >
              Brainster Next College
            </a>
            !
          </p>
        </div>

        <StyledSocialMediaIcons>
          <a href="https://www.facebook.com/Korchagin2022/" target="_blank">
            <StyledIcons src="/images/socialIcons/facebookLogo.svg" />
          </a>
          <a href="https://www.instagram.com/rjkorchagin/" target="_blank">
            <StyledIcons src="/images/socialIcons/instagramLogo.svg" />
          </a>
          <a href="https://www.youtube.com/@radejovcevskikorchagin7232" target="_blank">
            <StyledIcons src="/images/socialIcons/youtubeLogo.svg" />
          </a>
        </StyledSocialMediaIcons>
      </StyledContainer2>
    </>
  );
};
