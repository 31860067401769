import React, { useState } from "react";
import styled from "styled-components";

import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";
import EventCard from "./EventCard";

const StyledContainer = styled.section`
  text-align: center;

  @media (min-width: ${theme.smallDesktop}) {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-top: 1px solid #cfcfcf;
    padding-top: 80px;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    margin: 30px 10%;
  }
`;
const StyledHeading = styled(StyledElement)`
  @media (min-width: ${theme.largeMobile}) {
    font-size: 32px;
  }
  @media (min-width: ${theme.smallDesktop}) {
    display: none;
  }
`;
const StyledHeadingDesktop = styled(StyledElement)`
  display: none;
  @media (min-width: ${theme.smallDesktop}) {
    display: block;
    text-align: left;
    margin-bottom: 12px;
    line-height: 46px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 46px;
    line-height: 56px;
    margin-bottom: 33px;
  }
`;
const StyledImageDivContainer = styled.div`
  position: relative;
  width: 90%;
  height: auto;
  margin: 8% 5%;
  align-self: flex-end;

  @media (min-width: ${theme.smallDesktop}) {
    width: 49%;
    margin: 0%;
    margin-right: 3%;
    object-fit: cover;
    height: 100%;
    overflow: hidden;
  }
`;

const StyledImageDiv = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 500px;
  overflow: hidden;
  border-radius: 20px;
`;

const TitleOverlay = styled(StyledElement)`
  position: absolute;
  text-align: left;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(1px);
  background: linear-gradient(
    180deg,
    rgba(74, 74, 74, 0) 0%,
    rgba(22, 22, 22, 0.47) 34.03%,
    rgba(0, 0, 0, 0.6) 48.09%,
    rgba(6, 6, 6, 0.76) 66.66%
  );
  width: 100%;
  padding: 10% 6% 6%;
  border-radius: 0px 0px 20px 20px;

  @media (min-width: ${theme.tablet}) {
    font-size: 20px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    font-size: 28px;
  }
`;
const StyledContentContainer = styled.div`
  margin: 5%;

  @media (min-width: ${theme.smallDesktop}) {
    margin: 0;
    flex-grow: 1;
    align-self: flex-start;
    width: 48%;
  }
`;
const StyledCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${theme.smallDesktop}) {
    gap: 18px;
  }
  @media (min-width: ${theme.mediumDesktop}) {
    gap: 24px;
  }
  @media (min-width: ${theme.extraLargeDesktop}) {
    gap: 40px;
  }
`;

const UpcomingEvents = ({ events }) => {
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  const handleCardClick = (index) => {
    const indexCard = index;
    setSelectedCardIndex(indexCard);
  };

  return (
    <StyledContainer>
      <StyledHeading
        as="h1"
        fontFamily="'Exo 2', sans-serif"
        fontWeight="900"
        fontSize="25px"
        lineHeight="32px"
      >
        Престојни настани
      </StyledHeading>
      <StyledImageDivContainer>
        <StyledImageDiv src={events?.[selectedCardIndex]?.image || ""} />
        <TitleOverlay
          as="h1"
          fontWeight="500"
          fontSize="14px"
          lineHeight="46px"
          color={`${theme.white}`}
        >
          {events[selectedCardIndex]?.name}
        </TitleOverlay>
      </StyledImageDivContainer>

      <StyledContentContainer>
        <StyledHeadingDesktop
          as="h1"
          fontFamily="'Exo 2', sans-serif"
          fontWeight="900"
          fontSize="30px"
          lineHeight="46px"
        >
          Престојни настани
        </StyledHeadingDesktop>
        <StyledCardsContainer>
          {events.map((card, index) => (
            <div key={index}>
              <EventCard
                title={card?.name}
                date={card?.start_at}
                location={card?.location}
                image={card?.image}
                isSelected={selectedCardIndex === index}
                onClick={() => handleCardClick(index)}
              />
            </div>
          ))}
        </StyledCardsContainer>
      </StyledContentContainer>
    </StyledContainer>
  );
};

export default UpcomingEvents;
