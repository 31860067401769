import React from "react";
import styled from "styled-components";
import theme from "../../Consts/Theme";
import StyledElement from "../../Consts/Text";
import { Link } from "react-router-dom";

const MainContainer = styled(Link)``;

const CardContainer = styled.div`
  background-color: ${theme.white};
  width: 100%;
  border-radius: 10px;
  box-shadow: 12.7299px 9.90103px 35.36083px 2.82887px rgba(9, 30, 57, 0.1);
  padding: 22px 22px 30px;

  @media (min-width: ${theme.tablet}) {
    padding: 34px 39px;
    border-radius: 15px;
    box-shadow: 0px 14px 30px 4px rgba(9, 30, 57, 0.1);
  }
  @media (min-width: ${theme.mediumDesktop}) {
    width: 95%;
  }
`;
const CardImage = styled.img`
  object-fit: cover;
  width: 100%;
  flex-shrink: 0;
  border-radius: 10.608px;
  background: lightgray 50% / cover no-repeat;
`;

const CardTitle = styled(StyledElement)`
  text-align: left;
  margin-top: 8px;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    font-size: 32px;
    margin-top: 16px;
  }
`;
const CardDescription = styled(StyledElement)`
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 8px;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }
`;
const CardDate = styled(StyledElement)`
  text-align: right;
  text-transform: capitalize;
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Event = ({ image, name, description, date, to }) => {
  const getDate = new Date(date).getDate();
  const getMonth = new Date(date).getMonth() + 1;
  const getYear = new Date(date).getFullYear();
  return (
    <MainContainer to={to}>
      <CardContainer>
        <CardImage src={image} />
        <CardTitle
          as="h2"
          fontFamily="'Exo 2', sans-serif"
          fontSize="22px"
          fontWeight="700"
          lineheight="32px"
        >
          {name}
        </CardTitle>
        <CardDescription
          as="p"
          fontFamily="'Roboto', sans-serif"
          fontSize="14px"
          fontWeight="400"
          lineheight="20px"
        >
          {description}
        </CardDescription>
        <CardDate as="h3" fontSize="12px" fontWeight="600" lineheight="20px">
          <span>{getDate}</span>/<span>{getMonth}</span>/<span>{getYear}</span>
        </CardDate>
      </CardContainer>
    </MainContainer>
  );
};

export default Event;
