// import "./App.css";
import GlobalStyle from "./Consts/GlobalStyle";
import { GlobalProvider } from "./Context/GlobalProvider";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Home } from "./Pages/Home";
import { Activities } from "./Pages/Activities";
import { CareerCenter } from "./Pages/CareerCenter";
import {Classes} from "./Pages/Classes";
import { News } from "./Pages/News";
import { AboutUs } from "./Pages/AboutUs";
import { Announcement } from "./Pages/Announcement";
import { Event } from "./Pages/Event";
import { Staff } from "./Pages/Staff";
import { HouseRules } from "./Pages/HouseRules";
import { Philosophy } from "./Pages/Philosophy";
import { BilingualClass } from "./Pages/BilingualClass";
import { SchoolCommunity } from "./Pages/SchoolCommunity";
import { Enrollments } from "./Pages/Enrollments";
import { StateGraduation } from "./Pages/StateGraduation";
import { Gallery } from "./Pages/Gallery";
import { ThemeProvider } from "styled-components";
import theme from "./Consts/Theme";
import ActivityCardPage from "./Pages/ActivityCardPage";
import { NewsCardPage } from "./Pages/NewsCardPage";
import NotFound from "./Pages/NotFound";
import { Footer } from "./Components/Footer/Footer";


function App() {
  return (
    <GlobalProvider>
      <ThemeProvider theme={theme} />
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/activities/:ACTIVITY_ID" element={<ActivityCardPage />} />
          <Route path="/career-center" element={<CareerCenter />} />
          <Route path="/classes" element={<Classes />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:NEWS_ID" element={<NewsCardPage />} />
          <Route path="/announcements/:ANNOUNCEMENT_ID" element={<Announcement />} />
          <Route path="/events/:EVENT_ID" element={<Event />} />
          <Route path="/staff" element={<Staff />} />
          <Route path="/house-rules" element={<HouseRules />} />
          <Route path="/philosophy" element={<Philosophy />} />
          <Route path="/bilingual-class" element={<BilingualClass />} />
          <Route path="/school-community" element={<SchoolCommunity />} />
          <Route path="/enrollments" element={<Enrollments />} />
          <Route path="/state-graduation" element={<StateGraduation />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="*" element={<NotFound />}/>
        </Routes>
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
