import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MenuData } from "../../../Consts/utils";
import MenuDropdown from "./MenuDropdown";
import StyledElement from "../../../Consts/Text";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import theme from "../../../Consts/Theme";

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
`;

const StyledInfoContent = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 50px;
    display: block;
  }
  p.school-name {
    display: none;
    font-size: 20px;
    font-weight: 900;
    line-height: 48px;
    margin-left: 16px;
  }

  @media (min-width: ${theme.largeDesktop}) {
    p.school-name {
      display: block;
    }
  }
`;

const StyledNav = styled.nav`
  flex-grow: 1;
  display: flex;
  margin-left: auto;
  justify-content: space-evenly;

  .dropdown {
    position: relative;
    display: inline-block;
  }
`;

const StyledMenuButton = styled(StyledElement)`
  line-height: 32px;
  background-color: transparent;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  /* padding: 8px 18px; */
  cursor: pointer;
  color: ${(props) => (props.isActive ? theme.black : "rgba(0, 0, 0, 0.50)")};

  .menu-name {
    margin-right: 8px;
  }

  .icon {
    display: flex;
  }
  @media (min-width: ${theme.largeDesktop}) {
    padding: 8px 18px;
  }
`;

const MenuDesktop = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);


  return (
    <StyledContainer>
      <StyledInfoContent to="/">
        <img src="/images/logo.svg" alt="Logo" />
        <p className="school-name">СУГС „Раде Јовчевски - Корчагин“</p>
      </StyledInfoContent>
      <StyledNav>
        {MenuData?.map((item, index) => (
          <div
            key={index}
            className="dropdown"
            onMouseEnter={() => setActiveDropdown(index)}
            onMouseLeave={() => setActiveDropdown(null)}
          >
            <StyledMenuButton
              as="button"
              fontSize="16px"
              fontWeight="700"
              isActive={activeDropdown == index}
            >
              <span className="menu-name">{item.link}</span>
              {item?.submenu?.length > 0 ? (
                <span className="icon">
                  {activeDropdown !== index ? (
                    <FiChevronDown />
                  ) : (
                    <FiChevronUp />
                  )}
                </span>
              ) : (
                ""
              )}
            </StyledMenuButton>
            {item?.submenu?.length > 0 ? (
              <MenuDropdown
                showDropdown={activeDropdown === index}
                links={item?.submenu}
              />
            ) : (
              ""
            )}
          </div>
        ))}
      </StyledNav>
    </StyledContainer>
  );
};

export default MenuDesktop;
