import React from "react";
import styled from "styled-components";
import theme from "../Consts/Theme";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: ${theme.beige};
`;
const InnerContainer = styled.div`
  text-align: center;
  margin: 0 16px;

  h1 {
    font-size: 30px;
    margin-bottom: 16px;
  }
  @media (min-width: ${theme.smallDesktop}) {
    h1 {
      font-size: 44px;

    }
  }
`;
const NotFound = () => {
  return (
    <StyledContainer class="error-container">
      <InnerContainer>
        <h1>
          404 - <span>Page Not Found</span>
        </h1>
        <p>We're sorry, but the page you are looking for does not exist.</p>
      </InnerContainer>
    </StyledContainer>
  );
};

export default NotFound;
