import React from "react";
import styled from "styled-components";
import StyledElement from "../../Consts/Text";

const StyledHeading = styled(StyledElement)`
    margin-bottom: 24px;
`;
const StyledDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.color};
`;

const ContentBlock = ({ data, color }) => {
  return (
    <div>
      <StyledHeading
        as="h2"
        fontFamily="'Exo 2', san-serif"
        fontSize="25px"
        fontWeight="700"
        lineheight="35px"
        className="filter-title"
        color={color}
      >
        {data?.name}
      </StyledHeading>

      <StyledDescription color={color}>{data?.description}</StyledDescription>
    </div>
  );
};

export default ContentBlock;
