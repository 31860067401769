/**
 * This code defines a styled component called StyledElement using styled-components.
 * It takes advantage of the tagged template literal syntax to define the styles.
 * The component can render any HTML element specified in the 'as' prop.
 * The CSS properties are conditionally set based on the props provided, with default values as fallbacks.
 *
 * @param {object} props - Component props
 * @param {string} props.as - The HTML element to render (e.g., 'div', 'p', 'span')
 * @param {string} props.fontFamily - The font family for the element (default: 'Roboto')
 * @param {string} props.fontSize - The font size for the element (default: 'inherit')
 * @param {string} props.fontStyle - The font style for the element (default: 'normal')
 * @param {string} props.fontWeight - The font weight for the element (default: 'normal')
 * @param {string} props.lineheight - The line height for the element (default: 'normal')
 * @param {string} props.color - The text color for the element (default: theme.black)
 *
 * @returns {JSX.Element} - A styled JSX element based on the provided props and CSS styles.
 */

import { createElement } from "react";
import styled from "styled-components";
import theme from "./Theme";
import "../Fonts/font.css"
const StyledElement = styled((props) => {
  const { as, ...rest } = props;

  // Render the element specified by the 'as' prop with the provided props
  return createElement(as, rest);
})`
  font-family: ${(props) => props.fontFamily || "'Roboto', sans-serif"};
  font-size: ${(props) => props.fontSize || "inherit"};
  font-style: ${(props) => props.fontStyle || "normal"};
  font-weight: ${(props) => props.fontWeight || "400"};
  line-height: ${(props) => props.lineheight || "inherit"};
  color: ${(props) => props.color || "inherit"};
`;

export default StyledElement;
