import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { GrFormNext } from "react-icons/gr";
import { GrFormPrevious } from "react-icons/gr";

import { axios, makeApiRoute, makeAppRoute } from "../Api";
import theme from "../Consts/Theme";
import HeroBanner from "../Components/HeroBanner/HeroBanner";
import Button from "../Components/Button/Button";
import ActivityCard from "../Components/ActivityCard/ActivityCard";
import PageLoader from "../Components/Loader/PageLoader";
import { Footer } from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import ScrollToTopPage from "../Components/ScrollToTop/ScrollToTop";
import { useNavigate } from "react-router-dom";

const StyledContainer = styled.div`
  padding-bottom: 60px;
  position: relative;

  img.image-ellipse {
    position: absolute;
    bottom: -15%;
    right: 0;
    z-index: 0;
  }
  .btn-navigate-page-container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: ${(props) =>
      props.currentPage === 1 ? "flex-end" : "space-between"};
    position: relative;
    z-index: 11;
  }

  .btn-navigate-page-container button {
    display: inline-flex;
    align-items: center;
    background-color: transparent;
    border: none;
    outline: none;
    color: ${theme.magenta};
    font-size: 16px;
    cursor: pointer;
  }

  .btn-navigate-page-container button svg polyline {
    stroke: ${theme.magenta};
  }

  @media (min-width: ${theme.standardDesktop}) {
    margin-bottom: 170px;
    .btn-navigate-page-container {
      justify-content: ${(props) =>
        props.currentPage === 1 || props.currentPage === props.lastPage
          ? "center"
          : "space-around"};
    }
    .btn-navigate-page-container button {
      font-size: 18px;
    }

    img.image-ellipse {
      bottom: -30%;
    }
  }
`;

const ButtonContainer = styled.div`
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
  padding: 30px 15px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
  position: relative;
  z-index: 11;

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  button {
    padding: 15px 25px;
    font-size: 18px;
    font-weight: 600;
    margin-right: 15px;
  }
  @media (min-width: ${theme.standardDesktop}) {
    overflow: unset;
    white-space: normal;
    width: 65%;
    margin: 60px auto 0;
    text-align: center;

    button {
      margin-bottom: 30px;
    }
  }
`;
const CardContainer = styled.div`
  width: 90%;
  margin: 50px auto 25px;
  display: grid;
  gap: 16px;
  position: relative;
  z-index: 1;

  @media (min-width: ${theme.standardDesktop}) {
    grid-template-columns: repeat(3, 27%);
    justify-items: center;
    justify-content: center;
    align-items: center;
  }
`;

export const Activities = () => {
  //ref
  const ref = useRef(null);
  // states
  const [activitiesData, setActivitiesData] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [activityCards, setActivityCards] = useState([]);
  const [showFilteredCards, setShowFilteredCards] = useState(false);
  const [activeButton, setActiveButton] = useState(-1);
  const navigate = useNavigate();

  const fetchActivitiesData = (_route, object) => {
    axios
      .get(makeApiRoute(_route, object && object))
      .then((response) => {
        setActivitiesData(response.data);
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    setActivityCards(activitiesData.data);
  }, [activitiesData]);

  //fetch data
  useEffect(() => {
    fetchActivitiesData("activities");

    axios
      .get(makeApiRoute("clubs"))
      .then((response) => {
        setClubs(response.data.data);
      })
      .catch(function (error) {});
  }, []);

  const navigateToCardPage = (card_id) => {
    navigate(makeAppRoute("activityCard", {ACTIVITY_ID: card_id}));
  };

  // on click on Show All button
  const showAllActivities = () => {
    setShowFilteredCards(false);
    setActiveButton(-1);
    fetchActivitiesData("activities");
  };
  // on click on filter buttons
  const onFilterActivities = (id, index) => {
    setShowFilteredCards(true);
    setActiveButton(index);
    fetchActivitiesData("filteredActivities", { CARD_ID: id });
  };
  // on click on next page button
  const onNextPage = () => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
    if (!showFilteredCards) {
      const activePage = activitiesData.meta.current_page + 1;
      fetchActivitiesData("activitiesAllPerPage", { CURRENT_PAGE: activePage });
    } else {
      const activePage = activitiesData.meta.current_page + 1;
      const clubID = activitiesData?.data?.[0].club?.id;
      fetchActivitiesData("filteredActivitiesPerPage", {
        CURRENT_PAGE: activePage,
        CARD_ID: clubID,
      });
    }
  };
  // on click on prev page button
  const onPrevPage = () => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
    if (!showFilteredCards) {
      const activePage = activitiesData.meta.current_page - 1;
      fetchActivitiesData("activitiesAllPerPage", { CURRENT_PAGE: activePage });
    } else {
      const activePage = activitiesData.meta.current_page - 1;
      const clubID = activitiesData?.data?.[0].club?.id;
      fetchActivitiesData("filteredActivitiesPerPage", {
        CURRENT_PAGE: activePage,
        CARD_ID: clubID,
      });
    }
  };

  if (activitiesData.length === 0) {
    return <PageLoader />;
  }
  return (
    <>
      <ScrollToTopPage />
      <Header />
      <StyledContainer
        currentPage={activitiesData.meta.current_page}
        lastPage={activitiesData.meta.last_page}
      >
        <HeroBanner
          heading="Клубови и драмски секции"
          imageUrl="/images/heroBanner/aboutUs.svg"
        />

        <ButtonContainer>
          <Button
            content="Прикажи ги сите"
            bgColor={activeButton === -1 ? theme.darkBlue : "transparent"}
            color={activeButton === -1 ? theme.white : theme.darkBlue}
            border="2px solid #023859"
            onClick={showAllActivities}
          />
          {clubs.length > 0
            ? clubs?.map((club, index) => (
                <Button
                  key={club.id}
                  content={club.name}
                  bgColor={activeButton === index && theme.darkBlue}
                  color={activeButton === index ? theme.white : theme.darkBlue}
                  border="2px solid #023859"
                  onClick={() => onFilterActivities(club.id, index)}
                />
              ))
            : "Loading ..."}
        </ButtonContainer>

        <CardContainer ref={ref}>
          {activityCards?.length > 0
            ? activityCards?.map((activity) => (
                <ActivityCard
                  key={activity.id}
                  title={activity.name}
                  description={activity.description}
                  image={activity.image}
                  label={activity.club.name}
                  date={activity.created_at}
                  onClick={() => navigateToCardPage(activity?.id)}
                />
              ))
            : "Нема активности за оваа категорија"}
        </CardContainer>
        {(activityCards?.length > 1 && activitiesData?.meta?.last_page > 1) ||
        (activityCards?.length >= 1 && activitiesData?.meta?.last_page >= 1) ? (
          <div className="btn-navigate-page-container">
            {activitiesData?.meta?.current_page !== 1 ? (
              <button className="btn-navigate-page" onClick={onPrevPage}>
                <GrFormPrevious /> Претходна страна
              </button>
            ) : (
              ""
            )}
            {activitiesData?.meta?.last_page > 1 &&
            activitiesData?.meta?.current_page !==
              activitiesData?.meta?.last_page ? (
              <button onClick={onNextPage}>
                Следна страна <GrFormNext />
              </button>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <img
          className="image-ellipse"
          src="./images/activities/ellipse.svg"
          alt="Ellipse"
        />
      </StyledContainer>
      <Footer />
    </>
  );
};
